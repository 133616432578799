<template>
  <Spacer>
    <Container>
      <Spacer size="sm"
        ><Heading align-center level="h2">{{ heading }}</Heading></Spacer
      >
    </Container>

    <TabGroup>
      <TabList class="hide-scrollbars flex space-x-2 -my-2 py-2 justify-start md:justify-center overflow-x-scroll md:overflow-auto px-8 scroll-pl-8 md:scroll-p-0">
        <Tab v-for="element in elements" :key="element.title" v-slot="{ selected }" as="template">
          <TabButton :selected="selected">{{ element.title }}</TabButton>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel v-for="(element, elementIndex) in elements" :key="element.title">
          <div class="relative group">
            <Spacer size="sm">
              <ScrollingContainer wide :columns="4">
                <InspirationImage v-for="(image, index) in element.images" :key="index" :src="image.src" :alt="image.caption" :lazy="elementIndex === 0" />
              </ScrollingContainer>
            </Spacer>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </Spacer>
</template>

<script setup>
import { Spacer, Container, Heading, ScrollingContainer, TabButton } from "prism";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import InspirationImage from "./inspiration_image.vue";

const props = defineProps({
  heading: String,
  elements: Object,
});
</script>
