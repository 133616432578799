document.body.addEventListener("click", function (e) {
  if (!e.target.matches('#table-of-contents a[href^="#"]')) return;

  e.preventDefault();

  const targetId = e.target.getAttribute("href");
  const targetElement = document.querySelector(targetId);

  if (!targetElement) return;

  const targetOffset = targetElement.getBoundingClientRect().top + window.scrollY;

  const navbar = document.getElementById("navbar");
  const navbarHeight = navbar ? navbar.clientHeight : 0;
  const offset = navbarHeight + 20;

  history.replaceState({}, "", targetId);
  window.scrollTo({
    top: targetOffset - offset,
    behavior: "smooth",
    left: 0,
  });
});
