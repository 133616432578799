<template>
  <div>
    <div v-if="uploadStatus == 'captcha_required'">
      <div class="download-overlay">
        <div class="backdrop"></div>
        <div class="content">
          <checkbox-captcha v-on:completed="completeCaptcha($event)" v-bind="captchaConfig" />
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block">
      <!-- desktop -->
      <div class="d-none d-md-block">
        <!-- desktop -->
        <div class="row ml-3 mr-3 mt-3 mb-0">
          <strong class="mr-1" style="font-size: 14px">{{ node.title_i18n }}</strong>
          <span class="hover-tooltip" style="opacity: 0.8" :data-tippy-content="'<div style=\'text-align: left;\'><strong>' + node.noteTitle_i18n + '</strong><br/>' + node.noteContents_i18n" tabindex="0">
            <i class="fas fa-info-circle" style="opacity: 0.5; font-size: 80%; color: #454545"></i>
          </span>
        </div>
        <div class="row mx-3 valign-wrapper node-padding">
          <div class="col-2 no-x-padding" v-if="foregroundUrl" v-on:click="uploadFromFile" style="cursor: pointer">
            <img :src="foregroundUrl" style="max-width: 100%; max-height: 75px" v-if="foregroundUrl" />
          </div>
          <div class="" :class="{ 'col-10': foregroundUrl, 'col-12 no-x-padding': !foregroundUrl }">
            <button class="btn btn-md" :class="uploadedFile || uploadedUrl ? 'btn-outline-secondary' : 'btn-primary'" v-on:click="uploadFromFile">{{ uploadedFile || uploadedUrl ? t.change_image : t.upload_image }}</button>
            <div style="color: #ff7272" class="mt-1 mb-0 text-left" v-if="node.tempJson.settings.requiredForegroundTypes && foregroundType && !node.tempJson.settings.requiredForegroundTypes.includes(foregroundType)">
              <small>{{ node.tempJson.settings.wrongForegroundTypeText_i18n }}</small>
            </div>
            <div v-else style="font-size: 70%; color: #98a0a6" class="mt-1 mb-0 text-left">
              {{ t.drop_paste_text_start }}
              <a href="#" v-on:click.prevent="uploadFromUrl" style="text-decoration: underline; color: #98a0a6">{{ t.drop_paste_text_url }}</a>
              {{ t.drop_paste_text_end }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!------------>
    <!-- mobile -->
    <!------------>
    <div class="d-lg-none">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="pull-left ml-3">
            <strong style="font-size: 14px">{{ node.title_i18n }}</strong>
            <span class="hover-tooltip" style="opacity: 0.8" :data-tippy-content="'<div style=\'text-align: left;\'><strong>' + node.noteTitle_i18n + '</strong>' + node.noteContents_i18n" tabindex="0">
              <i class="fas fa-info-circle" style="opacity: 0.5; font-size: 80%; color: #454545"></i>
            </span>
            <div style="color: #ff7272" v-if="node.tempJson.settings.requiredForegroundTypes && foregroundType && !node.tempJson.settings.requiredForegroundTypes.includes(foregroundType)">
              <div class="small mt-3 mb-3">{{ node.tempJson.settings.wrongForegroundTypeText_i18n }}</div>
            </div>
          </div>
          <div class="col text-right">
            <button class="btn btn-md" :class="uploadedFile || uploadedUrl ? 'btn-outline-secondary' : 'btn-primary'" v-on:click="uploadFromFile">{{ uploadedFile || uploadedUrl ? t.change_image : t.upload_image }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3 mb-1 mx-3 text-center" v-if="progress > 0">
      <div class="progress">
        <div class="progress-bar with-pulse" role="progressbar" v-bind:aria-valuenow="progress * 100" aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: `${progress * 100}%` }"></div>
      </div>
    </div>
    <div class="row mx-3" v-if="uploadStatus == 'token_requested'">
      <div class="mobile-center my-1">
        <div class="status-text">{{ t.uploading }}</div>
      </div>
    </div>
    <div class="row mx-3" v-if="uploadStatus == 'processing'">
      <div class="mobile-center my-1">
        <div class="status-text">{{ t.removing_background }}</div>
      </div>
    </div>
    <div class="row mx-3" v-if="uploadingNote">
      <div class="mobile-center my-1">
        <small class="status-text">{{ uploadingNote }}</small>
      </div>
    </div>
    <div class="row mx-3" v-if="uploadStatus == 'error'">
      <div class="my-1" style="width: 100%">
        <div class="template-alert alert-danger">
          <small>{{ errorMessage }}</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import Rails from "@rails/ujs";
import ColorPicker from "./color_picker.vue";
import CheckboxCaptcha from "./checkbox_captcha.vue";
import ColorList from "./color_list.vue";
import tippy from "tippy.js";

import { ImageResizer } from "../src/remove-bg-tools";

import { pickFile } from "../src/pick_file";
import { hcaptcha_invisible } from "../src/hcaptcha";
import Configs from "@/modules/configs";

const maxConcurrent = 1;
const maxSize = (size_in_mb) => {
  if (!size_in_mb) {
    size_in_mb = Number(Configs.get("max_file_size_limit_mb")) || 12;
  }

  return 1024 * 1024 * size_in_mb;
};

import "vue-select/dist/vue-select.css";

export default {
  props: {
    node: Object,
    t: Object,
    params: Object,
    designTemplate: Object,
  },
  data: () => ({
    uploadStatus: "",
    errorMessage: "",
    uploadingNote: null,
    foregroundUrl: "",
    uploadedFile: null,
    resultUrl: null,
    uploadedUrl: null,
    foregroundType: null,
    progress: null,
    captchaConfig: {},
  }),
  mounted() {
    if (this.params.image_url) {
      this.urlPicked(this.params.image_url);
    } else {
      this.foregroundUrl = this.designTemplate.foregroundPlaceholderUrl();
    }

    var tooltips = $(document).find(".hover-tooltip");
    if (tooltips.length > 0) {
      tippy(tooltips.get(), { allowHTML: true, interactive: true });
    }
  },
  methods: {
    completeCaptcha(value) {
      this.onCaptchaCompleted({
        value: value,
        version: "v2_checkbox",
      });
    },
    uploadFromFile() {
      pickFile().then((file) => {
        window.track("Images", "upload_select_file", "Select file");
        this.filePicked(file);
      });
    },
    uploadFromUrl() {
      var url = prompt(`${this.t.image_url}:`);
      window.track("Images", "upload_enter_url", "Enter URL");
      this.urlPicked(url);
    },
    urlPicked(url) {
      if (url == null || url == "") return;
      if (!url.match(/^https?:\/\/.+$/)) {
        alert(this.t.invalid_image_url);
        return;
      }
      this.process({ url });
    },
    filePicked(file, isBlob = false) {
      this.changeProgress(0.1);
      let that = this;
      this.uploadingNote = null;
      if (file.type != "image/jpeg" && file.type != "image/png") {
        alert(this.t.only_jpg_png_supported);
        return;
      }

      if (file.size <= maxSize(this.t.max_file_size_limit_mb)) {
        if (isBlob) {
          this.process({ file: file.file, filename: file.filename }, file);
        } else {
          this.process({ file }, file);
        }
      } else {
        var imageResizer = new ImageResizer(file);
        if (imageResizer.supports(file)) {
          imageResizer
            .reduce()
            .then((returnObj) => {
              if (blob.size > maxSize(this.t.max_file_size_limit_mb)) {
                alert(that.t.max_file_size_exceeded);
                return;
              }
              this.uploadingNote = this.t.image_resized;
              this.process(
                {
                  file: returnObj.blob,
                  filename: file.name,
                },
                file
              );
            })
            .catch((error) => {
              alert(that.t.max_file_size_exceeded);
            });
        } else {
          alert(that.t.max_file_size_exceeded);
        }
      }
    },
    updateThumbnail(upload, whichThumbnail) {
      let that = this;
      var file;
      if (upload.url) {
        that[whichThumbnail] = upload.url;
      } else {
        var imageResizer = new ImageResizer(upload.file);
        imageResizer.reduce().then((returnObj) => {
          var fr = new FileReader();
          fr.onload = function () {
            that[whichThumbnail] = fr.result;
          };
          fr.readAsDataURL(returnObj.blob);
        });
      }
    },
    deleteOldUpload() {
      if (this.resultUrl) {
        $.ajax({
          type: "DELETE",
          headers: {
            "X-CSRF-TOKEN": Rails.csrfToken(),
          },
          url: this.resultUrl,
        });
      }
    },
    setUploadStatus(status) {
      this.uploadStatus = status;
    },
    processUpload(upload) {
      return new Promise((resolve, reject) => {
        this.requestToken(upload)
          .then((upload) => {
            this.doUpload(upload)
              .then((upload) => {
                this.showResult(upload)
                  .then((upload) => {
                    resolve(upload);
                  })
                  .catch((e, status = "error") => {
                    // showResult
                    this.setUploadStatus(status);
                    this.setErrorMessage(e);
                    reject(e);
                  });
              })
              .catch((e) => {
                // doUpload
                this.setUploadStatus("error");
                this.setErrorMessage(e);
                reject(e);
              });
          })
          .catch((e) => {
            // requestToken
            this.setUploadStatus("error");
            this.setErrorMessage(e);
            reject(e);
          });
      });
    },
    process(upload) {
      this.changeProgress(0.3);
      let that = this;

      this.node.injectUpload(upload, this.processUpload, this.errorCallback);
    },
    errorCallback(e) {
      this.setUploadStatus("error");
      this.setErrorMessage(e);
    },
    requestToken(upload, tryCount = 1) {
      return new Promise((resolve, reject) => {
        $.ajax({
          type: "POST",
          url: this.t.trust_tokens_url,
          headers: {
            "X-CSRF-Token": Rails.csrfToken(),
          },
          data: upload.trustTokenData,
          success: (data) => {
            this.changeProgress(0.5);
            window.useToken = (token) => {
              upload.token = token;
              resolve(upload);
            };
            if (data.request && typeof data.request == "string") {
              eval(data.request);
            }
          },
          error: (xhr) => {
            window.submitChallenge = (upload, value) => {
              upload.trustTokenData = value;
              resolve(this.requestToken(upload));
            };

            window.request_hcaptcha_checkbox = (config, upload, data) => {
              this.uploadStatus = "captcha_required";
              this.captchaConfig = {
                type: "hcaptcha_checkbox",
                config: config,
              };
              this.onCaptchaCompleted = (captcha) => {
                this.uploadStatus = "token_requested";
                window.submitChallenge(upload, {
                  proof: "hcaptcha_checkbox",
                  hcaptcha_response: captcha.value,
                });
              };
            };

            window.request_hcaptcha_invisible = (config, upload, data) => {
              hcaptcha_invisible(config)
                .then((captcha) => {
                  submitChallenge(upload, {
                    proof: "hcaptcha_invisible",
                    hcaptcha_response: captcha,
                  });
                })
                .catch((e) => {
                  reject(e);
                });
            };

            if (xhr.status == 422 && xhr.responseJSON && xhr.responseJSON.request && typeof xhr.responseJSON.request == "string") {
              eval(xhr.responseJSON.request);
            } else if (xhr.status == 422 && xhr.responseJSON && xhr.responseJSON.status == "invalid_csrf_token" && tryCount < 3) {
              $("meta[name='csrf-token']").attr("content", xhr.responseJSON.csrf_token);
              resolve(this.requestToken(upload, tryCount + 1));
            } else {
              reject(`Error: Failed to prepare upload (error code ${xhr.status}).\nSorry – please reload the page and try again.\nIf the problem persists, contact team@remove.bg`);
            }
          },
        });
      });
    },
    doUpload(upload) {
      return new Promise((resolve, reject) => {
        let that = this;
        that.changeProgress(0.6);

        var formdata = new FormData();

        formdata.append("trust_token", upload.token);
        if (upload.file) {
          this.uploadedFile = upload.file.name;

          if (upload.filename) {
            formdata.append("image[original]", upload.file, upload.filename);
            this.$emit("update-foregroundFilename", upload.filename);
          } else {
            formdata.append("image[original]", upload.file);
            this.$emit("update-foregroundFilename", upload.file.name);
          }
        } else if (upload.url) {
          var filename = upload.url.split("/").pop().split("#")[0].split("?")[0]; // parse filename from url
          this.$emit("update-foregroundFilename", filename); // pass back up
          this.uploadedUrl = upload.url;

          formdata.append("image[original_source_url]", upload.url);
        }

        if (that.node.tempJson.settings.typeLevel) {
          formdata.append("image[type_level]", that.node.tempJson.settings.typeLevel);
        }

        formdata.append("template", this.params.id);

        formdata.append("channel", "template");

        $.ajax({
          url: this.t.images_url,
          type: "POST",
          data: formdata,
          cache: false,
          contentType: false,
          processData: false,
          headers: {
            "X-CSRF-Token": Rails.csrfToken(),
          },
          xhr: () => {
            var myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener(
                "progress",
                (e) => {
                  if (e.lengthComputable && e.total > 0) {
                    that.changeProgress((e.loaded / e.total) * 0.25 + 0.45);
                  }
                },
                false
              );
            }
            return myXhr;
          },
          success: (data) => {
            that.changeProgress(0.9);
            upload.result = data;
            this.deleteOldUpload();
            this.resultUrl = upload.result.url;
            resolve(upload);
          },
          error: (xhr) => {
            reject(`Image Upload failed (${xhr.status}): Please try again later or select a different image.`);
          },
        });
      });
    },
    setErrorMessage(errorMessage) {
      this.changeProgress(null);
      this.errorMessage = errorMessage;
      this.$emit("update-errorMessage", errorMessage);
    },
    showResult(upload) {
      return new Promise((resolve, reject) => {
        let that = this;
        this.$emit("update-uploadResult", upload.result); // pass back up

        if (upload.result.fetch_in) {
          this.uploadStatus = "processing";
          setTimeout(() => {
            $.ajax({
              type: "GET",
              headers: {
                "X-CSRF-Token": Rails.csrfToken(),
              },
              url: upload.result.url,
              cache: false,
              success: (data) => {
                if (data.template.status == "error") {
                  reject(data.template.error_message, data.template.status);
                } else {
                  upload.result = data;
                  that.changeProgress(0.95);
                  resolve(that.showResult(upload));
                }
              },
              error: (xhr) => {
                reject(`Image Upload failed (${xhr.status}): Please try again later or select a different image.`);
              },
            });
          }, upload.result.fetch_in);
        } else {
          this.uploadStatus = "finished";
          this.foregroundType = upload.result.template.foreground_type;
          this.$emit("update-uploadStatus", "finished");
          this.$emit("update-fullAvailable", upload.result.template.full_available);
          this.updateThumbnail(upload, "foregroundUrl");
          this.uploadingNote = null;
          this.$emit("update-foregroundUrl", upload.result.template.result_url); // pass back up
          this.changeProgress(null);

          resolve(upload);
        }
      });
    },
    changeProgress(value) {
      this.progress = value;
    },
  },
  components: {
    CheckboxCaptcha,
  },
};
</script>
<style scoped>
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.btn-outline-secondary {
  color: #0f70e6;
  background-color: #fff;
  border-color: #0f70e6;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #0f70e6;
  border-color: #0f70e6;
}

.download-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19000;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-overlay .backdrop {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19020;
}
.download-overlay .content {
  background: #fff;
  position: relative;
  z-index: 19050;
  padding: 15px;
  max-width: 345px;
  border-radius: 3px;
}
</style>
