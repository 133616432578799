<template>
  <div class="relative overflow-hidden rounded-2xl shadow-2xl bg-white p-6 flex flex-col gap-6">
    <div class="flex flex-col gap-3">
      <h3 class="text-xl text-typo-critical font-display">{{ errorHeading(error) }}</h3>
      <p>{{ errorSubheading(error) }}</p>
    </div>
    <div class="flex flex-col items-start gap-3">
      <template v-if="canBeContributed">
        <template v-if="needsDialog">
          <Dialog ref="dialog" :dismissable="false" trigger-classes="w-full flex justify-center sm:justify-start focus:outline-none" dialog-classes="md:!max-w-4xl">
            <template #trigger>
              <Button :disabled="isRated" full-width>{{ I18n.t("editor.contribute.cta") }}</Button>
            </template>
            <template v-slot:content="{ closeDialog }">
              <div class="flex flex-col gap-y-6">
                <Heading level="h4">{{ I18n.t("improve.heading") }}</Heading>
                <p>{{ I18n.t("improve.want_to_help") }}</p>
                <ul style="list-style-type: disc; list-style-position: inside">
                  <li>{{ I18n.t("improve.contribute_and_improve") }}</li>
                  <li>{{ I18n.t("improve.teach_ai") }}</li>
                  <li>{{ I18n.t("improve.get_better") }}</li>
                </ul>

                <p>
                  <Checkbox :v-model="consented" :error="hasConsentError" @update:modelValue="handleConsentChange"><span v-html="I18n.t('improve.agree_to_x', { x: ipc_url })"></span></Checkbox>
                  <Checkbox :v-model="rememberDecision" @update:modelValue="(newValue) => (rememberDecision = newValue)">{{ I18n.t("improve.remember") }}</Checkbox>
                </p>

                <div class="sm:flex sm:flex-row-reverse sm:gap-x-3 space-y-3 sm:space-y-0 grow">
                  <Button
                    fullWidth
                    class="mb-2"
                    autofocus
                    type="button"
                    :on-click="
                      () => {
                        contribute(closeDialog);
                      }
                    "
                    >{{ I18n.t("improve.yes_contribute") }}</Button
                  >
                  <Button
                    fullWidth
                    variant="secondary"
                    type="reset"
                    :on-click="
                      () => {
                        closeDialog();
                      }
                    "
                    >{{ I18n.t("improve.no_contribute") }}</Button
                  >
                </div>
              </div>
            </template>
          </Dialog>
        </template>
        <template v-else>
          <Button
            :disabled="isRated"
            :on-click="
              () => {
                contribute();
              }
            "
            full-width
            >{{ I18n.t("editor.contribute.cta") }}</Button
          >
        </template>
      </template>

      <Button variant="secondary" full-width @click="uploadFile">{{ I18n.t("editor.try_another_image") }}</Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import "@/src/i18n";
import { ref, computed, inject, onMounted } from "vue";
import { Button, Dialog, Checkbox } from "prism";
import improvedecision from "@/src/improvedecision";
import Client from "@/modules/internal_api/client";
import { rbgRateBadV100 } from "kaleido-event-tracker";
import Routes from "@/modules/routes";

import { useEditorStore } from "@/stores/editor_store";
import { ProcessingError, ProcessingState, UploadError, UploadState } from "@/modules/internal_api/image";
const store = useEditorStore();

const consented = ref<Boolean>(false);
const hasConsentError = ref<Boolean>(false);
const needsDialog = ref<Boolean>(false);
const rememberDecision = ref<Boolean>(false);

const isRated = ref<Boolean>(false);
const rating = ref<Number>(0);

const error = computed((): UploadError | ProcessingError => {
  const image = store.selectedImage;

  if (image.original.state === UploadState.Error) {
    return image.original as UploadError;
  } else if (image.previewResult.state === ProcessingState.Error) {
    return image.previewResult as ProcessingError;
  }
});

const contributeAbleErrors = ["no_foreground_found"];
const canBeContributed = computed(() => contributeAbleErrors.includes(error.value.message));

const I18n = inject("I18n", window.I18n);

onMounted(() => {
  const decision = improvedecision.get();

  if (decision === undefined) {
    consented.value = false;
    needsDialog.value = true;
  } else if (decision === "accept") {
    consented.value = true;
    needsDialog.value = false;
  } else if (decision === "reject") {
    consented.value = false;
    needsDialog.value = false;
  }

  const image = store.selectedImage;
  if (image.previewResult.state == ProcessingState.Finished) {
    isRated.value = image.previewResult.rated;
    rating.value = image.previewResult.rating;
  }
});

const handleConsentChange = (newValue) => {
  hasConsentError.value = false;
  consented.value = newValue;
};

const contribute = (closeDialog?: () => void) => {
  if (closeDialog) {
    if (!consented.value) {
      hasConsentError.value = true;
      return;
    }
    saveDecision();
  }

  rateNegative();
  if (closeDialog) closeDialog();
};

const saveDecision = () => {
  if (rememberDecision.value) {
    improvedecision.set(consented.value ? "accept" : "reject");
    needsDialog.value = false;
  }
};

const rateNegative = () => {
  const image = store.selectedImage;
  window.track("Images", "rate_bad", "Rate bad");
  rbgRateBadV100({ image_id: image.meta.id });
  rate(-1);
};

const rate = (newRating: number) => {
  const image = store.selectedImage;

  Client.rateImage(image, newRating, consented.value);
  isRated.value = true;
  rating.value = newRating;

  if (image.previewResult.state == ProcessingState.Finished) {
    image.previewResult.rated = isRated.value as boolean;
    image.previewResult.rating = rating.value as number;
    store.updateImage(image);
  }
};

const ipc_url = computed(() => {
  return `<a href="${Routes.get("ipc_url")}" target="_blank">${I18n.t("improve.ipc")}</a>`;
});

const isMissingTranslation = (translatedMessage: string): boolean => {
  return /\[missing ".*?" translation\]/.test(translatedMessage);
};

const errorHeading = (error) => {
  // TODO: We need have a better message (https://canvadev.atlassian.net/browse/RMV-3002)
  return errorMessage(error, I18n.t("ai_brush.error.sorry_something_went_wrong_please_try_again_later"));
};

const errorSubheading = (error) => {
  errorMessage(error);
};

const errorMessageMap = {
  original_source_url_is_invalid: "upload_widget.invalid_image_url",
  upload_error: "editor.error.failed_to_download_image_from_url.message",
};
const errorMessage = (error, defaultMessage = "") => {
  let translation = I18n.t(`editor.error.${error.message}.message`);
  if (isMissingTranslation(translation)) {
    if (errorMessageMap[error.message]) {
      translation = I18n.t(errorMessageMap[error.message]);
    } else if (error.code.includes("upload_error")) {
      translation = I18n.t(errorMessageMap["upload_error"]);
    } else {
      translation = defaultMessage;
    }
  }

  return translation;
};

const uploadFile = () => {
  window.onUploadFile();
};
</script>
