<template>
  <div style="width: 100%">
    <div class="overflow-visible">
      <div v-if="buttonActivation && isMobileOnly && !searchActive">
        <i class="fa fa-search" style="color: white" @click="activateSearch"></i>
      </div>
      <div class="d-flex align-items-center search-border" v-if="!buttonActivation || (buttonActivation && searchActive) || !isMobileOnly">
        <div class="input-group">
          <span class="input-group-prepend">
            <div class="input-group-text bg-white border-0"><i class="fa fa-search"></i></div>
          </span>
          <input ref="input" v-model="q" @focus="openAndFocus" @blur="close" @keyup="debounce(search, 500)" type="search" class="form-control pl-1 border-0" :style="state == 'searching' ? '' : 'border-top-right-radius: 5px; border-bottom-right-radius: 5px;'" placeholder="Search tools" />

          <span class="input-group-append ml-0">
            <span class="input-group-text bg-white border-0" v-if="state == 'searching'"><img src="@public/images/loader.gif" class="d-inline-block" alt="Processing…" style="width: 25px; height: 25px" /></span>
          </span>
        </div>
      </div>
      <div :style="isMobileOnly ? {} : { position: 'absolute' }">
        <div v-show="open" id="search-overlay" class="mt-1 search-overlay-breadcrumb">
          <div id="search-overlay-contents" class="search-shadow search-border" v-if="tools && tools.length > 0" @mouseover="hover = true" @mouseleave="hover = false">
            <div class="entry" v-for="tool in tools" :key="tool.id">
              <a :href="tool.content.show_url" class="hover-none bg-white p-3 d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center mw-100">
                  <div :style="toolLogoStyle(tool.content.icon.filename)" class="col-auto mr-3"></div>
                  <div class="flex-grow-0 d-flex flex-column align-items-start mw-100">
                    <div class="flex-grow-0 d-flex justify-content-start text-left align-items-center mw-100">
                      <p class="mb-0 text-dark font-family-sans hover-none mw-100 text-wrap" style="font-weight: 600; font-size: 16px">{{ tool.name }}</p>
                    </div>
                    <small v-for="(group, type) in groupBy(tool.content.sources, 'source_type_short')" :key="type" class="text-muted">
                      <span class="text-left" style="font-size: 16px">{{ type }} {{ group.map((source) => source.name).join(" | ") }}</span>
                    </small>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div v-else>
            <div v-if="state == 'finished' && !searchModified" class="search-shadow">
              <div id="entry">
                <span class="hover-none bg-white p-3 d-flex justify-content-between align-items-center" style="border-radius: 5px">
                  <div class="d-flex align-items-center">
                    <div class="d-flex flex-column align-items-start">
                      <div class="d-flex justify-content-start text-left align-items-center">
                        <p class="mb-0 text-dark font-family-sans hover-none font-weight-normal" style="font-size: 16px">
                          Sorry, nothing found for <b>{{ truncatedQ }}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Rails from "@rails/ujs";
import { isMobileOnly, isIOS } from "mobile-device-detect";
// import Vue from "vue";

export default {
  props: {
    featured_tools: Array,
    buttonActivation: Boolean,
  },
  data() {
    return {
      tools: [],
      open: false,
      state: "finished",
      q: "",
      lastQ: "",
      xhr: null,
      hover: false, // need to track search contents hover so links are clickable before blur
      timeoutID: null,
      searchModified: false,
      searchActive: false,
    };
  },
  computed: {
    isMobileOnly: function () {
      return isMobileOnly;
    },
    truncatedQ: function () {
      if (this.q.length > 10) {
        return this.q.substring(0, length) + suffix;
      } else {
        return this.q;
      }
    },
  },
  watch: {
    searchActive: function (val) {
      document.getElementById("tools-api-title").style.display = val ? "none" : "block";
      document.getElementById("breadcrumb-search").style.width = "100%";
    },
    q: function (val) {
      this.searchModified = true; // hide "no results" overlay immediately when changing the search query
    },
  },
  methods: {
    activateSearch() {
      this.searchActive = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    debounce(fn, delay) {
      clearTimeout(this.timeoutID);
      var args = arguments;
      var that = this;
      this.timeoutID = setTimeout(function () {
        fn.apply(that, args);
      }, delay);
    },
    openAndFocus(event) {
      if (isMobileOnly) {
        var element = $("#search-input-field");
        if (!element.length) {
          element = $("#breadcrumb-search");
        }
        $("html,body").animate({
          scrollTop: element.offset().top - 50,
        });
      }
      this.open = true;
      if (this.q && this.q == this.lastQ) return;
      this.search();
    },
    close() {
      if (!this.hover) {
        this.open = false;
      }

      this.cancelSearch();
    },

    clear() {
      this.q = "";
      this.cancelSearch();
      this.search();
    },
    cancelSearch() {
      if (this.xhr) {
        this.xhr.abort();
        this.xhr = undefined;
      }
    },
    search() {
      if (this.state == "searching") this.cancelSearch();
      if (this.q.length == 0) {
        this.tools = this.featured_tools;
        this.state = "finished";
        return;
      }

      this.state = "searching";

      this.xhr = $.ajax({
        type: "GET",
        headers: {
          "X-CSRF-TOKEN": Rails.csrfToken(),
        },
        url: "/tools-api/search",
        data: { q: this.q },
        success: (data) => {
          this.tools = data.tools;
          this.lastQ = this.q;
          this.state = "finished";
          this.searchModified = false;
        },
        error: (e) => {
          this.state = "finished";
        },
      });
    },
    toolLogoStyle(url) {
      const cached_url = url.replace("//a.storyblok.com/f/67418/", "//sb.kaleidousercontent.com/67418/");

      return {
        backgroundImage: `url("${cached_url}")`,
        backgroundSize: "contain",
        width: "54px",
        height: "54px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };
    },

    groupBy(array, key) {
      return array.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
  },
};
</script>

<style lang="scss" scoped>
#entry a:hover {
  background-color: #edeff0 !important;
}

a:hover .text-dark {
  color: black !important;
}

input:focus,
textarea:focus,
button:focus {
  outline: none;
  box-shadow: none;
}

.input-group {
  &:focus-within {
    border-radius: 5px;
    border-color: #05f;
    box-shadow: 0 0 0 1px #768089;
  }
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

#search-overlay {
  position: absolute;
  z-index: 10000;
}

.search-border {
  border-style: solid;
  border-color: #bac0c4;
  border-width: 1px;
  border-radius: 5px;
}

#search-overlay-contents {
  max-height: 50vh;
  overflow: hidden auto;
  border-radius: 5px;
}

.search-shadow {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
}

.bg-dark-transparent {
  background-color: rgba(69, 69, 69, 0.9);
}
</style>

<style>
#search-input-field {
  z-index: 20;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  max-width: 500px;
  position: absolute;
}

@media screen and (min-width: 720px) {
  /* desktop */
  .search-overlay-breadcrumb {
    right: -234px;
    width: 350px;
  }

  #breadcrumb-search {
    text-align: right;
    max-width: 234px;
  }
}

@media screen and (max-width: 720px) {
  /* desktop */
  .search-overlay-breadcrumb {
    right: 16px;
    left: 16px;
  }
}

.hover-none:hover {
  text-decoration: none;
}
</style>
