import $ from "jquery";

window.showGetHelpForm = function() {
  $("#solution-header").hide();
  $("#choose-feedback").hide();
  $("#gethelp-form").fadeIn();
};

window.showCallbackForm = function() {
  $("#choose-feedback").hide();
  $("#solution-header").hide();
  $("#callback-form").fadeIn();
};

$(function() {
  $(".final-cancel-btn").click(function() {
    $(".cancel-btns").html('<img src="/images/loader.gif" alt="Loading..." /> Cancelling subscription...');
  });
});
