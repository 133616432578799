<template>
  <div class="flex flex-col items-center w-full">
    <p class="w-full align-left text-typo" v-html="I18n.t('image.ios_restricted_download_message')" />

    <Button
      @click="props.closeDialog"
      class="!bg-transparent hover:!bg-transparent active:!bg-transparent text-primary hover:!text-primary-hover text-base w-full md:w-auto"
      >{{ I18n.t("editor.dismiss") }}</Button
    >
  </div>
</template>

<script setup lang="ts">
import "@/src/i18n";
import { Button } from "prism";
import { inject } from "vue";

interface Ios16MPDialogContentProps {
  closeDialog: () => void;
}

const props = defineProps<Ios16MPDialogContentProps>();
const I18n = inject("I18n");
</script>
