import Routes from "@/modules/routes";
import { I18n } from "i18n-js";

const locale = document.documentElement.lang;
const promises = [
  fetch(Routes.get("translations")).then((response) => response.json()),
  fetch(Routes.get("editor_translations")).then((response) => response.json()),
];

const [translations, rawEditorTranslations] = await Promise.all(promises);
const editorTranslations = { [locale]: rawEditorTranslations };

const i18n = new I18n({
  [locale]: {
    ...translations[locale],
    ...editorTranslations[locale],
  },
});

i18n.locale = locale;
i18n.defaultLocale = "en";
window.I18n = i18n;
