import $ from "jquery";
import { trackStructEvent } from "@snowplow/browser-tracker";
import { rbgTransactionOrderV100, rbgTransactionItemV100, rbgLogInV100, rbgSignUpV100 } from "kaleido-event-tracker";

const snowplowConfig = JSON.parse(document.querySelector("meta[property='snowplow']").getAttribute("content"));
const eventsIdentifier = snowplowConfig.events_identifier;

window.track = function (
  category: string,
  action: string,
  label: string,
  item?: TrackingItem,
  transaction_id?: string
) {
  var sampleRate = 1;
  if (category === "Editor") {
    sampleRate = 1000;
  }
  if (sampleRate > 1 && Math.random() > 1 / sampleRate) {
    return;
  }

  if (typeof window.gtag !== "undefined") {
    window.gtag("event", action, {
      transaction_id: transaction_id,
      currency: "USD",
      event_category: category,
      event_label: label,
      items: [item],
    });
  }

  if (snowplowConfig.enabled) {
    trackStructEvent(
      {
        category: category,
        action: action,
        label: label,
      },
      [eventsIdentifier]
    );

    trackSignIn(category, action);
    trackSignUp(category, action);

    if (transaction_id) {
      const orderData = { orderid: transaction_id, total: item?.price, currency: "USD" };
      rbgTransactionOrderV100(orderData, [eventsIdentifier]);

      const itemData = { orderid: transaction_id, sku: item?.id, ...item };
      delete itemData.id;

      rbgTransactionItemV100(itemData, [eventsIdentifier]);
    }
  }
};

$(() => {
  $(".ga-tracked").on("click", function (event: Event) {
    window.track($(this).data("ga-category"), $(this).data("ga-event"), $(this).data("ga-label"));
  });
});

const trackSignUp = (category: string, action: string) => {
  if (category?.toLowerCase() === "user" && action?.toLowerCase() === "signup") {
    rbgSignUpV100({});
  }
};

const trackSignIn = (category: string, action: string) => {
  if (category?.toLowerCase() === "user" && action?.toLowerCase() === "signin") {
    rbgLogInV100({});
  }
};

export interface TrackingItem {
  id?: string;
  orderid?: string;
  name?: string;
  category?: string;
  price?: number;
  quantity?: number;
}
