export function useUploaderSamples() {

  const base = "https://static.remove.bg/uploader-examples/";

  const numberOfPersonSamples = 7;
  const numberOfAnimalSamples = 8;
  const numberOfCarSamples = 6;
  const numberOfProductSamples = 9;

  const personSamples = Array.from(Array(numberOfPersonSamples).keys()).map((i) => {
    return { url: `${base}person/${i+2}.jpg`, thumbnail_url: `${base}person/${i+2}_thumbnail.jpg` }
  });

  const animalSamples = Array.from(Array(numberOfAnimalSamples).keys()).map((i) => {
    return { url: `${base}animal/${i+1}.jpg`, thumbnail_url: `${base}animal/${i+1}_thumbnail.jpg` }
  });

  const carSamples = Array.from(Array(numberOfCarSamples).keys()).map((i) => {
    return { url: `${base}car/${i+1}.jpg`, thumbnail_url: `${base}car/${i+1}_thumbnail.jpg` }
  });

  const productSamples = Array.from(Array(numberOfProductSamples).keys()).map((i) => {
    return { url: `${base}product/${i+1}.jpg`, thumbnail_url: `${base}product/${i+1}_thumbnail.jpg` }
  });

  const availableImages = [
    personSamples,
    animalSamples,
    carSamples,
    productSamples,
  ]

  return {
    availableImages,
  }
}
