<template>
  <!-- oveflow: visible dirty hack is used so tooltip can appear without being cut by drawer frame -->
  <Drawer :on-close="closePanel" :close-icon-position="closeIconPosition" :close-title="I18n.t('editor.modal_close')" style="overflow: visible !important">
    <template #content>
      <div class="min-width-desktop inline-block" :class="contentWrapperClasses">
        <div class="effect-container flex flex-col gap-3 h-24 !px-4 w-full">
          <div class="flex items-center gap-2">
            <Toggle
              :name="I18n.t('editor.blur_background')"
              :model-value="props.selectedImageState.isBackgroundBlurEnabled"
              @update:modelValue="updateBlurState"
              :label="I18n.t('editor.blur_background')"
            />
          </div>
          <RangeSlider
            :value="$props.selectedImageState?.blurRadius"
            @slider-value-changed="changeBlurRadius"
            :label="I18n.t('editor.blur_amount')"
            :disabled="!props.selectedImageState.isBackgroundBlurEnabled"
            :min="0"
            :max="25"
            :step="1"
            :on-mousedown="cacheState"
            :on-touchstart="cacheState"
            :on-mouseup="applyBlur"
            :on-touchend="applyBlur"
            :inline-label="isMobile"
          />
        </div>
        <div class="effect-container flex flex-col gap-3 h-24 !px-4">
          <div class="flex items-center gap-2">
            <Toggle
              v-if="shadowToggleEnabled"
              :name="I18n.t('editor.apply_shadow')"
              :model-value="props.selectedImageState.isShadowLayerVisible"
              :label="I18n.t('editor.apply_shadow')"
              @update:modelValue="updateShadowVisibility"
            />
            <span
              v-else
              class="hover-tooltip"
              :data-tippy-content="I18n.t('editor.no_shadow_tooltip')"
              data-tippy-placement="right"
            >
              <Toggle
                :name="I18n.t('editor.apply_shadow')"
                :model-value="false"
                :label="I18n.t('editor.apply_shadow')"
                :disabled="!shadowToggleEnabled"
                @update:modelValue="updateShadowVisibility"
              />
            </span>
            <a
              href="https://www.remove.bg/help/a/enhance-your-images-with-the-ai-shadow-feature"
              class="text-sm text-typo-tertiary pointer no-underline hover:text-brand-typo flex items-center gap-1"
              target="_blank"
              >{{ I18n.t("editor.beta") }} <OpenInNewIcon style="scale: 0.8" />
            </a>
          </div>
          <RangeSlider
            :value="$props.selectedImageState?.shadowOpacity"
            @slider-value-changed="updateShadowOpacityValue"
            :label="I18n.t('editor.opacity')"
            :disabled="!props.selectedImageState.isShadowLayerVisible || !shadowToggleEnabled"
            :on-mousedown="cacheState"
            :on-touchstart="cacheState"
            :on-mouseup="applyShadow"
            :on-touchend="applyShadow"
            :inline-label="isMobile"
          />
        </div>
      </div>
    </template>
    <template #mobile-actions>
      <div class="flex justify-end w-full">
        <TextButton v-if="!isNewLayoutEnabled" @click="closePanel">{{ I18n.t("editor.done") }}</TextButton>
      </div>
    </template>

    <template #actions>
      <div class="flex justify-end w-full">
        <Button v-if="!isNewLayoutEnabled" :full-width="true" @click="closePanel">{{ I18n.t("editor.done") }}</Button>
      </div>
    </template>
  </Drawer>
</template>

<script setup lang="ts">
import "@/src/i18n";
import tippy from "tippy.js";

import { Button, Toggle, Drawer, TextButton, OpenInNewIcon } from "prism";

import RangeSlider from "../prism/range_slider.vue";
import { onMounted, ref, inject, ComputedRef, computed } from "vue";
import { useEditorStore } from "@/stores/editor_store";
import { rbgEditorApplyBlurV100, rbgEditorApplyShadowV101 } from "kaleido-event-tracker";
import { useMonitorSize } from "@/composables/monitor_size";
import Flipper from "@/modules/flipper";

const I18n = inject("I18n");
const shadowToggleEnabled: ComputedRef<boolean> = inject("shadowToggle");
const { isMobile } = useMonitorSize();

interface AddBackgroundProps {
  foregroundType: string;
  selectedImageState: any;
}

const props = defineProps<AddBackgroundProps>();
const store = useEditorStore();

const cachedState = ref<any>(null);
const nextBlurRadius = ref<number>(0);
const nextShadowOpacity = ref<number>(0);

onMounted(() => {
  nextBlurRadius.value = props.selectedImageState.blurRadius;
  nextShadowOpacity.value = props.selectedImageState.shadowOpacity;
  tippy("[data-tippy-content]", { allowHTML: true, interactive: true });
});

const applyShadow = () => {
  props.selectedImageState.snapshotWithState(cachedState.value);
  props.selectedImageState.persist();

  rbgEditorApplyShadowV101({
    image_id: store.selectedImage.meta.id,
    opacity_value: props.selectedImageState.shadowOpacity,
    default_shadow: false,
  });
};

const updateShadowVisibility = (value: boolean): void => {
  props.selectedImageState.withSnapshot(() => {
    props.selectedImageState.setShadowLayerVisible(value);
  });

  const opacity = value ? props.selectedImageState.shadowOpacity : 0;
  rbgEditorApplyShadowV101({ image_id: store.selectedImage.meta.id, opacity_value: opacity, default_shadow: false });
};

const updateShadowOpacityValue = (value) => {
  nextShadowOpacity.value = parseInt(value, 10);
  props.selectedImageState.setShadowOpacity(nextShadowOpacity.value);
};

const updateBlurState = (value: boolean): void => {
  props.selectedImageState.withSnapshot(() => {
    props.selectedImageState.setBackgroundBlurEnabled(value);
  });

  const eventBlurRadius = value === true ? props.selectedImageState.blurRadius : 0;
  rbgEditorApplyBlurV100({ image_id: store.selectedImage.meta.id, blur_value: eventBlurRadius });
};

const cacheState = () => {
  const clonedState = JSON.parse(JSON.stringify(props.selectedImageState.current));
  cachedState.value = clonedState;
};

const applyBlur = () => {
  props.selectedImageState.setBlurRadius(nextBlurRadius.value);
  props.selectedImageState.snapshotWithState(cachedState.value);
  props.selectedImageState.persist();
  rbgEditorApplyBlurV100({ image_id: store.selectedImage.meta.id, blur_value: props.selectedImageState.blurRadius });
};

const isNewLayoutEnabled = Flipper.isEnabled('background_image_search');
const closeIconPosition = isNewLayoutEnabled ? "floating" : "contained";

const contentWrapperClasses = computed(() => ({
  "!pt-6": isNewLayoutEnabled,
}));

const emit = defineEmits<{
  (e: "closePanel"): void;
}>();

const closePanel = () => {
  emit("closePanel");
};

const changeBlurRadius = (blurRadius) => {
  const parsedBlurRadius = parseInt(blurRadius, 10);
  nextBlurRadius.value = parsedBlurRadius;
};
</script>

<style scoped>
.min-width-desktop {
  min-width: 18rem;
  width: 100%;
}

.effect-container {
  @media (min-width: 768px) {
    margin-bottom: 1rem;
  }
}
</style>
