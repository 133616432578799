<template>
  <Drawer :on-close="closePanel" :close-icon-position="closeIconPosition" :close-title="I18n.t('editor.modal_close')" class="eraseRestoreDrawer">
    <template #content>
      <div class="w-full flex md:gap-6 flex-col md:!px-0" :class="contentWrapperClasses">
        <div class="bg-shape rounded-lg !p-0 hidden md:flex gap-3 items-center">
          <template v-if="store.isMagicBrushEnabled">
            <div class="rounded-lg h-24 w-24 shrink-0 overflow-hidden">
              <video
                :src="imgEraseRestore"
                class="rounded-lg"
                width="100"
                height="100"
                :autoplay="true"
                :loop="true"
                :muted="true"
                :controls="false"
                webkit-playsinline
                playsinline
              />
            </div>
            <div class="flex flex-col gap-1 pr-2">
              <p class="text-xs font-bold text-typo-tertiary !m-0">{{ I18n.t("editor.magic_brush") }}</p>
              <p class="text-sm text-typo !m-0">{{ I18n.t("editor.easily_erase_or_restore_anything") }}</p>
            </div>
          </template>

          <div v-else class="h-24 flex flex-col items-center justify-center w-full">
            <p class="text-sm text-typo !m-0">{{ I18n.t("editor.manually_erase_or_restore") }}</p>
          </div>
        </div>

        <div class="flex gap-2">
          <ToggleButton :selected="store.drawSettings.action === DrawAction.Erase" @click="enterEraseMode()">
            <RemoveIconCircleLarge class="hidden md:block" />
            <RemoveIconCircle class="md:hidden" />
            <span>{{ I18n.t("editor.erase") }}</span>
          </ToggleButton>

          <ToggleButton :selected="store.drawSettings.action === DrawAction.Restore" @click="enterRestoreMode()">
            <AddIconCircleLarge class="hidden md:block" />
            <AddIconCircle class="md:hidden" />
            <span>{{ I18n.t("editor.restore") }}</span>
          </ToggleButton>
        </div>

        <div
          class="!pt-2"
          @mousedown="handleMouseDown"
          @mouseup="handleMouseUp"
          @touchstart="handleMouseDown"
          @touchend="handleMouseUp"
        >
          <RangeSlider
            v-if="store.isMagicBrushEnabled"
            inline-label
            :label="I18n.t('editor.brush_size')"
            :value="magicBrushSliderPosition"
            @update="updateBrushSize"
          />
          <RangeSlider
            v-else
            inline-label
            :label="I18n.t('editor.brush_size')"
            :value="manualBrushSliderPosition"
            @update="updateBrushSize"
          />
        </div>

        <div class="flex justify-between gap-2">
          <Toggle
            :name="I18n.t('editor.magic_brush')"
            :model-value="store.isMagicBrushEnabled"
            :label="I18n.t('editor.magic_brush')"
            @update:modelValue="updateBrushMode"
          />
          <TextButton v-if="isNewLayoutEnabled" class="md:hidden" variant="secondary" @click="reset">{{ I18n.t("editor.reset") }}</TextButton>
        </div>
      </div>
    </template>

    <template v-if="!isNewLayoutEnabled" #mobile-actions>
      <TextButton variant="secondary" @click="reset">{{ I18n.t("editor.reset") }}</TextButton>
      <TextButton @click="closePanel">{{ I18n.t("editor.done") }}</TextButton>
    </template>

    <template #actions>
      <Button :full-width="true" variant="secondary" @click="reset">{{ I18n.t("editor.reset") }}</Button>
      <Button v-if="!isNewLayoutEnabled" :full-width="true" @click="closePanel">{{ I18n.t("editor.done") }}</Button>
    </template>
  </Drawer>
</template>

<script setup lang="ts">
import "@/src/i18n";

import { ref, computed, inject } from "vue";

import {
  Drawer,
  RangeSlider,
  Button,
  Toggle,
  ToggleButton,
  TextButton,
  AddIconCircle,
  AddIconCircleLarge,
  RemoveIconCircle,
  RemoveIconCircleLarge,
} from "prism";

import imgErase from "@assets/images/erase.mp4";
import imgRestore from "@assets/images/restore.mp4";

import { useEditorStore } from "@/stores/editor_store";
import { DrawAction } from "@/stores/line";
import { Stage } from "konva/lib/Stage";
import { useBrushCursor } from "@/composables/brush_cursor";
import Flipper from "@/modules/flipper";

const I18n = inject("I18n");

const store = useEditorStore();

interface EraseRestoreProps {
  editor: HTMLElement;
  stage: Stage;
  scale: number;
}

const props = defineProps<EraseRestoreProps>();

const brushCursor = useBrushCursor({ editor: props.editor, stage: props.stage });

// Manual Brush
const maxBrushSize = 200;
const minBrushSize = 1;
const BRUSH_SIZE_DEFAULT = 10;
const brushSize = ref<number>(store.drawSettings?.brushSize || BRUSH_SIZE_DEFAULT);

// Magic Brush
const maxMagicBrushSize = 200;
const minMagicBrushSize = 10;
const MAGIC_BRUSH_SIZE_DEFAULT = 50;
const magicBrushSize = ref<number>(store.drawSettings?.magicBrushSize || MAGIC_BRUSH_SIZE_DEFAULT);

const manualBrushSliderPosition = computed(() => {
  return ((brushSize.value - minBrushSize) / (maxBrushSize - minBrushSize)) * 100;
});

const magicBrushSliderPosition = computed(() => {
  return ((magicBrushSize.value - minMagicBrushSize) / (maxMagicBrushSize - minMagicBrushSize)) * 100;
});

const handleMouseDown = () => {
  store.isAdjustingBrushSize = true;
  const position = brushCursor.centralCursorPosition();
  if (store.isMagicBrushEnabled) {
    store.magicBrushCursorPosition = position;
  } else {
    store.manualBrushCursorPosition = position;
  }
};

const handleMouseUp = () => {
  store.isAdjustingBrushSize = false;
};

const updateBrushSize = (value: number) => {
  const newBrushSize = brushCursor.updateBrushSize(value);
  if (store.isMagicBrushEnabled) {
    magicBrushSize.value = newBrushSize;
  } else {
    brushSize.value = newBrushSize;
  }
};

const updateBrushMode = (value: boolean) => {
  store.isMagicBrushEnabled = value;
};

const imgEraseRestore = computed(() => (store.drawSettings.action === DrawAction.Erase ? imgErase : imgRestore));

const isNewLayoutEnabled = Flipper.isEnabled('background_image_search');
const closeIconPosition = isNewLayoutEnabled ? "floating" : "contained";

const contentWrapperClasses = computed(() => ({
  "gap-2 !p-6 !pb-2 md:!p-0": isNewLayoutEnabled,
  "gap-3 !p-3": !isNewLayoutEnabled,
}));

const enterEraseMode = () => {
  store.enterEraseMode();
};

const enterRestoreMode = () => {
  store.enterRestoreMode();
};

const emit = defineEmits<{
  (e: "closePanel"): void;
  (e: "reset"): void;
}>();

const closePanel = () => {
  emit("closePanel");
};

const reset = () => {
  emit("reset");
};
</script>
<style>
.eraseRestoreDrawer {
  @media (min-width: 768px) {
    width: 24.25rem;
  }
}
</style>
