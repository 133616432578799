import Appsignal from "@appsignal/javascript" 

// Example
//
//  error_tracking = new ErrorTracking();
//  error_tracking.tag_request({user_id: 123});
//  error_tracking.set_action('ErrorTest');
//
//  try
//    adddlert("Welcome guest!");
//  catch err
//    error_tracking.sendError(err)
//

class ErrorTracking {
  constructor() {
    var tag = document.head.querySelector("meta[property='appsignal:frontend'][content]");
    this.active = tag ? true : false;
    this.appsignal = new Appsignal({ 
      key: tag ? tag.content : ""
    });
    this.action = "";
    this.tags   = {};
  }

  set_action(action) {
    this.action = action;
  }

  tag_request(tags) {
    this.tags = {...this.tags, ...tags};
  }

  sendError(error) {
    if(!this.active) return;
    const span = this.appsignal.createSpan();
    span.setAction(atob(this.action));
    span.setTags(this.tags).setError(error);
    
    this.appsignal.send(span)
  }
}

window.trackErrors = (action, tags) => {
  var error_tracking  = new ErrorTracking();
  error_tracking.set_action(action);
  error_tracking.tag_request(tags);
  window.error_tracking = error_tracking;

  window.onerror = (message, filename, lineno, colno, error) => {
    if(error) {
      error_tracking.sendError(error);
    }
    else {
      error_tracking.sendError(new Error('Null error raised, no exception message available'));
    }
  }
}
