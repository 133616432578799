class Routes {
  static get(routeName: string): string {
    const values = this.parseValues()
    if (!values.hasOwnProperty(routeName)) return "/"
    return values[routeName]
  }

  private static parseValues(): Object {
    const values = document.querySelector('meta[name="routes"]')
    try {
      const parsedValues = JSON.parse(values?.getAttribute("content") || "{}")
      return parsedValues;
    } catch (_error) {
      return {}
    }
  }
}

export default Routes
