<template>
  <Dialog ref="dialog" :dismissable="false" trigger-classes="flex justify-center sm:justify-start focus:outline-none" dialog-classes="!p-6 dialog-wrapper" :on-open="onOpen">
    <template #trigger>
      <slot name="trigger" />
    </template>
    <template v-slot:content="{ closeDialog }">
      <div v-if="showThankYouDialogFlag" class="text-center">
        <div class="text-primary flex justify-center mb-4"><TickCircleIcon /></div>
        <p class="mb-0">{{ I18n.t("improve.thank_you_for_image_submit") }}</p>
      </div>
      <div v-else class="flex flex-col gap-y-6">
        <template>
          <template v-if="slots.content">
            <slot name="content"></slot>
          </template>
          <p class="mb-0" v-else>
            <strong class="font-body text-xl font-weight-500 text-typo">{{ I18n.t("improve.thanks_for_being_honest")}}{{' '}}</strong>
            <span class="font-body text-base text-typo">{{ I18n.t("improve.help_further_improve_ai")}}</span>
          </p>
        </template>

        <p class="mb-0">
          <p class="!mb-3"><Checkbox :v-model="consented" :error="hasConsentError" @update:modelValue="handleConsentChange"><span v-html="I18n.t('improve.agree_to_x', { x: ipc_url })"></span></Checkbox></p>
          <p><Checkbox :v-model="rememberDecision" @update:modelValue="(newValue) => (rememberDecision = newValue)">{{ I18n.t("improve.remember") }}</Checkbox></p>
        </p>

        <div class="sm:flex sm:flex-row-reverse sm:gap-x-3 space-y-3 sm:space-y-0 grow">
          <Button
            class="w-full md:w-auto"
            autofocus
            type="button"
            :disabled="!consented"
            :on-click="onSubmit"
          >{{ I18n.t("improve.submit_image") }}</Button
          >
          <Button
            class="!bg-transparent hover:!bg-transparent active:!bg-transparent text-primary hover:!text-primary-hover text-base w-full md:w-auto"
            type="reset"
            :on-click="handleClose"
          >{{ I18n.t("editor.cancel") }}</Button
          >
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import "@/src/i18n";
import { Dialog, Button, Checkbox } from "prism";
import TickCircleIcon from "./tick_circle_icon.vue";
import improvedecision from "@/src/improvedecision";
import { onMounted, ref, watch, inject, computed, onUpdated, useSlots } from "vue";
import Routes from "@/modules/routes";
import { Image, ProcessingState } from "@/modules/internal_api/image";
import Client from "@/modules/internal_api/client";
import { rbgRateBadV100, rbgImprovementProgramConsentPanelOpenedV100, rbgImprovementProgramConsentPanelSubmittedV100 } from "kaleido-event-tracker";
import { useEditorStore } from "@/stores/editor_store";

import type { I18n } from "i18n-js";

const I18n: I18n = inject("I18n");
const slots = useSlots();

interface QIPDialogProps {
  image: Image;
  triggerClass: string
  showThankYouDialog: boolean
}

const props = defineProps<QIPDialogProps>();

const consented = ref<Boolean>(false);
const hasConsentError = ref<Boolean>(false);
const rememberDecision = ref<Boolean>(false);

const isRated = ref<Boolean>(false);
const rating = ref<Number>(0);
const store = useEditorStore();
const dialog = ref(null);

onMounted(() => {
  updateConsentedValue();
  refresh();
});

onUpdated(async () => {
  if (showThankYouDialogFlag.value) {
    await showThankyouDialog();
  }
});

watch(() => props.image, () => refresh());

const emit = defineEmits<{
  (e: "onClose"): void;
  (e: "onSubmit"): void;
}>();

const onOpen = () => {
   rbgImprovementProgramConsentPanelOpenedV100({ image_id: props.image.meta.id } );
};

const handleClose = () => {
  onlyRate()
  emit("onClose");
  closeDialog();
};

const onSubmit = async () => {
  rbgImprovementProgramConsentPanelSubmittedV100({ image_id: props.image.meta.id, remember_decision: rememberDecision.value as boolean });
  await contribute();
  if (!hasConsentError.value) {
    emit("onSubmit");
    closeDialog();
  }
};

const updateConsentedValue = () => {
  const decision = improvedecision.get();

  if (decision === undefined) {
    consented.value = false;
  } else if (decision === "accept") {
    consented.value = true;
  } else if (decision === "reject") {
    consented.value = false;
  }
}

const closeDialog = () => {
  dialog.value?.closeDialog();
}

const refresh = () => {
  if (props.image?.previewResult?.state === ProcessingState.Finished) {
    isRated.value = props.image.previewResult.rated;
    rating.value = props.image.previewResult.rating;
  } else {
    isRated.value = false;
    rating.value = 0;
  }
};

const handleConsentChange = (newValue) => {
  hasConsentError.value = false;
  consented.value = newValue;
};

const contribute = async () => {
  if (!consented.value) {
    hasConsentError.value = true;
    return;
  }
  await showThankyouDialog();
};

const showThankYouDialogFlag = ref<Boolean>(props.showThankYouDialog || false);
const CLOSE_THANK_YOU_DIALOG_IN_MS = 1800;
const showThankyouDialog = async () => {
  showThankYouDialogFlag.value = true;
  await new Promise((resolve, reject) => {
    setTimeout(() => {
      rateNegative();
      saveDecision();
      showThankYouDialogFlag.value = false;
      resolve(null);
    }, CLOSE_THANK_YOU_DIALOG_IN_MS);
  });
  closeDialog();
}

const onlyRate = () => {
  saveDecision();
  rateNegative();
};

const saveDecision = () => {
  if (rememberDecision.value) {
    improvedecision.set(consented.value ? "accept" : "reject");
  }
};

const rateNegative = () => {
  window.track("Images", "rate_bad", "Rate bad");
  rbgRateBadV100({ image_id: props.image.meta.id });
  rate(-1);
};

const rate = (newRating: number) => {
  Client.rateImage(props.image, newRating, consented.value);
  isRated.value = true;
  rating.value = newRating;

  const image = props.image;

  if (image.previewResult.state == ProcessingState.Finished) {
    image.previewResult.rated = isRated.value as boolean;
    image.previewResult.rating = rating.value as number;
    store.updateImage(image);
  }
};

const ipc_url = computed(() => {
  return `<a href="${Routes.get("ipc_url")}" target="_blank">${I18n.t("improve.ipc")}</a>`;
});

const showDialog = () => {
  dialog.value.showDialog();
}

defineExpose({
  showDialog,
  showThankyouDialog
});
</script>
