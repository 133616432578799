<template>
  <div v-if="editorStore.openedPanel === 'fx'">
    <FxDrawer
      :foreground-type="editorStore.selectedImage.meta.foregroundType"
      :selected-image-state="currentPersistentStore"
      @close-panel="editorStore.closePanel"
    />
  </div>
  <div v-if="editorStore.openedPanel === 'addBackground'">
    <DrawerAddBackground
      @close-panel="() => props.togglePanel('addBackground')"
      @reset="props.restoreOriginalResult"
      :foreground-type="editorStore.selectedImage.meta.foregroundType"
      :selected-image-state="props.currentPersistentStore"
    />
  </div>
  <div v-if="editorStore.openedPanel === 'eraseRestore'">
    <DrawerEraseRestore
      :editor="props.editor"
      :stage="props.stage"
      :scale="props.scale"
      @close-panel="() => props.togglePanel('eraseRestore')"
      @reset="restoreOriginalResult"
    />
  </div>
</template>
<script setup lang="ts">
import { Panel, useEditorStore } from "@/stores/editor_store";
import DrawerAddBackground from "@/components/upload/drawer_add_background.vue";
import DrawerEraseRestore from "@/components/upload/drawer_erase_restore.vue";
import FxDrawer from "@/components/upload/fx_drawer.vue";
import { Stage } from "konva/lib/Stage";

interface DrawerManagerProps {
  currentPersistentStore: any;
  editor: HTMLElement;
  stage: Stage;
  scale: number;
  restoreOriginalResult: () => void;
  togglePanel: (id: Panel) => void;
}

const props = defineProps<DrawerManagerProps>();
const editorStore = useEditorStore();
</script>
