<template>
  <div>
    <div class="d-flex flex-row justify-content-center">
      <div class="justify-content-center align-items-center d-none d-lg-flex pr-3">
        <div class="align-items-center d-none d-lg-flex h-100">
          <i class="navigation fas fa-2x fa-chevron-left" @click.prevent="slidePrev"></i>
        </div>
      </div>
      <div class="flex-grow-1 container mx-0 px-0">
        <hooper :settings="settings" ref="carousel" @slide="updateCarousel" class="h-auto">
          <slide v-for="(tool, index) in tools" :key="tool.id" :index="index" class="h-auto">
            <a :href="tool.content.show_url" class="card-link d-block h-100 p-2">
              <div class="h-100 shadow-sm d-flex flex-wrap bg-white p-2 p-md-4 align-items-center mx-2 position-relative">
                <span v-if="tool.content.badge_text.length > 0" class="badge badge-secondary badge-secondary-light text-uppercase position-absolute mx-auto">
                  {{ tool.content.badge_text }}
                </span>
                <div :style="toolLogoStyle(tool.content.icon.filename)" class="col-auto ml-3 ml-md-0"></div>
                <div class="pl-md-4 col-12 col-md d-flex flex-column mt-0">
                  <small class="headline-featured text-uppercase font-weight-bold mb-2">Featured</small>
                  <h4 class="mb-0 text-dark font-family-sans">{{ tool.name }}</h4>
                  <p class="text-featured">{{ tool.content.featured_text }}</p>
                  <a :href="tool.content.show_url">Learn more <i class="ml-1 fas fa-chevron-right"></i></a>
                </div>
              </div>
            </a>
          </slide>
        </hooper>
      </div>
      <div class="justify-content-center align-items-center d-none d-lg-flex pl-3">
        <div class="align-items-center d-none d-lg-flex h-100">
          <i class="navigation fas fa-2x fa-chevron-right" @click.prevent="slidePrev"></i>
        </div>
      </div>
    </div>
    <ul class="nav nav-tools justify-content-center mt-3 d-none d-md-flex">
      <li v-for="(tool, index) in tools" :key="tool.id" :index="index" class="nav-item">
        <a :class="{ active: carouselData == index }" @click.prevent="slideTo(index)">
          <i class="inactive-state fal fa-circle"></i>
          <i class="active-state fas fa-circle"></i>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { Hooper, Slide } from "@/vendor/hooper.esm";
import "hooper-vue3/dist/hooper.css";

export default {
  props: {
    tools: Array,
  },

  data() {
    return {
      carouselData: 0,
      settings: {
        infiniteScroll: true,
        wheelControl: false,
        centerMode: true,
        itemsToShow: 1.25,
        mouseDrag: false,
        breakpoints: {
          577: {
            centerMode: true,
            itemsToShow: 1,
          },
          769: {
            centerMode: false,
            itemsToShow: 2,
          },
        },
      },
    };
  },

  watch: {
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    },
  },

  methods: {
    updateCarousel(payload) {
      this.carouselData = Math.abs(payload.currentSlide) % this.tools.length;
    },

    slideTo(index) {
      this.carouselData = index;
    },

    slidePrev() {
      this.carouselData = (this.carouselData + 1) % this.tools.length;
    },

    slideNext() {
      this.carouselData = (this.carouselData - 1) % this.tools.length;
    },

    toolLogoStyle(url) {
      const cached_url = url.replace("//a.storyblok.com/f/67418/", "//sb.kaleidousercontent.com/67418/");

      return {
        backgroundImage: `url("${cached_url}")`,
        backgroundSize: "contain",
        borderRadius: "0.25rem",
        width: "75px",
        height: "75px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };
    },
  },

  components: {
    Hooper,
    Slide,
  },
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 1.3rem;
}

.details {
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }
}

.card-link {
  &:hover {
    text-decoration: none;
  }
  a {
    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }
}

i.navigation {
  color: #b9bfc3;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #454545;
  }
}

.headline-featured {
  color: #bac0c4;
}

.text-featured {
  @media (min-width: 768px) {
    font-size: 1.2rem;
  }

  color: #768089;
}

.badge {
  left: 50%;
  transform: translateX(-50%);
  top: -9px;
}

.nav-tools {
  a {
    cursor: pointer;
    font-size: 12px;
    padding: 12px;
  }

  .active-state {
    display: none;
  }

  .active {
    .inactive-state {
      display: none;
    }

    .active-state {
      display: inline;
    }
  }
}
</style>
