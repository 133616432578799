<template>
  <div id="new-header" class="block md:hidden !bg-white !bg-opacity-90 !backdrop-blur-lg fixed w-full z-50 top-0 px-2 relative">
    <Container full-width-on-mobile>
      <div class="header-wrapper flex justify-between items-center" :class="headerWrapperClasses">
        <!-- Start Left -->
        <NavLogo :href="User.mobileApp() ? 'javascript:' : Routes.get('root_url')">
          <span class="sr-only">remove.bg</span>
          <LogoRBGIconOnly v-if="showIconOnlyRBGLogo()" />
          <LogoRBG v-else style="max-height: 60px" />
        </NavLogo>

        <div class="flex">
          <div v-if="store.selectedImage !== null" class="action-btn-wrapper flex custom-gap" :class="actionIconWrapperClass">
            <IconButton @mousedown="toggleCompareBefore" @mouseup="toggleCompareAfter" @touchstart="toggleCompareBefore" @touchend="toggleCompareAfter" variant="secondary"><CompareIcon :class="{ 'text-brand-typo': store.viewMode == ViewMode.Before, 'text-typo': store.viewMode == ViewMode.After }" /></IconButton>
            <IconButton variant="secondary" :disabled="!currentPersistentStore?.canUndo" @click="currentPersistentStore?.undo"><UndoIcon /></IconButton>
            <IconButton variant="secondary" :disabled="!currentPersistentStore?.canRedo" @click="currentPersistentStore?.redo"><RedoIcon /></IconButton>
          </div>
          <span v-if="User.mobileApp()" class="action-btn-wrapper">
            <Dropdown align-right>
              <template v-slot:trigger>
                <IconButton variant="secondary"><OverflowIcon /></IconButton>
              </template>
              <template v-slot:content>
                <DropdownBox>
                  <ul class="overflow-dropdown flex flex-col text-base !p-3 mb-0">
                    <template v-if="User.signedIn()">
                      <li><a href="removebg://account" class="text-typo">{{ I18n.t("navigation.my_account") }}</a></li>
                      <li><a href="removebg://logout" class="text-typo">{{ I18n.t("navigation.logout") }}</a></li>
                    </template>
                    <li v-else><a href="removebg://login" class="text-typo">{{ I18n.t("navigation.login") }}</a></li>
                  </ul>
                </DropdownBox>
              </template>
            </Dropdown>
          </span>
        </div>
        <!-- End Left -->
      </div>
    </Container>
  </div>
</template>

<script setup lang="ts">
import { NavLogo, IconButton, CompareIcon, Container, RedoIcon, UndoIcon, LogoRBG, Dropdown, DropDownbox } from "prism";
import LogoRBGIconOnly from "../prism/rbg_icon_only_logo.vue";
import OverflowIcon from "../prism/overflow_icon.vue";
import Routes from "@/modules/routes";
import { useEditorStore, ViewMode } from "@/stores/editor_store";
import User from "@/modules/user";
import { computed } from "vue";

interface HeaderProps {
  currentPersistentStore?: any;
}

defineProps<HeaderProps>();
const store = useEditorStore();

const emit = defineEmits<{
  (e: "toggleCompareBefore"): void;
  (e: "toggleCompareAfter"): void;
}>();

const toggleCompareBefore = () => {
  emit("toggleCompareBefore");
};

const toggleCompareAfter = () => {
  emit("toggleCompareAfter");
};

const showIconOnlyRBGLogo = () => {
  return User.mobileApp() && store.selectedImage;
}

const headerWrapperClasses = computed(() => ({
  "p-2": showIconOnlyRBGLogo(),
}));

const actionIconWrapperClass = computed(() => ({
  "action-icons-gap": showIconOnlyRBGLogo(),
}))
</script>

<style scoped>
#new-header {
  transition: top 0.5s;
}

.custom-gap {
  gap: 8px;
}

.action-icons-gap {
  gap: 8px !important;
}

.overflow-dropdown {
  width: max-content;
  gap: 0.75rem;
}

@media (max-width: 345px) {
  .header-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 375px) {
  .custom-gap {
    gap: 0;
  }
}
</style>
