<template>
  <div class="share-overlay">
    <div class="backdrop" v-on:click="close"></div>
    <div class="content">
      <div v-if="base64 == null" class="d-flex align-items-center">
        <img src="@public/images/loader.gif" alt="Processing…" style="width: 25px; height: 25px; display: inline-block; margin: 10px" />
        {{ t.preparing_image_for_download }}
      </div>

      <div v-if="base64 != null" class="text-center">
        <button class="close-btn" v-on:click="close" :title="t.modal_close">
          <i class="fas fa-times"></i>
        </button>
        <p style="margin-bottom: 0;">
          <div v-if="shareSupported" class="text-center">
            <h3 class="mt-3">{{ t.share_your_image }}</h3>
            <img class="p-3 pb-4" :src="base64" style="max-width: 100%">
            <button class="btn btn-primary btn-lg mb-2" v-on:click="shareImage" style="width: 75%;">
              <i class="fas fa-share-alt"></i>
              {{ t.share_image }}
            </button>
          </div>
          <div v-else class="text-center">
            <img class="p-3" :src="thumbnail_image" style="max-width: 100%">
            <h3 style="font-size: 120%"><b>{{ t.spread_the_word }}:</b></h3>
            <h3 style="font-size: 120%"><b>{{ t.share_this_design_template }}</b></h3>
            <div class="mt-4 mb-4">
              <a :href='"https://www.facebook.com/sharer/sharer.php?u="+href' style="color: #3b5998; text-decoration: none; display: inline-block;" target="_blank" rel="noopener">
              <i class='fab fa-facebook-square fa-3x mr-2' style='color: #3b5998; vertical-align: middle;'></i> <b>{{ t.share }}</b></a>
              <a :href='"https://twitter.com/intent/tweet?text=&hashtags=removebg&url="+href' style="color: #1DA1F2; text-decoration: none; display: inline-block;" class="ml-4" target="_blank" rel="noopener">
              <i class='fab fa-twitter fa-3x mr-1' style='color: #1DA1F2; vertical-align: middle;'></i> <b>{{ t.tweet }}</b></a>
            </div>
          </div>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';

const isUCBrowser = navigator.userAgent.indexOf(' UCBrowser/') >= 0;

function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function() {
      resolve(reader.result);
    }
  });
}

export default {
  props: {
    base64: String,
    filename: String,
    t: Object,
    thumbnail_image: String
  },
  data() {
    return {
      canSupportDownloadAttribute: !isUCBrowser
    };
  },
  computed: {
    href() {
      return encodeURI(window.location.href);
    },
    file() {
      if(this.base64) {
        let matches = this.base64.match(/^data:([^;]+);base64,(.*)$/);
        let mimeType = matches[1];
        let extension = matches[1].substr(matches[1].indexOf('/')+1);
        var arr = this.base64.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        var filename = "share."+extension;
        return new File([u8arr], filename, {type:mimeType});
      }
      return null;
    },
    shareSupported() {
      return this.file && (window.mobile_app || navigator.canShare && navigator.canShare({files: [this.file]}));
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    shareImage() {
      // track click
      window.track("Images", "share_from_templates", "Share from Templates");
      if(window.hj) window.hj('trigger', 'templates_download');

      navigator.share({
        files: [this.file],
        text: 'www.remove.bg'
      }).then(() => {
        //alert("shared");
      }).catch((error) => {
        //alert(error);
      });
    }
  }
}
</script>

<style scoped>
.share-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19000;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-overlay .backdrop {
  background: rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19020;
}
.share-overlay .content {
  background: #fff;
  position: relative;
  z-index: 19050;
  padding: 10px;
  margin: 25px;
  max-width: 345px;
  border-radius: 3px;
}
.close-btn {
  position: absolute;
  right: -20px;
  top: -20px;
  background: transparent;
  border: 1px solid #595959;
  color: #595959;
  font-size: 16px;
  width: 34px;
  height: 34px;
  background: #fff;
  padding: 8px;
  margin: 0;
  border-radius: 19px;
  line-height: 1;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.close-btn:hover, .close-btn:focus {
  background: #eee;
}
</style>
