<template>
  <div class="color-picker">
    <div class="backdrop"></div>
    <div class="content">
      <div ref="colorInput"></div>
      <div class="buttonrow">
        <button
          class="btn btn-primary"
          v-on:click="
            select();
            close();
          "
        >
          OK
        </button>
        <button class="btn btn-outline-primary" v-on:click="close()">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as AColorPicker from "a-color-picker";

export default {
  props: {
    value: String,
  },
  data() {
    return {
      colorpicker: null,
    };
  },
  mounted() {
    this.colorpicker = AColorPicker.from(this.$refs.colorInput, {
      showHSL: false,
      showRGB: false,
      palette: "PALETTE_MATERIAL_CHROME",
      color: this.value,
    })[0];
  },
  methods: {
    select: function () {
      this.$emit("change", { newValue: this.colorpicker.rgbhex });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.color-picker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19000;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-picker .backdrop {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19020;
}
.color-picker .content {
  background: #fff;
  position: relative;
  z-index: 19050;
  padding: 15px;
}
.buttonrow {
  margin-top: 15px;
  display: -webkit-flex;
  display: flex;
}
.buttonrow .btn {
  width: 50%;
}
</style>
