import { Stage } from "konva/lib/Stage";
import type { Point } from "@/stores/line";
import { useEditorStore } from "@/stores/editor_store";

interface BrushCursorProps {
  stage: Stage;
  editor: HTMLElement;
}

export interface CursorDimension {
  height?: number;
  width?: number;
  cx?: number;
  cy?: number;
  r?: number;
}

/**
 * useBrushCursor is a composable that provides functions and utilities for the brush cursor in the canvas
 */
export function useBrushCursor(props: BrushCursorProps) {
  const store = useEditorStore();

  const maxMagicBrushSize = 200;
  const minMagicBrushSize = 10;

  const maxBrushSize = 200;
  const minBrushSize = 1;

  /**
   * Returns the cursor position such that the cursor is at the center of the canvas in the editor
   */
  const centralCursorPosition = (stage?: Stage) => {
    const node = props.editor;
    if (node === undefined || node === null) return { x: 0, y: 0 };

    const box = node.getBoundingClientRect();
    const centerX = (box.left + box.right) / 2;
    const centerY = (box.top + box.bottom) / 2;

    const size = scaledCursorSize(stage);

    const x = centerX - size / 2;
    const y = centerY - size / 2;

    return { x, y };
  };

  const calculateCursorPosition = (e: MouseEvent, stage?: Stage): Point => {
    const brushSize = getBrushSize();

    let stg = stage || props.stage;
    const scale = stg?.getStage()?.scaleX() || 1;
    const x = e.pageX - (brushSize * scale) / 2;
    const y = e.pageY - (brushSize * scale) / 2;

    if (store.isMagicBrushEnabled) {
      store.magicBrushCursorPosition = { x, y };
    } else {
      store.manualBrushCursorPosition = { x, y };
    }

    return { x, y };
  };

  const scaledCursorSize = (stage?: Stage) => {
    const size = getBrushSize();

    let stg = stage || props.stage;
    const scale = stg?.getStage()?.scaleX() || 1;

    return size * scale;
  };

  const updateBrushSize = (value: number) => {
    if (value === undefined || value === null) return;

    let newBrushSize: number;
    if (store.isMagicBrushEnabled) {
      newBrushSize = Math.round((value / 100) * (maxMagicBrushSize - minMagicBrushSize) + minMagicBrushSize);
      store.drawSettings.magicBrushSize = newBrushSize;
      store.magicBrushCursorPosition = centralCursorPosition();
    } else {
      newBrushSize = Math.round((value / 100) * (maxBrushSize - minBrushSize) + minBrushSize);
      store.drawSettings.brushSize = newBrushSize;
      store.manualBrushCursorPosition = centralCursorPosition();
    }

    return newBrushSize;
  };

  const resetCursorPositions = () => {
    store.magicBrushCursorPosition = { x: 0, y: 0 };
    store.manualBrushCursorPosition = { x: 0, y: 0 };
  };

  const cursorDimensions = (stage: Stage): CursorDimension => {
    const size = scaledCursorSize(stage);

    return {
      height: size,
      width: size,
      cx: size / 2,
      cy: size / 2,
      r: size / 2,
    };
  };

  const getBrushSize = () =>
    store.isMagicBrushEnabled ? store.drawSettings.magicBrushSize : store.drawSettings.brushSize;

  return {
    centralCursorPosition,
    calculateCursorPosition,
    scaledCursorSize,
    updateBrushSize,
    resetCursorPositions,
    cursorDimensions,
  };
}
