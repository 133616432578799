import { Stage } from "konva/lib/Stage";
import { Point } from "@/stores/line";

interface UpdateStageArgs {
  height?: number;
  width?: number;
  scale?: number;
  position?: Point;
}

export class EditorStage {
  stage: Stage;
  bgStage: Stage;
  updateStatus = "idle";
  updateStageArgs: UpdateStageArgs = {};

  constructor(stage: Stage, bgStage: Stage) {
    this.stage = stage;
    this.bgStage = bgStage;
  }

  updateStages(args: UpdateStageArgs = {}) {
    if (!this.bgStage || !this.stage) return;

    this.updateStageArgs = { ...this.updateStageArgs, ...args };
    if (this.updateStatus === "executing") return;

    this.updateStatus = "executing";
    const { height, width, scale, position } = this.updateStageArgs;
    [this.stage.getStage(), this.bgStage.getStage()].forEach((stage) => {
      if (width) stage.width(width);
      if (height) stage.height(height);
      if (scale) stage.scale({ x: scale, y: scale });
      if (position) stage.position(position);
    });

    this.updateStatus = "idle";
    this.updateStageArgs = {};
  }
}
