<template></template>

<script setup lang="ts">
import { onMounted } from "vue";

onMounted(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      const intersectingEntries = entries.filter((entry) => entry.isIntersecting);
      if (intersectingEntries.length > 0) {
        const firstEntry = intersectingEntries[0];

        const id = firstEntry.target.parentElement?.parentElement?.getAttribute("id");
        const link = document.querySelector(`#api-docs-nav a[href="#${id}"]`);

        if (link) {
          document.querySelectorAll("#api-docs-nav a").forEach((link) => {
            link.classList.remove("active");
          });
          link.classList.add("active");
        }
      }
    },
    {
      root: document.getElementById("#docs"),
      rootMargin: "0px",
      threshold: 1.0,
    }
  );

  document.querySelectorAll("h2").forEach((h2) => {
    observer.observe(h2);
  });
});
</script>
