var Auth = {
  listeners: [],
  onChange: function(listener) {
    Auth.listeners.push(listener);
  },
  attempt(origin, origin_path, login_url) {
    var iframe = document.createElement("iframe");

    function receiveMessage(e) {
      var data = e.data;
      switch(data.type) {
        case 'sso-signin':
          if(event.origin !== origin) return;
          iframe.src = login_url;
          break;
        case 'sso-successful':
          if(event.origin !== location.origin) return;
          for(var i = 0; i < Auth.listeners.length; i++) {
            Auth.listeners[i].call(window, data.data);
          }
          break;
      }
    }
    window.addEventListener("message", receiveMessage, false);

    iframe.src = origin + origin_path;
    iframe.width = 1;
    iframe.height = 1;
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  }
}

window.Auth = Auth;
