<template>
  <ButtonPlaceholder v-if="props.isProcessing"></ButtonPlaceholder>
  <template v-else>
    <template v-if="User.signedIn()">
      <Dialog
        v-if="store.selectedImage.hdResult.state !== ProcessingState.Finished && !props.isIosDisabled"
        trigger-classes="w-full focus:outline-none"
        :on-open="props.loadCreditsAndUpdatePreview"
      >
        <template #trigger>
          <Button
            :disabled="!props.enableDownloadHdButton"
            variant="secondary"
            :loading="props.isProcessingHdDownload"
            full-width
            >{{ I18n.t("image.download_high_res") }}</Button
          >
        </template>
        <template v-slot:content="{ closeDialog }">
          <template>
            <div class="flex flex-col gap-y-6 w-full">
              <div class="flex flex-col gap-y-0 mt-3">
                <Heading level="h4">{{ I18n.t("image.download_high_res") }}</Heading>
                <p class="!m-0 text-xl text-brand-typo font-bold">
                  HD {{ store.selectedImage.meta.hdWidth }}x{{ store.selectedImage.meta.hdHeight }}px
                </p>
              </div>

              <!-- Image Preview -->
              <div v-if="isPreviewLoading" class="flex justify-center">
                <div
                  class="rounded !bg-secondary !w-full animate-pulse"
                  :style="{ height: previewHeight, width: previewWidth }"
                ></div>
              </div>
              <div v-else class="sm:w-128 rounded-lg !bg-secondary border !border-secondary">
                <HTMLImage
                  v-if="store.selectedImage.meta.orientation === ImageOrientation.Portrait"
                  :src="store.selectedImage.meta.livePreview"
                  alt=""
                  width="200"
                  :fluid="false"
                  img-classes="bg-white checkerboard rounded-lg mx-auto"
                />
                <HTMLImage
                  v-else
                  :src="store.selectedImage.meta.livePreview"
                  alt=""
                  :fluid="false"
                  img-classes="!w-full bg-white checkerboard rounded-lg mx-auto"
                />
              </div>

              <div class="flex flex-col gap-y-3 justify-center">
                <div class="text-center">
                  <Button
                    class="mb-2"
                    size="lg"
                    type="submit"
                    :disabled="credits <= 0"
                    @click.prevent="() => props.downloadHd(closeDialog)"
                    >{{ I18n.t("image.download_high_res_for_1_credit") }}</Button
                  >
                </div>
                <div class="flex justify-center items-center space-x-4">
                  <p class="!m-0 text-typo">{{ I18n.t("full_image.you_have_credits", { credits: props.credits }) }}</p>
                  <a class="" :href="Routes.get('pricing_url')">{{ I18n.t("full_image.get_credits") }}</a>
                </div>
              </div>
            </div>
          </template>
        </template>
      </Dialog>
      <Dialog
        v-else-if="props.isIosDisabled"
        trigger-classes="w-full focus:outline-none"
        :on-open="props.loadCreditsAndUpdatePreview"
        :dismissable="false"
      >
        <template #trigger>
          <Button
            :disabled="!props.enableDownloadHdButton"
            variant="secondary"
            :loading="props.isProcessingHdDownload"
            full-width
            >{{ I18n.t("image.download_high_res") }}</Button
          >
        </template>
        <template v-slot:content="{ closeDialog }">
          <Ios16MPDialogContent :close-dialog="closeDialog" />
        </template>
      </Dialog>
      <Button
        v-else
        variant="secondary"
        @click.prevent="() => props.downloadHd()"
        :loading="isProcessingHdDownload"
        full-width
        >{{ I18n.t("image.download_high_res") }}</Button
      >
    </template>
    <template v-else>
      <Dialog trigger-classes="w-full focus:outline-none">
        <template v-slot:trigger>
          <Button variant="secondary" full-width>{{ I18n.t("image.download_high_res") }}</Button>
        </template>
        <template v-slot:content="{ closeDialog }">
          <div class="flex flex-col gap-y-6 w-full">
            <div class="flex flex-col gap-y-0 mt-3">
              <Heading level="h4">{{ I18n.t("image.download_high_res_image") }}</Heading>
              <p class="!m-0 text-xl text-brand-typo font-bold">
                HD {{ store.selectedImage.meta.hdWidth }}x{{ store.selectedImage.meta.hdHeight }}px
              </p>
            </div>

            <!-- Image Preview -->
            <div v-if="isPreviewLoading" class="flex justify-center">
              <div
                class="rounded !bg-secondary !w-full animate-pulse"
                :style="{ height: previewHeight, width: previewWidth }"
              ></div>
            </div>
            <div v-else class="sm:w-128 rounded-lg !bg-secondary border !border-secondary">
              <HTMLImage
                v-if="store.selectedImage.meta.orientation === ImageOrientation.Portrait"
                :src="store.selectedImage.meta.livePreview"
                alt=""
                width="200"
                :fluid="false"
                img-classes="bg-white checkerboard rounded-lg mx-auto"
              />
              <HTMLImage
                v-else
                :src="store.selectedImage.meta.livePreview"
                alt=""
                :fluid="false"
                img-classes="!w-full bg-white checkerboard rounded-lg mx-auto"
              />
            </div>

            <div class="flex flex-col gap-y-3 justify-center">
              <p class="!m-0 text-xl text-typo font-bold text-center">{{ I18n.t("full_image.get_this_free") }}:</p>
              <div class="text-center">
                <span onclick="window.track('Images', 'hd_download_signup', 'Signup for Account from HD Download')">
                  <Button as="link" :href="signupUrl()" size="lg" autofocus>{{
                    I18n.t("full_image.sign_up_free")
                  }}</Button>
                </span>
              </div>
              <div class="flex gap-2 justify-center">
                <span>{{ I18n.t("full_image.already_have_account") }}</span
                ><Link hideArrow :href="loginUrl()">{{ I18n.t("full_image.login") }}</Link>
              </div>
            </div>
          </div>
        </template>
      </Dialog>
    </template>
  </template>
</template>
<script setup lang="ts">
import "@/src/i18n";
import { useEditorStore } from "@/stores/editor_store";
import { Image, ImageOrientation } from "@/modules/internal_api/image";
import Routes from "@/modules/routes";
import { computed, inject } from "vue";
import User from "@/modules/user";
import { ProcessingState } from "@/modules/internal_api/image";
import { Dialog, Button, Image as HTMLImage, Heading, Link, ButtonPlaceholder } from "prism";
import Ios16MPDialogContent from "@/components/upload/ios_16mp_dialog_content.vue";

const I18n = inject("I18n");

const store = useEditorStore();

interface DownloadHdDialogProps {
  editor: any;
  enableDownloadHdButton: Boolean;
  isProcessing: Boolean;
  isProcessingHdDownload: Boolean;
  isProcessingPreviewDownload: Boolean;
  isIosDisabled: boolean;
  credits: any;
  loadCreditsAndUpdatePreview: any;
  downloadHd: any;
}

const emit = defineEmits<{
  (e: "closeDialog"): void;
}>();

const closeDialog = () => {
  emit("closeDialog");
};

const props = defineProps<DownloadHdDialogProps>();

const signupUrl = () => {
  if (User.mobileApp()) {
    return "removebg://login";
  }

  return Routes.get("signup_url");
};

const loginUrl = () => {
  if (User.mobileApp()) {
    return "removebg://login";
  }

  return Routes.get("login_url");
};

// Image Preview

/**
 * isPreviewLoading is a computed boolean that determines whether or not the image preview
 *  for the HD download is ready.
 *  It's set to true only after the livePreview of selectedImage is updated to the latest.
 *  Or if the livePreview needs a refresh after some editing.
 */
const isPreviewLoading = computed(() => {
  // We do not want to show a loader when the download is being processed.
  if (props.isProcessingHdDownload) {
    return false;
  }

  const isPreviewRefreshing = store.selectedImage.meta.refreshingLivePreview === true; // values could be nil, it doesn't mean it's refreshing.
  return isPreviewSameAsOriginal(store.selectedImage) || isPreviewRefreshing;
});
const isPreviewSameAsOriginal = (image: Image): boolean => {
  return image.meta.livePreview === image.original?.url;
};

const previewHeight = computed(() =>
  store.selectedImage.meta.previewHeight ? `${store.selectedImage.meta.previewHeight}px` : "406px"
);
const previewWidth = computed(() =>
  store.selectedImage.meta.previewWidth ? `${store.selectedImage.meta.previewWidth}px` : "614px"
);
</script>
