<template>
  <Image v-show="imageLoaded" :src="src" :alt="alt" :caption="alt" :fluid="false" :on-load="onLoad" :lazy="false" rounded="md" class="flex-shrink-0 w-3/4 sm:w-[55%] md:w-auto md:flex-shrink-1"> </Image>
  <div v-show="!imageLoaded" class="flex-shrink-0 w-3/4 sm:w-[55%] rounded-2xl !bg-secondary md:w-auto md:flex-shrink-1 animate-pulse aspect-3/4"></div>
</template>

<script setup>
import { ref } from "vue";
import { Image } from "prism";

const imageLoaded = ref(false);
const props = defineProps({
  alt: String,
  src: String,
  lazy: Boolean,
});

const onLoad = () => {
  imageLoaded.value = true;
};
</script>

<style scoped>
.aspect-3\/4 {
  aspect-ratio: 3/4;
}
</style>
