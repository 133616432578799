<template>
  <div>
    <div class="mx-auto position-relative my-3 my-md-5" style="max-width: 700px">
      <div class="d-none d-md-block sprite sprite-square" style="position: absolute; left: -50px; top: -50px; color: #d7eff3; width: 105px; height: 105px"></div>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="192" height="146" viewBox="0 0 192 146" class="d-none d-md-block sprite sprite-dots" style="position: absolute; right: -60px; bottom: -40px; color: #81c0db">
        <circle r="1.5" fill="currentColor" cx="2" cy="2"></circle>
        <circle r="1.5" fill="currentColor" cx="2" cy="25"></circle>
        <circle r="1.5" fill="currentColor" cx="2" cy="48"></circle>
        <circle r="1.5" fill="currentColor" cx="2" cy="71"></circle>
        <circle r="1.5" fill="currentColor" cx="2" cy="94"></circle>
        <circle r="1.5" fill="currentColor" cx="2" cy="117"></circle>
        <circle r="1.5" fill="currentColor" cx="2" cy="140"></circle>
        <circle r="1.5" fill="currentColor" cx="25" cy="2"></circle>
        <circle r="1.5" fill="currentColor" cx="25" cy="25"></circle>
        <circle r="1.5" fill="currentColor" cx="25" cy="48"></circle>
        <circle r="1.5" fill="currentColor" cx="25" cy="71"></circle>
        <circle r="1.5" fill="currentColor" cx="25" cy="94"></circle>
        <circle r="1.5" fill="currentColor" cx="25" cy="117"></circle>
        <circle r="1.5" fill="currentColor" cx="25" cy="140"></circle>
        <circle r="1.5" fill="currentColor" cx="48" cy="2"></circle>
        <circle r="1.5" fill="currentColor" cx="48" cy="25"></circle>
        <circle r="1.5" fill="currentColor" cx="48" cy="48"></circle>
        <circle r="1.5" fill="currentColor" cx="48" cy="71"></circle>
        <circle r="1.5" fill="currentColor" cx="48" cy="94"></circle>
        <circle r="1.5" fill="currentColor" cx="48" cy="117"></circle>
        <circle r="1.5" fill="currentColor" cx="48" cy="140"></circle>
        <circle r="1.5" fill="currentColor" cx="71" cy="2"></circle>
        <circle r="1.5" fill="currentColor" cx="71" cy="25"></circle>
        <circle r="1.5" fill="currentColor" cx="71" cy="48"></circle>
        <circle r="1.5" fill="currentColor" cx="71" cy="71"></circle>
        <circle r="1.5" fill="currentColor" cx="71" cy="94"></circle>
        <circle r="1.5" fill="currentColor" cx="71" cy="117"></circle>
        <circle r="1.5" fill="currentColor" cx="71" cy="140"></circle>
        <circle r="1.5" fill="currentColor" cx="94" cy="2"></circle>
        <circle r="1.5" fill="currentColor" cx="94" cy="25"></circle>
        <circle r="1.5" fill="currentColor" cx="94" cy="48"></circle>
        <circle r="1.5" fill="currentColor" cx="94" cy="71"></circle>
        <circle r="1.5" fill="currentColor" cx="94" cy="94"></circle>
        <circle r="1.5" fill="currentColor" cx="94" cy="117"></circle>
        <circle r="1.5" fill="currentColor" cx="94" cy="140"></circle>
        <circle r="1.5" fill="currentColor" cx="117" cy="2"></circle>
        <circle r="1.5" fill="currentColor" cx="117" cy="25"></circle>
        <circle r="1.5" fill="currentColor" cx="117" cy="48"></circle>
        <circle r="1.5" fill="currentColor" cx="117" cy="71"></circle>
        <circle r="1.5" fill="currentColor" cx="117" cy="94"></circle>
        <circle r="1.5" fill="currentColor" cx="117" cy="117"></circle>
        <circle r="1.5" fill="currentColor" cx="117" cy="140"></circle>
        <circle r="1.5" fill="currentColor" cx="140" cy="2"></circle>
        <circle r="1.5" fill="currentColor" cx="140" cy="25"></circle>
        <circle r="1.5" fill="currentColor" cx="140" cy="48"></circle>
        <circle r="1.5" fill="currentColor" cx="140" cy="71"></circle>
        <circle r="1.5" fill="currentColor" cx="140" cy="94"></circle>
        <circle r="1.5" fill="currentColor" cx="140" cy="117"></circle>
        <circle r="1.5" fill="currentColor" cx="140" cy="140"></circle>
        <circle r="1.5" fill="currentColor" cx="163" cy="2"></circle>
        <circle r="1.5" fill="currentColor" cx="163" cy="25"></circle>
        <circle r="1.5" fill="currentColor" cx="163" cy="48"></circle>
        <circle r="1.5" fill="currentColor" cx="163" cy="71"></circle>
        <circle r="1.5" fill="currentColor" cx="163" cy="94"></circle>
        <circle r="1.5" fill="currentColor" cx="163" cy="117"></circle>
        <circle r="1.5" fill="currentColor" cx="163" cy="140"></circle>
        <circle r="1.5" fill="currentColor" cx="186" cy="2"></circle>
        <circle r="1.5" fill="currentColor" cx="186" cy="25"></circle>
        <circle r="1.5" fill="currentColor" cx="186" cy="48"></circle>
        <circle r="1.5" fill="currentColor" cx="186" cy="71"></circle>
        <circle r="1.5" fill="currentColor" cx="186" cy="94"></circle>
        <circle r="1.5" fill="currentColor" cx="186" cy="117"></circle>
        <circle r="1.5" fill="currentColor" cx="186" cy="140"></circle>
        <circle r="1.5" fill="currentColor" cx="209" cy="2"></circle>
        <circle r="1.5" fill="currentColor" cx="209" cy="25"></circle>
        <circle r="1.5" fill="currentColor" cx="209" cy="48"></circle>
        <circle r="1.5" fill="currentColor" cx="209" cy="71"></circle>
        <circle r="1.5" fill="currentColor" cx="209" cy="94"></circle>
        <circle r="1.5" fill="currentColor" cx="209" cy="117"></circle>
        <circle r="1.5" fill="currentColor" cx="209" cy="140"></circle>
      </svg>
      <hooper :settings="settings" ref="carousel" @slide="updateCarousel" class="h-auto">
        <slide v-for="(testimonial, index) in testimonials" :key="index" :index="index" class="h-auto">
          <div class="px-1 px-md-0 h-100">
            <a :href="testimonial.link" target="_blank" class="card border h-100">
              <div class="card-body text-center pb-5">
                <div :style="companyLogoStyle(testimonial.company_image)" class="mx-auto"></div>
                <p class="lead mt-4">{{ testimonial.text }}</p>
                <div class="d-flex mt-4 align-items-center">
                  <div class="ml-auto mr-3">
                    <div style="max-width: 50px">
                      <img :src="testimonial.image" class="img-fluid rounded-circle" />
                    </div>
                  </div>
                  <div class="mr-auto text-left" style="font-size: 90%">
                    <div>
                      <strong>{{ testimonial.name }}</strong>
                    </div>
                    <div>{{ testimonial.company }}, {{ testimonial.role }}</div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </slide>
      </hooper>
      <ul class="nav nav-testimonials justify-content-center mt-3 d-none d-md-flex">
        <li v-for="(testimonial, index) in testimonials" :key="index" :index="index" class="nav-item">
          <a :class="{ active: carouselData == index }" @click.prevent="slideTo(index)">
            <i class="inactive-state fal fa-circle"></i>
            <i class="active-state fas fa-circle"></i>
          </a>
        </li>
      </ul>
    </div>
    <div v-if="more_link" class="text-center mt-md-n4">
      <a :href="more_link" target="_blank" class="btn btn-arrow text-primary">
        {{ more_text }}
      </a>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from "@/vendor/hooper.esm";
import "hooper-vue3/dist/hooper.css";

export default {
  props: {
    testimonials: Array,
    more_link: String,
    more_text: String,
  },

  data() {
    return {
      carouselData: 0,
      settings: {
        autoPlay: true,
        playSpeed: 5000,
        infiniteScroll: true,
        wheelControl: false,
        centerMode: true,
        itemsToShow: 1.25,
        breakpoints: {
          577: {
            itemsToShow: 1,
          },
        },
      },
    };
  },

  watch: {
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    },
  },

  methods: {
    updateCarousel(payload) {
      this.carouselData = payload.currentSlide % this.testimonials.length;
    },

    slideTo(index) {
      this.carouselData = index;
    },

    companyLogoStyle(url) {
      return {
        backgroundImage: `url("${url}")`,
        backgroundSize: "contain",
        width: "200px",
        height: "100px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };
    },
  },

  components: {
    Hooper,
    Slide,
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #454545;
  text-decoration: none;
}

.nav-testimonials {
  a {
    cursor: pointer;
    font-size: 12px;
    padding: 12px;
  }

  .active-state {
    display: none;
  }

  .active {
    .inactive-state {
      display: none;
    }

    .active-state {
      display: inline;
    }
  }
}
</style>
