<template>
  <button :class="classes" :type="props.type" @click="props.onClick(props.color)">
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Flipper from "@/modules/flipper";

export interface ColorTileProps {
  onClick?: (color: string) => void;
  selected?: boolean;
  color: string;
}

const props = withDefaults(defineProps<ColorTileProps>(), {
  onClick: undefined,
  type: "button",
  selected: false,
  color: undefined,
});

const isNewLayoutEnabled = Flipper.isEnabled('background_image_search');

const classes = computed(() => ({
  "h-16 w-16": !isNewLayoutEnabled,
  "h-20 w-20": isNewLayoutEnabled,
  "!rounded-lg overflow-hidden select-none shrink-0": true,
  "transition ease-in-out active:scale-[0.98]": true,
  "focus:ring-none focus:outline-none focus-visible:ring focus-visible:ring-offset-2 focus-visible:ring-primary-hover focus-visible:outline-none":
    true,
  "!border-secondary hover:!border-secondary-hover": !props.selected,
  "!border border-newBlue-primary hover:!border-newBlue-primary-hover": props.selected,
}));
</script>
