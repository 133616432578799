<template>
  <div ref="wrapper">
    <div v-if="renderAs == 'banner'">
      <transition name="fade">
        <div v-if="mounted && !consentManaged">
          <div
            id="wrapper"
            class="fixed-bottom d-flex justify-content-md-end mx-2 mx-md-0 mb-md-4 mr-md-4"
            :class="wrapperClasses"
          >
            <div v-if="explicitConsent" class="banner">
              <div class="container p-4">
                <p class="policy mb-4">
                  <span class="font-weight-bold h4">{{ t.header }}.</span>
                  {{ t.eu_consent }}
                  <a
                    v-if="showRejectAll"
                    class="banner-link"
                    href="#"
                    data-toggle="modal"
                    data-target="#consentModal"
                    >{{ t.open_settings }}</a
                  >
                </p>
                <div class="buttons d-flex flex-column flex-md-row justify-content-between justify-content-md-start">
                  <button type="button" class="btn" :class="bannerButtonStyle" @click.prevent="consentAll">
                    {{ t.accept_all }}
                  </button>
                  <button
                    type="button"
                    v-if="showRejectAll"
                    class="btn mr-2"
                    :class="bannerRejectButtonStyle"
                    @click.prevent="rejectAll"
                  >
                    {{ t.reject_all }}
                  </button>
                  <button
                    type="button"
                    v-if="!showRejectAll"
                    class="btn btn-link btn-link-secondary"
                    data-toggle="modal"
                    data-target="#consentModal"
                  >
                    {{ t.open_settings }}
                  </button>
                </div>
              </div>
            </div>
            <div v-else class="banner">
              <div class="container p-4 d-flex flex-column justify-content-between">
                <p class="policy mb-4" v-html="t.consent"></p>
                <div class="buttons d-flex justify-content-center justify-content-md-end">
                  <button type="button" class="btn btn-link btn-link-secondary" @click.prevent="consentAll">
                    {{ t.close }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-else>
      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#consentModal">
        {{ t.manage_cookies }}
      </button>
    </div>
    <div id="consentModal" class="modal" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-dialog-md-centered">
        <div class="modal-content">
          <div class="modal-header border-0 pb-0 px-1">
            <div class="container">
              <h5 class="modal-title">{{ t.modal_header }}</h5>
            </div>
          </div>
          <div class="cookie-consent-info py-2 py-md-3 px-1">
            <div class="container">
              <p class="mb-0" v-html="t.modal_cookie_info"></p>
            </div>
          </div>
          <div class="modal-body px-1">
            <div class="container">
              <div class="row consent-class">
                <div class="col-9 col-md-10 pr-0">
                  <div class="d-flex align-items-center">
                    <span class="consent-class-header d-block">{{ t.essential.header }}</span>
                    <span class="always-on ml-2 text-uppercase font-weight-bold d-block">{{ t.always_on }}</span>
                  </div>
                  <p>{{ t.essential.description }}</p>
                </div>
                <div class="col-3 col-md-2 d-flex justify-content-center align-items-center">
                  <toggle-button
                    :value="true"
                    :labels="switchLabels"
                    :disabled="true"
                    :color="switchColors"
                    :width="55"
                    :height="25"
                  />
                </div>
              </div>
              <div class="row consent-class">
                <div class="col-9 col-md-10 pr-0">
                  <span class="consent-class-header block">{{ t.functional.header }}</span>
                  <p>{{ t.functional.description }}</p>
                </div>
                <div class="col-3 col-md-2 d-flex justify-content-center align-items-center">
                  <toggle-button
                    v-model="selection.functional"
                    :labels="switchLabels"
                    :color="switchColors"
                    :sync="true"
                    :width="55"
                    :height="25"
                  />
                </div>
              </div>
              <div class="row consent-class">
                <div class="col-9 col-md-10 pr-0">
                  <span class="consent-class-header block">{{ t.performance.header }}</span>
                  <p>{{ t.performance.description }}</p>
                </div>
                <div class="col-3 col-md-2 d-flex justify-content-center align-items-center">
                  <toggle-button
                    v-model="selection.performance"
                    :labels="switchLabels"
                    :color="switchColors"
                    :sync="true"
                    :width="55"
                    :height="25"
                  />
                </div>
              </div>
              <div class="row consent-class">
                <div class="col-9 col-md-10 pr-0">
                  <span class="consent-class-header block">{{ t.targeting.header }}</span>
                  <p>{{ t.targeting.description }}</p>
                </div>
                <div class="col-3 col-md-2 d-flex justify-content-center align-items-center">
                  <toggle-button
                    v-model="selection.targeting"
                    :labels="switchLabels"
                    :sync="true"
                    :color="switchColors"
                    :width="55"
                    :height="25"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center w-full flex-wrap flex-md-nowrap">
            <button
              type="button"
              class="btn btn-outline-secondary btn-block mt-0 mr-0 mr-md-1 mb-2 mb-md-0 ml-0"
              data-dismiss="modal"
              @click="consentSelected"
            >
              {{ t.accept_selected }}
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary btn-block mt-0 mr-0 mr-md-1 mb-2 mb-md-0 ml-0"
              data-dismiss="modal"
              @click="rejectAll"
            >
              {{ t.reject_all }}
            </button>
            <button
              type="button"
              class="btn btn-banner-success btn-block mt-0 ml-0 mb-0"
              data-dismiss="modal"
              @click="consentAll"
            >
              {{ t.accept_all }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Buffer } from "buffer";
import Cookie from "../src/cookie.js";
import Hotjar from "../src/hotjar.js";
import EventBus from "../src/event_bus";
import { ToggleButton } from "vue-js-toggle-button";
import {
  trackPageView,
  disableAnonymousTracking,
  enableAnonymousTracking,
  clearUserData,
} from "@snowplow/browser-tracker";
import emitter from "@/modules/event_bus";

export default {
  props: {
    renderAs: String,
    hotjarEnabled: Boolean,
    explicitConsent: Boolean,
    country: String,
    ip: String,
    consentShareSites: Array,
    cookiePolicyVersion: String,
    showRejectAll: Boolean,
    t: Object,
  },

  data() {
    return {
      mounted: false,
      timestamp: Date.now(),
      acceptedCookiePolicyVersion: null,
      snowplowConfig: null,
      numberOfNewEditorImages: 0,
      isOnEditorPage: false,
      consent: {
        managed: false,
        functional: false,
        performance: false,
        targeting: false,
      },
      selection: {
        managed: true,
        functional: false,
        performance: false,
        targeting: false,
      },
      switchLabels: {
        checked: "ON",
        unchecked: "OFF",
      },
      switchColors: {
        checked: "#008009",
        disabled: "#008009",
        unchecked: "#454545",
      },
      cookieName: "consent",
      cookieMaxAge: 365,
    };
  },

  computed: {
    bannerButtonStyle() {
      return {
        "btn-banner-success": this.showRejectAll,
      };
    },
    bannerRejectButtonStyle() {
      return {
        "btn-link-secondary": this.showRejectAll,
      };
    },

    modalRejectButtonStyle() {
      return {
        "btn-outline-secondary": !this.showRejectAll,
        "btn-banner-success": this.showRejectAll,
      };
    },

    wrapperClasses() {
      const increasedBottomMargin = this.isOnEditorPage && this.numberOfNewEditorImages > 0;

      return {
        "banner-margin": !increasedBottomMargin,
        "banner-margin-editor": increasedBottomMargin,
      };
    },

    consentManaged() {
      return this.consent.managed === true;
    },

    functionalAccepted() {
      return this.consent.functional === true;
    },

    performanceAccepted() {
      return this.consent.performance === true;
    },

    targetingAccepted() {
      return this.consent.targeting === true;
    },

    options() {
      return Object.keys(this.consent);
    },

    cookieData() {
      const data = {
        ip: this.ip,
        origin: "remove.bg",
        country: this.country,
        timestamp: this.timestamp,
        options: this.options,
        consent: this.consent,
        cookiePolicyVersion: this.cookiePolicyVersion,
      };

      return Buffer.from(JSON.stringify(data)).toString("base64");
    },
  },

  mounted() {
    this.snowplowConfig = JSON.parse(document.querySelector("meta[property='snowplow']").getAttribute("content"));

    this.initCookie();
    this.loadConsentFromCookie();
    this.checkAcceptedPolicyVersion();
    this.updateSelection();
    this.updateGTagPreferences();
    this.updateSnowplowPreferences();
    this.loadHotjar();
    this.updateLoginUrl();
    this.sendSnowplowPageView();

    EventBus.$on("consent-managed", () => {
      this.consent.managed = true;
    });

    emitter.on("imagesChanged", (newCount) => {
      this.isOnEditorPage = true;
      this.numberOfNewEditorImages = newCount;
    });

    this.mounted = true;
  },

  methods: {
    checkAcceptedPolicyVersion() {
      if (this.cookiePolicyVersion === this.acceptedCookiePolicyVersion) return;

      if (!this.explicitConsent) {
        this.autoConsentAll();
      } else {
        this.resetAll();
      }

      this.saveConsentToCookie();
    },

    initCookie() {
      if (Cookie.get(this.cookieName) != undefined) return;

      if (!this.explicitConsent) {
        this.autoConsentAll();
      } else {
        this.consent.managed = false;
      }

      this.saveConsentToCookie();
    },

    updateSelection() {
      if (!this.consentManaged) return;

      this.selection = this.consent;
    },

    consentSelected() {
      this.consent = this.selection;

      this.saveChoices();
    },

    resetAll() {
      this.consent.managed = false;
      this.consent.functional = false;
      this.consent.performance = false;
      this.consent.targeting = false;
    },

    consentAll() {
      this.consent.managed = true;
      this.consent.functional = true;
      this.consent.performance = true;
      this.consent.targeting = true;
      this.selection = this.consent;

      this.saveChoices();
    },

    autoConsentAll() {
      this.consent.managed = false;
      this.consent.functional = true;
      this.consent.performance = true;
      this.consent.targeting = true;
    },

    rejectAll() {
      this.consent.managed = true;
      this.consent.functional = false;
      this.consent.performance = false;
      this.consent.targeting = false;
      this.selection = this.consent;

      this.saveChoices();
    },

    saveChoices() {
      this.saveConsentToCookie();
      this.updateGTagPreferences();
      this.updateSnowplowPreferences();
      this.loadHotjar();
      this.shareConsent();

      // Only send pageview if explicit consent is needed
      // otherwise the page view will not differ from the
      // inital page view and be counted twice
      if (this.explicitConsent && this.consent.performance) {
        this.sendSnowplowPageView();
      }

      EventBus.$emit("consent-managed");
    },

    sendSnowplowPageView() {
      const eventsIdentifier = this.snowplowConfig.events_identifier;
      trackPageView({}, [eventsIdentifier]);
    },

    shareConsent() {
      this.consentShareSites.forEach((site) => {
        let iframe = document.createElement("iframe");
        const url = `${site}/cookies/update_consent?value=${this.cookieData}`;

        iframe.style.display = "none";
        iframe.referrerPolicy = "unsafe-url";
        iframe.src = url;

        iframe.onload = () => {
          iframe.parentNode.removeChild(iframe);
        };

        this.$refs.wrapper.appendChild(iframe);
      });

      this.updateLoginUrl();
    },

    updateLoginUrl() {
      const signInButton = document.getElementById("sso-sign-in-btn");
      if (!signInButton) return;

      let url = new URL(signInButton.href);
      url.searchParams.set("cookie_consent", this.cookieData);
      signInButton.href = url;
    },

    updateGTagPreferences() {
      if (!window.gtag) return;
      const performanceState = this.performanceAccepted ? "granted" : "denied";
      const targetingState = this.targetingAccepted ? "granted" : "denied";

      window.gtag("consent", "update", {
        analytics_storage: performanceState,
        ad_storage: targetingState,
      });
    },

    updateSnowplowPreferences() {
      if (this.performanceAccepted) {
        disableAnonymousTracking();
      } else {
        enableAnonymousTracking();
        clearUserData();
      }
    },

    loadHotjar() {
      if (!this.hotjarEnabled) return;
      if (!this.performanceAccepted) return;
      if (window.hj) return;

      Hotjar.load();
    },

    loadConsentFromCookie() {
      const cookie = Cookie.get(this.cookieName);

      try {
        const decodedCookie = JSON.parse(Buffer.from(cookie, "base64").toString("ascii"));
        this.consent = decodedCookie.consent;
        this.acceptedCookiePolicyVersion = decodedCookie.cookiePolicyVersion;
      } catch (e) {
        this.consent.managed = false;
        this.acceptedCookiePolicyVersion = "";
        this.saveConsentToCookie();
      }
    },

    saveConsentToCookie() {
      Cookie.set(this.cookieName, "essential", this.cookieData, {
        expires: this.cookieMaxAge,
        secure: true,
        sameSite: "None",
      });
    },
  },

  components: { ToggleButton },
};
</script>

<!-- Links in the cookie banner are coming in from the outside
and therefore cant be scoped, se we use a second unscoped
style block -->
<style lang="scss">
.policy {
  a {
    text-decoration: none !important;
    color: #008009 !important;

    &:hover {
      text-decoration: none !important;
    }
  }
}
</style>

<style lang="scss" scoped>
#wrapper {
  @media (max-width: 768px) {
    transition: margin-bottom 1s;
  }
  @media (min-width: 767px) {
    left: auto;
  }
  max-width: 560px;
}

.banner {
  background: #c1e7c2;
  color: #454545;
  border-radius: 1rem;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  width: 100%;

  @media (min-width: 769px) {
    max-width: 560px;
  }
}

.banner-margin {
  margin-bottom: 0.5rem !important;

  @media (min-width: 769px) {
    margin-bottom: 1.5rem !important;
  }
}

.banner-margin-editor {
  margin-bottom: 5.5rem !important;

  @media (min-width: 769px) {
    margin-bottom: 1.5rem !important;
  }
}

.modal-footer {
  background: rgba(255, 255, 255, 0.9);
  color: #454545;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.24);
}

.btn-banner-secondary {
  color: #454545;
  background-color: #dddfe1;
  border-color: #dddfe1;
}

.btn-banner-success {
  color: white;
  background-color: #008009;
  border-color: #008009;
}

.modal {
  color: #454545;

  .modal-header {
    background-color: #f8f9fa;
  }

  .modal-dialog {
    max-width: 650px;
  }
}

.modal-dialog-md-centered {
  @media (min-width: 769px) {
    display: flex;
    align-items: center;
    min-height: calc(100% - 3.5rem);
  }

  &:before {
    display: block;
    height: min-content;
    content: "";
  }
}

.always-on {
  font-size: 0.8rem;
  color: #bac0c4;
}

.banner-link {
  color: #454545;
  text-decoration: underline;
}

.consent-class {
  .consent-class-header {
    font-weight: bold;
    font-size: 1.1rem;
  }

  p {
    font-size: 0.9rem;
  }
}

@media (max-width: 767px) {
  .modal-body {
    max-height: 40vh;
    overflow-y: auto;
  }
}

@media (max-width: 320px) {
  .buttons button {
    display: block;
    width: 100%;
  }
}

.cookie-consent-info {
  background-color: #f9fafa;
}

.btn-link-secondary {
  color: #008009;
  font-weight: bold;
}

.icon-close {
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
