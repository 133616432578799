<template>
  <div v-cloak>
    <input type="hidden" :value="fields.leadSource" name="lead_source" id="lead_source" />
    <input type="hidden" :value="fields.slug" name="slug" id="slug" />
    <input type="hidden" name="hcaptcha_response" id="hcaptcha_response" />
    <div class="form-group">
      <p-basic-input
        required
        name="company"
        :modelValue="fields.company.value"
        :label="fields.company.label"
        :additional-attributes="{ ...VALIDATION.company, readonly: isLoading }"
      ></p-basic-input>
    </div>

    <div class="form-group">
      <p-basic-input
        name="title"
        :model-value="fields.title.value"
        :label="fields.title.label"
        :additional-attributes="{ ...VALIDATION.title, readonly: isLoading }"
      ></p-basic-input>
    </div>

    <div class="form-group">
      <p-basic-input
        name="first_name"
        :model-value="fields.firstName.value"
        :label="fields.firstName.label"
        :additional-attributes="{ ...VALIDATION.firstName, readonly: isLoading }"
      ></p-basic-input>
    </div>

    <div class="form-group">
      <p-basic-input
        required
        name="last_name"
        :model-value="fields.lastName.value"
        :label="fields.lastName.label"
        :additional-attributes="{ ...VALIDATION.lastName, readonly: isLoading }"
      ></p-basic-input>
    </div>

    <div class="form-group">
      <p-basic-input
        required
        name="email"
        type="email"
        :model-value="fields.email.value"
        :label="fields.email.label"
        :additional-attributes="{ ...VALIDATION.email, readonly: isLoading }"
      ></p-basic-input>
    </div>

    <div class="form-group">
      <p-basic-input
        name="phone"
        type="tel"
        :model-value="fields.phone.value"
        :label="fields.phone.label"
        :additional-attributes="{ ...VALIDATION.phone, readonly: isLoading }"
      ></p-basic-input>
    </div>

    <div class="form-group">
      <p-select-input
        :error="errors[fields.country.name]"
        :disabled="isLoading"
        :name="fields.country.name"
        :label="fields.country.label"
        :controlled="false"
        :options="fields.country.options"
        :default-value="fields.country.defaultValue"
        :include-blank="fields.country.includeBlank"
      ></p-select-input>
    </div>
    <div class="form-group">
      <p-select-input
        :disabled="isLoading"
        :name="fields.industry.name"
        :label="fields.industry.label"
        :controlled="false"
        :options="fields.industry.options"
        :default-value="fields.industry.defaultValue"
        :include-blank="fields.industry.includeBlank"
        :error="errors['industry']"
      ></p-select-input>
    </div>
    <div class="form-group">
      <p-select-input
        :disabled="isLoading"
        :name="fields.imagesPerYear.name"
        :label="fields.imagesPerYear.label"
        :controlled="false"
        :options="fields.imagesPerYear.options"
        :default-value="fields.imagesPerYear.defaultValue"
        :include-blank="fields.imagesPerYear.includeBlank"
        :error="errors[fields.imagesPerYear.name]"
      ></p-select-input>
    </div>
    <div class="form-group sources">
      <p-select-input
        :disabled="isLoading"
        :name="fields.source.name"
        :label="fields.source.label"
        :controlled="false"
        :options="fields.source.options"
        :default-value="fields.source.defaultValue"
        :include-blank="fields.source.includeBlank"
        :error="errors[fields.source.name]"
      ></p-select-input>
    </div>

    <div class="form-group">
      <p-textarea
        :label="fields.message.label"
        name="message"
        rows="5"
        required
        :model-value="fields.message.value"
        :additional-attributes="{ ...VALIDATION.message, readonly: isLoading }"
      ></p-textarea>
    </div>

    <input
      type="hidden"
      :value="submissonUrl"
      name="submission_url"
      id="submission_url"
      :maxlength="VALIDATION.submissonUrl.maxlength"
    />

    <div class="form-group flex flex-column">
      <p-button
        type="submit"
        fullWidth
        size="lg"
        class="btn-submit-get-in-touch !mt-8 hidden lg:flex"
        :loading="isLoading"
        >Send Request</p-button
      >
      <p-button type="submit" fullWidth size="md" class="btn-submit-get-in-touch !mt-8 lg:hidden" :loading="isLoading"
        >Send Request</p-button
      >
      <p style="font-size: 75%; opacity: 0.6" class="mt-2 text-center" v-html="fields.hcaptchaMessage"></p>
    </div>
  </div>
  <div v-if="formError">
    <p class="error" style="color: red">{{ formError }}</p>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import { hcaptcha_invisible } from "@/src/hcaptcha";

interface InputField {
  label: string;
  value: string | undefined;
}

interface SelectInputField {
  name: string;
  label: string;
  options: Array<InputField>;
  defaultValue: InputField;
  includeBlank: string;
}

interface GetInTouchFormFields {
  formId: string;
  hcaptchaSitekey: string;
  formAction?: string;
  leadSource: string;
  slug: string;
  company: InputField;
  title: InputField;
  firstName: InputField;
  lastName: InputField;
  email: InputField;
  phone: InputField;
  country: SelectInputField;
  industry: SelectInputField;
  imagesPerYear: SelectInputField;
  source: SelectInputField;
  message: InputField;
  hcaptchaMessage: string;
}
export interface GetInTouchFormProps {
  fields: GetInTouchFormFields;
}

const VALIDATION = {
  company: { maxLength: 255 },
  title: { maxLength: 128 },
  firstName: { maxLength: 40 },
  lastName: { maxLength: 80 },
  email: { maxLength: 80 },
  phone: { maxLength: 40 },
  message: { maxlength: 255 },
  submissonUrl: { maxlength: 255 },
};

const props = defineProps<GetInTouchFormProps>();
const fields = props.fields;
const isLoading = ref(false);

const errors = ref({});
const formError = ref("");
const requiredFields = ["last_name", "company", "email", "message"];
const requiredSelectFields = [fields.country.name, fields.industry.name, fields.imagesPerYear.name, fields.source.name];
const submissonUrl = ref("");

const isEmpty = (value) => value === "" || value === undefined || value === null || value.trim() === "";
const errorMessage = (field: string) => {
  if (requiredSelectFields.includes(field)) return "Please select an option";

  return "This field is required";
};

let hcaptcha_processed = false;
const handleHcaptcha = async (form: HTMLFormElement) => {
  if (!hcaptcha_processed) {
    const captcha = await hcaptcha_invisible({ sitekey: fields.hcaptchaSitekey }).catch(() => {
      formError.value = "Captcha failed: Please try again or contact team@remove.bg if the problem persists.";

      return false;
    });

    form.elements.namedItem("hcaptcha_response").value = captcha;
    hcaptcha_processed = true;
  }
};

let validateIntervalId = null;
const validateForm = (form: HTMLFormElement): boolean => {
  let formData = new FormData(form);
  for (const field of [...requiredFields, ...requiredSelectFields]) {
    const value = formData.get(field);
    errors.value = {
      ...errors.value,
      [field]: isEmpty(value) ? errorMessage(field) : undefined,
    };
  }

  //form valid?
  let valid = true;
  for (let error of Object.values(errors.value)) {
    if (error) {
      valid = false;
      break;
    }
  }

  // If the form fields(ie. select fields) are not valid , make sure we turn off the continuous check that we put in place.
  if (valid && validateIntervalId) {
    clearInterval(validateIntervalId);
  }

  return valid;
};

async function submitHandler(e: SubmitEvent) {
  e.preventDefault();
  e.stopPropagation();

  let form = document.getElementById(fields.formId) as HTMLFormElement;
  const isFormValid = validateForm(form);

  // For now, we add continuous check if the form is valid until it's valid
  // Reason to do this:
  //  There has been some issue with v-model and v-bind in "SelectInput/p-select-input" component.
  //    It does not emit the "update:modelValue" event when the values are changed. This is preventing us from re-running a validation
  //    and giving the user with quick visual feedback. Hence, this dirty hack.
  if (!isFormValid && validateIntervalId === null) {
    validateIntervalId = setInterval(() => validateForm(form), 300);
  }

  if (isFormValid) {
    isLoading.value = true;
    await handleHcaptcha(form);

    form.submit();
    isLoading.value = false;

    return true;
  }

  return false;
}

onMounted(() => {
  document.getElementById(fields.formId).addEventListener("submit", submitHandler);
  submissonUrl.value = window.location.href;
});

onBeforeUnmount(() => {
  if (validateIntervalId) {
    clearInterval(validateIntervalId);
  }
});
</script>

<style>
/* Make sure the next option is partly visible to let the user know they can scroll */
.sources [role="listbox"] {
  max-height: 16.5rem !important;
}
</style>
