<template>
  <div>
    <transition name="fade">
      <div v-if="show" class="d-none d-lg-flex fixed-bottom mb-5 justify-content-center" ondragstart="return false;">
        <div class="overlay rounded-lg py-3 px-4 position-relative">
          <div class="d-flex align-items-center">
            <img :src="this.icon" />
            <div class="mx-4">
              <div>
                <strong>{{ this.t.header }}</strong>
              </div>
              <div>{{ this.t.text }}</div>
              <div class="mt-1 d-flex align-items-center">
                <a :href="this.link" target="_blank">{{ this.t.link }}</a>
                <i class="link pl-2 fas fa-chevron-right"></i>
              </div>
            </div>
            <i class="close fas fa-times position-absolute" @click="close"></i>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import EventBus from "../src/event_bus";
import Cookie from "../src/cookie";

export default {
  props: {
    t: Object,
    link: String,
    icon: String,
  },

  data() {
    return {
      sessionUploads: 0,
      multipleImages: false,
      closedByUser: false,
      cookieMaxAge: 180, // days
      cookieName: "improved_flow_promo",
    };
  },

  mounted() {
    EventBus.$on("image-added", () => {
      this.sessionUploads += 1;
    });

    EventBus.$on("multiple-images-added", () => {
      this.multipleImages = true;
    });
  },

  computed: {
    show() {
      if (this.closedByUser) return false;
      if (this.cookieExists) return false;

      if (this.multipleImages) return true;
      if (this.sessionUploads >= 4) return true;

      return false;
    },

    cookieExists() {
      return Cookie.get(this.cookieName) == "closed";
    },
  },

  methods: {
    close() {
      this.closedByUser = true;
      this.setCookie();
    },

    setCookie() {
      Cookie.set(this.cookieName, "functional", "closed", { expires: this.cookieMaxAge });
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  font-size: 0.9rem;
  background-color: #f7f9fa;
  max-width: 650px;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.25) !important;
}

.close {
  font-size: 1.1rem;
  cursor: pointer;
  top: 10px;
  right: 10px;
}

i.link {
  color: #0f70e6;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
