<template></template>

<script setup lang="ts">
import { onMounted } from "vue";

export interface AuthProps {
  origin: string;
  path: string;
  login_url: string;
}

const props = defineProps<AuthProps>();

onMounted(() => {
  const { origin, path, login_url } = props;
  window.Auth.attempt(origin, path, login_url);
});
</script>
