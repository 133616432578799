<template>
  <div>
    <div class="d-none d-lg-block">
      <!-- desktop -->
      <div class="row ml-3 mr-3 mt-3 mb-0 align-items-center">
        <div class="col-6 no-x-padding">
          <strong class="mr-1" style="font-size: 14px">{{ node.title_i18n }}</strong>
          <span class="hover-tooltip" style="opacity: 0.8" :data-tippy-content="'<div style=\'text-align: left;\'><strong>' + node.noteTitle_i18n + '</strong><br/>' + node.noteContents_i18n" tabindex="0">
            <i class="fas fa-info-circle" style="opacity: 0.5; font-size: 80%; color: #454545"></i>
          </span>
          <span class="ml-1 text-muted small">({{ t.optional }})</span>
        </div>
        <div class="col-6 no-gutters no-x-padding text-right" style="padding-right: 5px" v-if="alignmentEnabled">
          <strong style="font-size: 14px">{{ t.position }}</strong>
        </div>
      </div>
      <div class="row mx-3 valign-wrapper node-padding">
        <div class="col-2 no-x-padding" v-if="logoUrl" v-on:click="uploadFile" style="cursor: pointer">
          <img :src="logoUrl" style="max-width: 100%; max-height: 75px" v-if="logoUrl" />
        </div>
        <div :class="{ 'col-7': alignmentEnabled, 'col-10': !alignmentEnabled }">
          <button class="btn btn-md" :class="logoUrlChanged ? 'btn-outline-secondary' : 'btn-primary'" v-on:click="uploadFile('logo', 'logoUrl')">{{ logoUrlChanged ? t.change_logo : t.upload_logo }}</button>
        </div>
        <div class="col-3 no-gutters no-x-padding" v-if="alignmentEnabled">
          <div class="row no-gutters justify-content-end">
            <img @click="changeLogoAlignment(0)" style="width: 25px; height: 25px; transform: rotate(0deg); cursor: pointer; padding-left: 5px; padding-top: 5px" :src="'https://static.remove.bg/templates/alignment' + (logoAlignment == 0 ? '-active' : '') + '.png'" />
            <img @click="changeLogoAlignment(1)" style="width: 25px; height: 25px; transform: rotate(90deg); cursor: pointer; padding-left: 5px; padding-top: 5px" :src="'https://static.remove.bg/templates/alignment' + (logoAlignment == 1 ? '-active' : '') + '.png'" />
          </div>
          <div class="row no-gutters justify-content-end">
            <img @click="changeLogoAlignment(3)" style="width: 25px; height: 25px; transform: rotate(270deg); cursor: pointer; padding-left: 5px; padding-top: 5px" :src="'https://static.remove.bg/templates/alignment' + (logoAlignment == 3 ? '-active' : '') + '.png'" />
            <img @click="changeLogoAlignment(2)" style="width: 25px; height: 25px; transform: rotate(180deg); cursor: pointer; padding-left: 5px; padding-top: 5px" :src="'https://static.remove.bg/templates/alignment' + (logoAlignment == 2 ? '-active' : '') + '.png'" />
          </div>
        </div>
      </div>
    </div>
    <!------------>
    <!-- mobile -->
    <!------------>
    <div class="d-lg-none">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="pull-left ml-3">
            <strong style="font-size: 14px">{{ node.title_i18n }}</strong>
            <span class="hover-tooltip ml-1" style="opacity: 0.8" :data-tippy-content="'<div style=\'text-align: left;\'><strong>' + node.noteTitle_i18n + '</strong>' + node.noteContents_i18n" tabindex="0">
              <i class="fas fa-info-circle" style="opacity: 0.5; font-size: 80%; color: #768089"></i>
            </span>
            <br />
            <span class="text-muted small">({{ t.optional }})</span>
          </div>
          <div class="col-2 text-right" style="padding-right: 20px" v-if="alignmentEnabled">
            <span class="text-muted small">{{ t.position }}</span>
          </div>
          <div class="col-2 no-x-padding" v-if="alignmentEnabled">
            <div class="row no-gutters justify-content-end">
              <img @click="changeLogoAlignment(0)" style="width: 25px; height: 25px; transform: rotate(0deg); cursor: pointer; padding-left: 5px; padding-top: 5px" :src="'https://static.remove.bg/templates/alignment' + (logoAlignment == 0 ? '-active' : '') + '.png'" />
              <img @click="changeLogoAlignment(1)" style="width: 25px; height: 25px; transform: rotate(90deg); cursor: pointer; padding-left: 5px; padding-top: 5px" :src="'https://static.remove.bg/templates/alignment' + (logoAlignment == 1 ? '-active' : '') + '.png'" />
            </div>
            <div class="row no-gutters justify-content-end">
              <img @click="changeLogoAlignment(3)" style="width: 25px; height: 25px; transform: rotate(270deg); cursor: pointer; padding-left: 5px; padding-top: 5px" :src="'https://static.remove.bg/templates/alignment' + (logoAlignment == 3 ? '-active' : '') + '.png'" />
              <img @click="changeLogoAlignment(2)" style="width: 25px; height: 25px; transform: rotate(180deg); cursor: pointer; padding-left: 5px; padding-top: 5px" :src="'https://static.remove.bg/templates/alignment' + (logoAlignment == 2 ? '-active' : '') + '.png'" />
            </div>
          </div>
          <div class="col text-right">
            <button class="btn btn-md" :class="logoUrlChanged ? 'btn-outline-secondary' : 'btn-primary'" v-on:click="uploadFile('logo', 'logoUrl')">{{ logoUrlChanged ? t.change_image : t.upload_image }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-1 mx-3 text-center" v-if="progress">
      <div class="progress">
        <div class="progress-bar with-pulse" role="progressbar" v-bind:aria-valuenow="progress * 100" aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: `${progress * 100}%` }"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { pickFile } from "../src/pick_file";
import { ImageResizer } from "../src/remove-bg-tools";

export default {
  props: {
    node: Object,
    t: Object,
  },
  data: () => ({
    uploadStatus: null,
    logoUrl: null,
    logoAlignment: 0,
    progress: null,
  }),
  computed: {
    logoUrlChanged() {
      if (this.logoUrl == null) return false;
      return this.node.url != this.node.initialUrl;
    },
    alignmentEnabled() {
      return this.node.graph.getNodeByName("DESIGN_SELECTOR_LOGO");
    },
  },
  mounted() {
    this.logoUrl = this.node.placeholderUrl;
    var node = this.node.graph.getNodeByName("DESIGN_SELECTOR_LOGO");
    if (node) {
      this.logoAlignment = node.currentDesignId;
    }
  },
  methods: {
    changeProgress(value) {
      this.progress = value;
    },
    uploadFile(thumbnailId) {
      let that = this;
      pickFile(".jpg,.jpeg,.png,.webp,image/jpeg,image/png").then((file) => {
        this.$emit("update-logoFile", file); // pass back up
        this.changeProgress(0.15);
        that.activeBackground = 1000;
        var reader = new FileReader();
        reader.addEventListener("progress", function (e) {
          if (e.lengthComputable && e.total > 0) {
            that.changeProgress((e.loaded / e.total) * 0.25 + 0.4);
          }
        });
        reader.addEventListener(
          "load",
          function () {
            // convert image file to base64 string
            that.changeProgress(null);
            that.logoUrl = reader.result;
            that.node.urlChange(null, reader.result);
          },
          false
        );

        // Read in the image file as a data URL.
        reader.readAsDataURL(file);

        this.updateThumbnail(file, thumbnailId);
      });
    },
    updateThumbnail(file, whichThumbnail) {
      let that = this;
      var imageReducer = new ImageResizer(file);
      imageReducer.reduce().then((returnObj) => {
        var fr = new FileReader();
        fr.onload = function () {
          that[whichThumbnail] = fr.result;
        };
        fr.readAsDataURL(returnObj.blob);
      });
    },
    changeLogoAlignment(alignment) {
      this.logoAlignment = alignment;
      this.node.graph.getNodeByName("DESIGN_SELECTOR_LOGO").useDesign(alignment);
    },
  },
};
</script>
<style scoped>
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.btn-outline-secondary {
  color: #0f70e6;
  background-color: #fff;
  border-color: #0f70e6;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #0f70e6;
  border-color: #0f70e6;
}
</style>
