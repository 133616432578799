<template>
  <div class="container" style="box-shadow: 10px 10px 50px #ddd; line-height: 1">
    <div class="row">
      <div class="col-lg-8 py-3 desktop-border">
        <div
          class="template-frame d-flex justify-content-center align-items-center"
          ref="outputImage"
          v-on:contextmenu="$event.preventDefault()"
          data-hj-suppress
        >
          <div class="sk-circle" v-if="!base64 && !errorMessage">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
          <img
            v-on:contextmenu="$event.preventDefault()"
            :src="base64"
            v-if="base64"
            class="img-fluid"
            style="max-height: 800px"
          />
        </div>
      </div>
      <div
        ref="templateInputContainer"
        class="col-lg-4 no-x-padding desktop-border-top desktop-border-right desktop-border-bottom d-flex flex-column"
      >
        <div class="flex-grow-1">
          <div v-if="signed_in && template_configs.length > 0">
            <div class="container">
              <div class="mt-3 mb-2">
                <strong class="mr-1" style="font-size: 14px">{{ t.design_preset }}</strong>
              </div>
              <div class="row">
                <div class="col-10">
                  <select @change="selectTemplate($event.target.value)" class="form-control form-control-sm">
                    <option :value="template_url">{{ t.default }}</option>
                    <option
                      :key="template_config.id"
                      v-for="template_config in template_configs"
                      :selected="template_config.selected"
                      :value="template_config.url"
                    >
                      {{ template_config.name }}
                    </option>
                  </select>
                </div>
                <div class="col-2 pl-0">
                  <a
                    href="#"
                    class="d-inline-block p-1"
                    @click.prevent="deleteTemplate"
                    style="color: inherit; text-decoration: none"
                    v-if="delete_template_config_url"
                    ><i class="fas fa-times"></i
                  ></a>
                </div>
              </div>
            </div>
            <hr />
          </div>

          <component
            v-bind="comp.props"
            v-for="comp in compNames"
            ref="comps"
            :key="comp.order"
            :is="comp.component"
            :params="params"
            :foregroundUrl="foregroundUrl"
            @update-logoFile="updateLogoFile"
            @update-foregroundUrl="updateForegroundUrl"
            @update-foregroundFilename="updateForegroundFilename"
            @update-uploadResult="updateUploadResult"
            @update-uploadStatus="updateUploadStatus"
            @update-fullAvailable="updateFullAvailable"
            @update-errorMessage="updateErrorMessage"
          ></component>

          <div v-if="showDownloadButtons">
            <div>
              <div class="col text-center my-2">
                <button class="btn btn-primary my-1" v-on:click="downloadImage(false)">Download</button>
                <button
                  class="btn btn-outline-primary my-1"
                  v-if="!isMobileSafari && fullAvailable"
                  v-on:click="downloadImage(true)"
                >
                  Download High-Res
                </button>
              </div>
            </div>
            <div class="">
              <a
                href="#"
                class="col text-center m-3 small"
                v-on:click.prevent="shareImage"
                v-if="t.download_note_html && shareSupported"
                style="line-height: 1.3; color: #768089"
              >
                <i class="fas fa-share-alt mr-1"></i>
                <span style="text-decoration: underline" v-html="t.download_note_html"></span>
              </a>
            </div>
          </div>
          <div class="text-center mt-5">
            <span v-html="t.need_more_designs_html" class="" style="font-size: 14px; line-height: 1.5rem"></span>
          </div>
        </div>
        <hr class="w-100" />
        <div class="text-center small mt-1 mb-3">
          <a href="#" @click.prevent="saveTemplate" class="text-muted"
            ><i class="fas fa-plus-circle"></i> {{ t.save_as_design_preset }}</a
          >
        </div>
      </div>
    </div>
    <!-- preload thumbnail image so it has been loaded already when the overlay appears -->
    <img :src="thumbnail_image" style="position: fixed; left: -9999px; top: -9999px; opacity: 0" />
    <share-overlay
      :base64="base64"
      :t="t"
      :filename="foregroundFilename"
      :thumbnail_image="thumbnail_image"
      v-if="sharing"
      v-on:close="sharing = false"
    />
    <save-template-overlay
      :windows_mac_linux_url="windows_mac_linux_url"
      :name="name"
      :signup_url="signup_url"
      :login_url="login_url"
      :signed_in="signed_in"
      :changedInputs="designTemplate.getInputs()"
      :base64="base64"
      :t="t"
      :thumbnail_image="thumbnail_image"
      v-if="saving"
      v-on:close="saving = false"
    />
  </div>
</template>

<script>
import "@/src/i18n"; // This import was moved from old upload component - it enables proper rendering of design templates
import $ from "jquery";
import Rails from "@rails/ujs";
import nimp_foreground_image from "./nimp_foreground_image.vue";
import nimp_background_selector from "./nimp_background_selector.vue";
import nimp_logo_image from "./nimp_logo_image.vue";
import nimp_text from "./nimp_text.vue";
import nimp_design_selector from "./nimp_design_selector.vue";
import ShareOverlay from "./share_overlay.vue";
import SaveTemplateOverlay from "./save_template_overlay.vue";

import { createDownload } from "../src/download_utils.js";
import tippy from "tippy.js";

export default {
  props: {
    graph: Object,
    inputs: Object,
    t: Object,
    params: Object,
    value: String,
    thumbnail_image: String,
    signed_in: Boolean,
    login_url: String,
    signup_url: String,
    template_url: String,
    windows_mac_linux_url: String,
    template_configs: Array,
    delete_template_config_url: String,
    name: String,
  },
  data: () => ({
    foregroundUrl: null,
    foregroundFilename: null,
    logoFile: null,
    uploadedFile: null,
    base64: null,
    initialSuccessCallback: true,
    requestedAction: false,
    compNames: [],
    errorMessage: null,
    uploadResult: null,
    uploadStatus: null,
    componentNamedNodes: null,
    showDownloadButtons: false,
    resizeTimer: null,
    fullAvailable: false,
    shareOverlayShown: false,
    sharing: false,
    saving: false,
  }),
  mounted() {
    let that = this;

    window.template = this;

    var inputs = this.inputs;
    this.designTemplate = new DesignTemplate.default(
      this.graph,
      this.successCallback,
      function (errorMessage) {
        this.errorMessage = errorMessage;
      },
      inputs
    );

    this.updatePreviewSize();
    window.addEventListener("resize", () => {
      clearTimeout(this.resizeTimer); // poor man's debounce
      this.resizeTimer = setTimeout(this.updatePreviewSize(), 500);
    });

    // create UI components based on graph nodes
    this.componentNamedNodes = this.designTemplate.getComponentNamedNodes();
    this.componentNamedNodes = this.componentNamedNodes.sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1));
    this.componentNamedNodes = this.componentNamedNodes.filter(
      (namedNode) =>
        namedNode.componentName.includes("FOREGROUND_IMAGE") ||
        namedNode.componentName.includes("BACKGROUND_SELECTOR") ||
        namedNode.componentName.includes("LOGO_IMAGE") ||
        namedNode.componentName.includes("TEXT") ||
        namedNode.componentName.includes("DESIGN_SELECTOR")
    );

    this.componentNamedNodes.forEach((namedNode, key, arr) => {
      var componentName = "nimp_" + namedNode.componentName.toLowerCase();

      this.compNames.push({
        component: componentName,
        props: { node: namedNode, t: this.t, designTemplate: this.designTemplate },
      });
      if (!Object.is(arr.length - 1, key)) {
        this.compNames.push({ component: { template: "<hr/>" } }); // add horizontal line
      }
    });

    // redirect drag drop callbacks
    window.showUploadPage = null;
    window.uploadFile = this.uploadFile;
    window.uploadUrl = this.uploadUrl;

    var tooltips = $(document).find(".hover-tooltip");
    if (tooltips.length > 0) {
      tippy(tooltips.get(), { allowHTML: true, interactive: true });
    }
  },
  computed: {
    file() {
      if (this.base64) {
        let matches = this.base64.match(/^data:([^;]+);base64,(.*)$/);
        let mimeType = matches[1];
        let extension = matches[1].substr(matches[1].indexOf("/") + 1);
        var arr = this.base64.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        var filename = "share." + extension;
        return new File([u8arr], filename, { type: mimeType });
      }
      return null;
    },
    isMobileSafari: function () {
      return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);
    },
    shareSupported() {
      return this.file && (window.mobile_app || (navigator.canShare && navigator.canShare({ files: [this.file] })));
    },
  },
  watch: {
    base64: function (newValue, oldValue) {
      this.updateDownloadButtonVisibility();
    },
    foregorundUrl: function (newValue, oldValue) {
      this.updateDownloadButtonVisibility();
    },
    componentNamedNodes: function (newValue, oldValue) {
      this.updateDownloadButtonVisibility();
    },
  },
  methods: {
    selectTemplate(url) {
      location.href = url;
    },
    deleteTemplate() {
      if (confirm(this.t.delete_template_config_confirmation)) {
        $.ajax({
          method: "DELETE",
          headers: {
            "X-CSRF-TOKEN": Rails.csrfToken(),
          },
          url: this.delete_template_config_url,
        })
          .then((response) => {
            location.href = this.template_url;
          })
          .catch((error) => {});
      }
    },
    updateErrorMessage(errorMessage) {
      this.errorMessage = errorMessage;
      if (this.params.image_url) {
        // if graph outputs an error message and image_url is set reset the graph
        this.params.image_url = null;
        this.resetGraph();
      }
    },
    updateFullAvailable(fullAvailable) {
      this.fullAvailable = fullAvailable;
    },
    uploadFile(file) {
      var foregroundImageComponent = this.$refs.comps[0];
      foregroundImageComponent.filePicked(file);
    },
    uploadUrl(url) {
      var foregroundImageComponent = this.$refs.comps[0];
      foregroundImageComponent.urlPicked(url);
    },
    updatePreviewSize() {
      // feed preview size into graph
      var devicePixelRatio = window.devicePixelRatio > 1 ? 2 : 1; // fractional values produce black line on bottom
      var outputImageWidth = this.$refs.outputImage.clientWidth * devicePixelRatio;
      var resizePreviewDisplayNode = this.designTemplate.getResizePreviewDisplayNode();
      if (resizePreviewDisplayNode) {
        var resetGraph = false;
        if (outputImageWidth > resizePreviewDisplayNode.resizeX) {
          resetGraph = true;
        }
        resizePreviewDisplayNode.resizeX = outputImageWidth;
        if (resetGraph) this.resetGraph();
      }
    },
    updateDownloadButtonVisibility: function () {
      this.showDownloadButtons = this.base64 && this.foregroundUrl;
    },
    successCallback(base64, width, height) {
      // only for preview runs
      if (!(this.initialSuccessCallback && this.params.image_url)) {
        // when image_url param set ignore initial default preview
        this.base64 = base64;

        window.templateBase64 = base64;

        var megapixel = 0.25;
        var aspectX = width / height;
        var aspectY = height / width;

        var outputFullSize = this.designTemplate.getOutputFullSize();
        window.templateFullWidth = undefined;
        window.templateFullHeight = undefined;
        if (outputFullSize) {
          window.templateFullWidth = outputFullSize.width;
          window.templateFullHeight = outputFullSize.height;
        }
      }
      this.initialSuccessCallback = false;
    },
    updateForegroundFilename(foregroundFilename) {
      this.foregroundFilename = foregroundFilename;
    },
    updateLogoFile(logoFile) {
      this.logoFile = logoFile;
    },
    updateForegroundUrl(foregroundUrl) {
      this.foregroundUrl = foregroundUrl;
    },
    updateUploadResult(uploadResult) {
      this.uploadResult = uploadResult;
    },
    updateUploadStatus(uploadStatus) {
      this.uploadStatus = uploadStatus;
    },
    saveTemplate() {
      this.saving = true;
    },
    downloadImage(fullSize) {
      let that = this;
      var downloadBase64;
      if (fullSize) {
        // open the full image popup
        $.magnificPopup.open({
          items: {
            src: `${this.uploadResult.url}/full_image`,
          },
          type: "ajax",
        });
      } else {
        this.runGraphWithImageSize(this.foregroundUrl, this.designTemplate.consts.OUTPUT_SIZE_PREVIEW_DOWNLOAD).then(
          (base64) => {
            createDownload(base64, this.foregroundFilename ? this.foregroundFilename : "template");
            that.resetGraph();

            if (!fullSize && !that.shareOverlayShown) {
              setTimeout(function () {
                that.shareOverlayShown = true;
                that.sharing = true;
              }, 500);
            }
          }
        );
      }
    },
    runGraphWithImageSize(url, imageSize) {
      let that = this;
      return new Promise((resolve, reject) => {
        that.designTemplate.foregroundUrlChange(url, imageSize);
        that.designTemplate.setSuccessCallback(function (base64) {
          resolve(base64);
        });
      });
    },
    resetGraph() {
      // reset for preview
      this.designTemplate.hiResChange(this.designTemplate.consts.OUTPUT_SIZE_PREVIEW_DISPLAY);
      this.designTemplate.setSuccessCallback(this.successCallback);
    },
    hiResChange(imageSize) {
      let that = this;
      return new Promise((resolve, reject) => {
        that.designTemplate.hiResChange(imageSize);
        that.designTemplate.setSuccessCallback(function (base64) {
          that.resetGraph();
          resolve(base64);
        });
      });
    },
    shareImage() {
      // track click
      window.track("Images", "share_from_templates", "Share from Templates");
      if (window.hj) window.hj("trigger", "templates_download");

      navigator
        .share({
          files: [this.file],
          text: "www.remove.bg",
        })
        .then(() => {
          //alert("shared");
        })
        .catch((error) => {
          //alert(error);
        });
    },
  },
  components: {
    nimp_logo_image,
    nimp_foreground_image,
    nimp_background_selector,
    nimp_text,
    nimp_design_selector,
    ShareOverlay,
    SaveTemplateOverlay,
  },
};
</script>

<style scoped>
:deep(.nav-link.active) {
  border-bottom: 2px solid #454545 !important;
}

:deep(.nav-link) {
  color: #454545 !important;
}

:deep(.node-padding) {
  padding-top: 8px;
  padding-bottom: 8px;
}

.sk-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.template-frame {
  white-space: nowrap;
  text-align: center;
  position: relative;
  display: block;
  height: 100%;
}

#outputImage {
  vertical-align: middle;
  width: 100%;
  color: white;
}

@media (max-width: 992px) {
  .template-container {
    max-width: 100% !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.background-preview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
<style>
.template-alert {
  padding: 5px;
  padding-top: 3px;
}

.valign-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-x-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-x-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

/* text and button styles */
.status-text {
  font-size: 75%;
  color: #768089;
}

/* progress bar styles */
.progress-bar {
  -webkit-animation: progressBar 3s ease-in-out;
  -webkit-animation-fill-mode: both;
  -moz-animation: progressBar 3s ease-in-out;
  -moz-animation-fill-mode: both;
}

.progress {
  height: 8px;
  width: 100%;
}

@-webkit-keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-moz-keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes pulse {
  0%,
  100% {
    background-color: #0f70e6;
  }
  50% {
    background-color: #2287e0;
  }
}

.with-pulse {
  -webkit-animation-name: pulse; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1000ms; /* Safari 4.0 - 8.0 */
  animation-name: pulse;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

/* border control */
@media (min-width: 992px) {
  .desktop-border {
    border: 1px solid #dee2e6 !important;
  }
  .desktop-border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }
  .desktop-border-right {
    border-right: 1px solid #dee2e6 !important;
  }
  .desktop-border-top {
    border-top: 1px solid #dee2e6 !important;
  }
}

@media (max-width: 992px) {
  .mobile-justify-content-center {
    justify-content: center !important;
  }
}

@media (max-width: 992px) {
  .mobile-center {
    margin: 0 auto;
    text-align: center;
  }
}
</style>
