<template>
  <div class="color-list" data-ios-emulate-scroll="x">
    <button class="item picker" v-on:click="colorPickerActive = true">
      <div class="block" v-bind:style="{ 'background-color': color }" v-bind:key="color" v-for="color in colors"></div>
    </button>
    <button
      v-bind:class="{ item: true, active: transparent, transparent: true }"
      v-on:click="selectTransparent()"
      data-hj-suppress
    >
      <div
        class="foreground-thumbnail"
        v-bind:style="{ 'background-image': foregroundUrl ? `url('${foregroundUrl}')` : '' }"
      ></div>
    </button>
    <button
      v-bind:class="{ item: true, active: value == color }"
      v-bind:key="color"
      v-for="color in colors"
      v-bind:style="{ 'background-color': color }"
      v-on:click="select(color)"
      data-hj-suppress
    >
      <div
        class="foreground-thumbnail"
        v-bind:style="{ 'background-image': foregroundUrl ? `url('${foregroundUrl}')` : '' }"
      ></div>
    </button>
    <color-picker
      v-if="colorPickerActive"
      v-bind:value="value"
      v-on:close="colorPickerActive = false"
      v-on:change="select($event.newValue)"
    ></color-picker>
  </div>
</template>

<script>
import { rbgEditorApplyBackgroundColorV102 } from "kaleido-event-tracker";
import ColorPicker from "./color_picker.vue";

export default {
  props: {
    resultId: String,
    value: String,
    foregroundUrl: String,
    transparent: Boolean,
  },
  data: function () {
    return {
      colors: [
        "#ffffff",
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4caf50",
        "#8bc34a",
        "#cddc39",
        "#ffeb3b",
        "#ffc107",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#9e9e9e",
        "#607d8b",
        "#000000",
      ],
      colorPickerActive: false,
    };
  },
  methods: {
    select: function (value) {
      this.$emit("change", { newValue: value });
      window.track("Editor", "set_bg_color", "Set Background Color");
      rbgEditorApplyBackgroundColorV102({ image_id: this.resultId, color_value: value });
    },
    selectTransparent: function () {
      this.$emit("change", { newValue: null });
      window.track("Editor", "set_bg_transparent", "Set Background Transparent");
      rbgEditorApplyBackgroundColorV102({ image_id: this.resultId, color_value: "transparent" });
    },
  },
  components: { ColorPicker },
};
</script>

<style scoped>
.color-list {
  padding: 0;
  margin: 0;
  margin-left: -10px;
  margin-right: -10px;
  display: -webkit-flex;
  display: flex;
  overflow: auto;
}
.color-list .item {
  margin: 0 10px;
  width: 80px;
  height: 80px;
  border: #6f6f6f;
  flex: 0 0 auto;
  outline: none;
  border: 1px solid #6f6f6f;
  position: relative;
}
.color-list .item + .item {
  margin-left: 0;
}
.color-list .item.active {
  border-color: #fff;
}
.color-list .item .foreground-thumbnail {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.color-list .item.transparent {
  background: #fff;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA3LjkzNyA3LjkzOCcgaGVpZ2h0PSczMCcgd2lkdGg9JzMwJz48ZyBmaWxsPScjYzZkNWU5Jz48cGF0aCBwYWludC1vcmRlcj0nc3Ryb2tlIGZpbGwgbWFya2VycycgZD0nTS4wMTQuMDE0SDMuOTdWMy45N0guMDE0ek0zLjk3IDMuOTY4aDMuOTU0djMuOTU1SDMuOTd6Jy8+PC9nPjwvc3ZnPgo=");
  background-size: 25px;
}

@media (min-width: 768px) {
  .color-list {
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
  }
  .color-list .item {
    margin: 0 5px 10px 5px;
  }
  .color-list .item.active {
  }
  .color-list .item + .item {
    margin-left: 5px;
  }
}

.picker {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0;
}
.picker .block {
  width: 25%;
  height: 20px;
}
.color-list {
  padding-bottom: 12px;
}
</style>
