<template>
  <div v-if="upload?.captchaRequired" class="backdrop" :style="backdropStyles">
    <div class="rounded-2xl !p-8 shadow-2xl bg-white">
      <checkbox-captcha v-on:completed="completeCaptcha($event)" v-bind="upload?.captchaConfig || {}" style="display: flex; justify-content: center" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import CheckboxCaptcha from "../checkbox_captcha.vue";
import $ from "jquery";
import { Image, Upload } from "@/modules/internal_api/image";
import Rails from "@rails/ujs";
import { hcaptcha_invisible } from "@/src/hcaptcha";

type Captcha = { value: string; version: string };
let onCaptchaCompleted: (captcha: Captcha) => void;
const upload = ref<Upload>();

const requestToken = async (image: Image, tryCount = 1) => {
  upload.value = image.original;
  $.ajax({
    type: "POST",
    url: "/trust_tokens",
    data: upload.value.trustTokenData,
    headers: {
      "X-CSRF-TOKEN": Rails.csrfToken(),
    },
    success: (data) => {
      window.useToken = (token) => {
        upload.value.token = token;
        //this.doUpload(upload);
        emit("enqueueWithToken", image);
      };
      if (data.request && typeof data.request == "string") {
        eval(data.request);
      }
    },
    error: (xhr) => {
      window.submitChallenge = (upload, value) => {
        upload.value.trustTokenData = value;
        requestToken(image);
      };

      window.request_hcaptcha_checkbox = (config, upload, data) => {
        upload.value.captchaRequired = true;
        upload.value.captchaConfig = {
          type: "hcaptcha_checkbox",
          config: config,
        };
        upload.value.canSkipCaptcha = data.can_skip_captcha;
        //upload.value.creditBalance = data.credit_balance;
        onCaptchaCompleted = (captcha) => {
          upload.value.captchaRequired = false;
          window.submitChallenge(upload, {
            proof: "hcaptcha_checkbox",
            hcaptcha_response: captcha.value,
          });
        };
      };

      window.request_hcaptcha_invisible = (config, upload, data) => {
        hcaptcha_invisible(config)
          .then((captcha) => {
            window.submitChallenge(upload, {
              proof: "hcaptcha_invisible",
              hcaptcha_response: captcha,
            });
          })
          .catch((e) => {
            upload.value.status = "error"; // TODO: check for error status
            upload.value.errorMessage = e.message; // TODO: show error message
            //this.processQueue();
          });
      };

      if (xhr.status == 422 && xhr.responseJSON && xhr.responseJSON.request && typeof xhr.responseJSON.request == "string") {
        eval(xhr.responseJSON.request);
      } else if (xhr.status == 422 && xhr.responseJSON && xhr.responseJSON.status == "invalid_csrf_token" && tryCount < 3) {
        $("meta[name='csrf-token']").attr("content", xhr.responseJSON.csrf_token);
        requestToken(image, tryCount + 1);
      } else {
        upload.value.status = "error";
        upload.value.errorMessage = `Error: Failed to prepare upload (error code ${xhr.status}).\nSorry – please reload the page and try again.\nIf the problem persists, contact team@remove.bg`;
        if (window.error_tracking) {
          window.error_tracking.sendError(new Error(`Upload token request failed (try ${tryCount}): Status ${xhr.status}, Text ${(xhr.responseText ? xhr.responseText : "").substring(0, 100)}`));
        }
      }
    },
  });
};

const completeCaptcha = (value: any) => {
  onCaptchaCompleted({
    value: value,
    version: "v2_checkbox",
  });
};

const emit = defineEmits<{
  (e: "enqueueWithToken", image: Image): void;
}>();

const backdropStyles = computed(() => {
  const navbarHeight = document.getElementById("navbar")?.clientHeight || 0;

  const totalSafeZone = navbarHeight + 10;

  return {
    top: `${navbarHeight}px`,
    height: `calc(100% - ${totalSafeZone}px)`,
  };
});

// Expose to parent
defineExpose({
  requestToken,
});
</script>

<style>
.backdrop {
  position: absolute;
  /* Navbar is z-index: 50 and this needs to be under it when expanded */
  z-index: 40;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(16px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
