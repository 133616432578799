import $ from "jquery";
import Vue from "vue";

const context = import.meta.globEager('../components/*.vue')

$(() => {
  var vueComponents = $(".vue-component");
  for(var i = 0; i < vueComponents.length; i++) {
    var tag = $(vueComponents[i]);
    var filename = tag.data("vue-file");
    filename = filename.replace(/\.\//, "../components/");
    var classRef = context[filename].default;
    var props = tag.data("vue-props") || {};
    var on = tag.data("vue-on") || {};

    var boundOn = {};
    Object.keys(on).map(function(key, index) {
      var functionName = on[key];
      boundOn[key] = function() {
        window[functionName].apply(this, arguments);
      }
    });

    // translations
    if("t" in props) {
      props.t = JSON.parse(props.t);
    }

    Vue.prototype.I18n = window.I18n;
    var vue = new Vue({
      el: tag[0],
      render: h => h(classRef, {
        props: props,
        on: boundOn,
      }),
    })
  }
});
