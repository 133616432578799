import $ from "jquery";
import { showUploadPage, uploadFile, uploadUrl, uploadExample } from "./upload";
import { getUploaderTranslations } from "./get_meta";
import EventBus from "./event_bus";

window.showUploadPage = showUploadPage;
window.uploadFile = uploadFile;
window.uploadUrl = uploadUrl;

function fileDropped(e) {
  e.preventDefault();

  var dataTransfer = e.originalEvent.dataTransfer;

  if (dataTransfer.files.length > 1) {
    EventBus.$emit("multiple-images-added");
  }

  for (var item of dataTransfer.files) {
    window.track("Images", "upload_drop_file", "Drop file");

    // Check if the uploade file is an jpg or png
    if (item.type !== "image/jpeg" && item.type !== "image/png") {
      let t = getUploaderTranslations();
      alert(t.only_jpg_png_supported);
      return;
    }

    window.uploadFile(item);
    return;
  }

  if (dataTransfer) {
    var htmlData = dataTransfer.getData("text/html");
    if (htmlData) {
      var url = htmlData.match(/src\s*=\s*"(.+?)"/);
      if (!url) {
        url = htmlData.match(/href\s*=\s*"(.+?)"/);
      }
      if (url) {
        window.track("Images", "upload_drop_url", "Drop URL");
        var htmlEntitiesDecodedUrl = $("<textarea />").text(url[1]).text();
        window.uploadUrl(decodeURI(htmlEntitiesDecodedUrl));
      }
    }
  }
}

// Originally solved by Tim Branyen in his drop file plugin
// http://dev.aboutnerd.com/jQuery.dropFile/jquery.dropFile.js
// jQuery.event.props.push('dataTransfer');
// no longer necessary since we use e.originalEvent.dataTransfer;

import { pickFile } from "../src/pick_file";

$(() => {
  window.onUploadUrl = (url) => {
    var url = prompt("Image URL:");
    if (!url) return;

    window.track("Images", "upload_enter_url", "Enter URL");
    window.uploadUrl(url);
  };
  window.onUploadFile = (url) => {
    if (window.showUploadPage) window.showUploadPage();
    pickFile().then((file) => {
      window.track("Images", "upload_select_file", "Select file");
      window.uploadFile(file);
    });
  };
  window.onUploadBackgroundFile = (url) => {
    pickFile().then((file) => {
      window.uploadBackgroundFile(file);
    });
  };
  window.onExampleSelected = (url) => {
    uploadExample(url);
  };

  if ($(".dropzone-enabled").length > 0) {
    var t = getUploaderTranslations();
    var dropzone = $(`
      <div class="file-dropzone" style="display: none;">
        <img src="/images/corner.svg" style="left:34px; top:34px; position:absolute;">
        <img src="/images/corner.svg" style="right:34px; top:34px; position:absolute; transform: rotate(90deg);">
        <img src="/images/corner.svg" style="left:34px; bottom:34px; position:absolute; transform: rotate(270deg);">
        <img src="/images/corner.svg" style="right:34px; bottom:34px; position:absolute; transform: rotate(180deg);">
        <h1>
          ${t.drop_image_anywhere}
        </h1>
      </div>
    `).appendTo($("body"));

    function showDropzone() {
      if (!dropzone) return;
      dropzone.show();
    }

    function hideDropzone() {
      if (!dropzone) return;
      dropzone.hide();
    }

    dropzone.click(function (e) {
      hideDropzone();
    });
    dropzone.on("dragleave", function (e) {
      hideDropzone();
    });
    $("body").on("dragenter", function (e) {
      e.stopPropagation();
      e.preventDefault();
      showDropzone();
    });
    $("body").on("dragover", function (e) {
      e.stopPropagation();
      e.preventDefault();
      showDropzone();
    });
    $("body").on("drop", function (e) {
      hideDropzone();
      fileDropped(e);
    });
    $("body").bind("paste", function (e) {
      var target = $(e.originalEvent.target);
      if (target.is("input, textarea")) return;
      if (!e.originalEvent.clipboardData) return;
      var items = e.originalEvent.clipboardData.items;
      if (!items) return;

      if (items.length > 1) {
        EventBus.$emit("multiple-images-added");
      }

      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") == -1) continue;
        var blob = items[i].getAsFile();

        window.track("Images", "upload_paste_image", "Paste image");
        window.uploadFile(blob);
        return;
      }

      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("text/plain") == -1) continue;
        items[i].getAsString((url) => {
          window.track("Images", "upload_paste_url", "Paste URL");
          window.uploadUrl(url);
        });
        return;
      }
    });
  }
});
