<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-11 px-0 pl-md-3">
        <hooper :settings="settings" ref="carousel" class="h-auto">
          <slide v-for="(slide, index) in slides" :key="index" :index="index" class="h-auto pr-3">
            <img :src="slide.image" :alt="slide.name" class="img-fluid" />
          </slide>
        </hooper>
      </div>
      <div class="col-1 justify-content-center align-items-center d-none d-md-flex">
        <i class="navigation fas fa-2x fa-chevron-right" @click.prevent="slideNext"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from "@/vendor/hooper.esm";
import "hooper-vue3/dist/hooper.css";

export default {
  props: {
    slides: Array,
  },

  data() {
    return {
      settings: {
        infiniteScroll: true,
        wheelControl: false,
        centerMode: true,
        itemsToShow: 1.2,
        breakpoints: {
          577: {
            centerMode: false,
            itemsToShow: 1.25,
          },
          769: {
            centerMode: false,
            itemsToShow: 1.25,
          },
        },
      },
    };
  },

  components: {
    Hooper,
    Slide,
  },

  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },

    slideNext() {
      this.$refs.carousel.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #454545;

  &:hover {
    text-decoration: none;
  }
}

img {
  border: 1px rgba(0, 0, 0, 0.15) solid;

  border-radius: 0.5rem;
  @media (min-width: 769px) {
    border-radius: 1rem;
  }
}

i.navigation {
  color: #b9bfc3;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #454545;
  }
}

h6 {
  color: #98a0a6;
  font-size: 100%;
}

h5 {
  font-size: 100%;
  line-height: 1.5;
}

ul {
  display: flex;
}
</style>
