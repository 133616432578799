<template>
  <div>
    <div id="drag-wrapper" :class="{'grey-10' : dragging === true}"
         class="p-2 d-flex align-items-center rounded"
         @drop.prevent="dropHandler($event)"
         @dragover.prevent="dragging = true"
         @dragleave.prevent="dragging = false">
      <p class="mb-0 text-secondary">
        Optional: <span id="upload" class="text-primary" @click.prevent="openFilePicker">
        <i class="fas fa-upload"></i> Upload Image</span> or drop a file
      </p>
    </div>
    <div v-if="fileLimitWarning" class="text-left alert alert-warning" role="alert">
      You can only add up to 10 files.
    </div>
    <div v-if="fileTypeWarnings.length > 0" class="alert alert-warning" role="alert">
      <ul class="p-0 m-0">
        <li v-for="(file, index) in fileTypeWarnings" :key="index" class="d-flex">
          <p class="mb-0 text-left">
            <span style="word-break: break-all;">{{ file.name }}</span>
            has unsupported file type {{ file.type }}
          </p>
        </li>
      </ul>
    </div>
    <div v-if="fileList.length > 0" id="file-list" class="text-secondary">
      <p class="d-flex mb-1 pl-1 pt-2">
        Files to upload:
      </p>
      <ol class="ml-4 px-0">
        <li v-for="(file, index) in fileList" :key="index">
          <p class="d-flex mb-1" style="word-break: break-all;">
            {{ file.name }}
            <span class="" @click="removeFile(index)">
              <i class="fas fa-trash text-danger pl-1"></i>
            </span>
          </p>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file_field_selector: String,
    submit_button_selector: String,
    supported_file_types: Array
  },

  data() {
    return {
      maxNumberOfFiles: 10,
      fileLimitWarning: false,
      supportedFileTypes: [],
      fileTypeWarnings: [],
      dragging: false,
      fileField: null,
      submitButton: null,
      fileList: []
    }
  },

  mounted() {
    this.fileField = document.getElementById(this.file_field_selector)
    this.submitButton = document.getElementById(this.submit_button_selector)
    this.supportedFileTypes = this.supported_file_types
    this.fileList = Array.from(this.fileField.files)

    this.fileField.addEventListener("change", (event) => {
      this.clearWarnings()
      this.addFiles(event.target.files)
      this.updateFileField()
    })

    this.submitButton.form.addEventListener("submit", (event) => {
      this.submitButton.value = "Sending..."
      this.submitButton.classList.add("disabled")
    })
  },

  methods: {
    clearWarnings() {
      this.fileLimitWarning = false
      this.fileTypeWarnings = []
    },

    dropHandler(event) {
      this.clearWarnings()
      this.dragging = false

      this.addFiles(event.dataTransfer.files)
      this.updateFileField()
    },

    validFile(file) {
      if (this.supportedFileTypes.includes(file.type)) {
        return true
      } else {
        this.fileTypeWarnings.push(file)
        return false
      }
    },

    addFiles(fileList) {
      const files = Array.from(fileList)
      let filesToAdd = []
      this.clearWarnings()

      files.forEach((file) => {
        if (this.validFile(file)) {
          filesToAdd.push(file)
        }
      })

      if (this.fileList.length >= this.maxNumberOfFiles || (filesToAdd.length + this.fileList.length) > this.maxNumberOfFiles) {
        this.fileLimitWarning = true
        return
      }

      this.fileList.push(...filesToAdd)
    },

    removeFile(index) {
      this.$delete(this.fileList, index)
      this.updateFileField()
    },

    updateFileField() {
      let dataTransfer = new DataTransfer()

      this.fileList.forEach((file) => {
        dataTransfer.items.add(file)
      })

      this.fileField.files = dataTransfer.files
    },

    openFilePicker() {
      this.fileField.click()
    }
  }
}
</script>

<style lang="scss" scoped>
#drag-wrapper {
  border: 1px dashed #dee2e6 !important;
}

#upload {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

i.fa-trash {
  cursor: pointer;
}

div#file-list {
  font-size: 14px;
}

.grey-10 {
  background-color: #EDEFF0;
}
</style>
