<template>
  <div v-if="banner?.name" class="w-full z-50">
    <Alert :dismissable="true" class="rounded-none" :onClose="close" :tone="banner.tone" hideIcon>
      <span class="block" v-html="banner.html"></span>
    </Alert>
  </div>
</template>

<script setup lang="ts">
import { Alert } from "prism";
import Rails from "@rails/ujs";
import Routes from "@/modules/routes";
import Configs from "@/modules/configs";

interface AlertBannerConfig {
  name: string;
  slug: string;
  html: string;
  tone?: string;
  remember_cta_action?: boolean;
}

const banner = Configs.get("banner") as AlertBannerConfig;

const close = async () => {
  if (banner.remember_cta_action) {
    await fetch(Routes.get("session_preferences_url"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify({
        key: `${banner.name}_dismissed`,
        value: {
          [banner.slug]: true,
        },
      }),
    });
  }
};
</script>
