<template>
  <div>
    <div class="row">
      <div class="col-1 justify-content-end align-items-center d-none d-md-flex">
        <i class="navigation fas fa-2x fa-chevron-left" @click.prevent="slidePrev"></i>
      </div>
      <div class="col-12 col-md-10 px-0">
        <hooper :settings="settings" ref="carousel" class="h-auto">
          <slide v-for="(slide, index) in slides" :key="index" :index="index" class="h-auto">
            <div class="px-1 px-md-3 h-100">
              <a :href="slide.url" target="_blank">
                <div class="card h-100">
                  <img :src="slide.image" class="card-img-top" />
                  <div class="card-body">
                    <h6 class="text-uppercase">{{ slide.name }}</h6>
                    <h5>
                      <a :href="slide.url" target="_blank">{{ slide.text }}</a>
                      <i class="fas fa-arrow-right ml-1"></i>
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </slide>
        </hooper>
      </div>
      <div class="col-1 justify-content-start align-items-center d-none d-md-flex">
        <i class="navigation fas fa-2x fa-chevron-right" @click.prevent="slideNext"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from "@/vendor/hooper.esm";
import "hooper-vue3/dist/hooper.css";

export default {
  props: {
    slides: Array,
  },

  data() {
    return {
      settings: {
        infiniteScroll: true,
        wheelControl: false,
        centerMode: true,
        itemsToShow: 1.25,
        breakpoints: {
          577: {
            centerMode: false,
            itemsToShow: 2,
          },
          769: {
            centerMode: false,
            itemsToShow: 3,
          },
        },
      },
    };
  },

  components: {
    Hooper,
    Slide,
  },

  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },

    slideNext() {
      this.$refs.carousel.slideNext();
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #454545;

  &:hover {
    text-decoration: none;
  }
}

i.navigation {
  color: #b9bfc3;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #454545;
  }
}

h6 {
  color: #98a0a6;
  font-size: 100%;
}

h5 {
  font-size: 100%;
  line-height: 1.5;
}

ul {
  display: flex;
}
</style>
