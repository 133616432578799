import { PersistentStore } from "@/stores/persistent_store";

import * as StackBlur from "stackblur-canvas";

interface OptsType {
  force: boolean;
  beforeApplyingBackgroundImage?: () => void;
  afterApplyingBackgroundImage?: () => void;
  onError?: () => void;
}

interface Cache {
  [source: string]: {
    blur?: HTMLImageElement;
    regular?: HTMLImageElement;
    blurRadius?: number;
    lastUsedOn?: number;
  };
}

export class BackgroundImage {
  BLUR = "blur";
  REGULAR = "regular";
  BLUR_RADIUS = "blurRadius";
  LAST_USED_ON = "lastUsedOn";

  static cache: Cache = {};

  // The cache will get rid of least recently used background image after it hits this limit
  static CacheLength = 12;

  static async updateBackgroundImage(
    imagePersistentStore: PersistentStore,
    callback: (image: HTMLImageElement) => void,
    opts?: OptsType
  ): Promise<void> {
    const bgImage = new BackgroundImage();
    return bgImage.updateBackgroundImage(imagePersistentStore, callback, opts);
  }

  async updateBackgroundImage(
    imagePersistentStore: PersistentStore,
    callback: (image: HTMLImageElement) => void,
    opts?: OptsType
  ): Promise<void> {
    // return the existing image element from cache if the image for the given url exists
    // create and persist the image to cache if not.
    const source = imagePersistentStore?.selectedBackgroundPhotoUrl;
    const isBackgroundBlurEnabled = imagePersistentStore?.isBackgroundBlurEnabled;

    const blurRadius = imagePersistentStore?.blurRadius;
    const hasBlurRadiusChanged = this.getBlurRadiusFromCache(source) !== blurRadius;
    const forceImageCreation = opts?.force || hasBlurRadiusChanged;

    // Early return if cache available;
    if (!forceImageCreation) {
      let cacheImage = this.getImageFromCache(source, isBackgroundBlurEnabled);
      this.updateCacheLastUsed(source);
      if (cacheImage) return callback(cacheImage);
    }

    if (source === null) {
      const emptyImage = new Image();
      emptyImage.crossOrigin = "anonymous";

      callback(emptyImage);
      return;
    }

    this.clearCacheIfRequired();
    opts?.beforeApplyingBackgroundImage?.();

    const backgroundImage = await this.fetchImage(source).catch(() => {
      opts?.onError();
    });

    if (!backgroundImage) return;
    if (isBackgroundBlurEnabled) {
      return new Promise(async (resolve) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = backgroundImage.width;
        canvas.height = backgroundImage.height;
        ctx.drawImage(backgroundImage, 0, 0);
        StackBlur.canvasRGB(canvas, 0, 0, canvas.width, canvas.height, blurRadius);
        const resultImage = new Image();
        resultImage.crossOrigin = "anonymous";
        resultImage.src = canvas.toDataURL("image/png");
        resultImage.onload = () => {
          callback(resultImage);
          this.releaseCanvas(canvas);

          opts?.afterApplyingBackgroundImage?.();
          resolve(null);
        };

        this.updateImageToCache(source, resultImage, this.BLUR);
        this.updateBlurRadiusToCache(source, blurRadius);
        this.updateCacheLastUsed(source);
      });
    }

    return new Promise((resolve) => {
      this.updateImageToCache(source, backgroundImage, this.REGULAR);
      callback(backgroundImage);

      opts?.afterApplyingBackgroundImage?.();
      this.updateCacheLastUsed(source);
      resolve(null);
    });
  }

  static clearCache() {
    this.cache = {};
  }

  clearCacheIfRequired() {
    if (Object.keys(BackgroundImage.cache).length < BackgroundImage.CacheLength) return;

    let oldest = Number.POSITIVE_INFINITY;
    let source: string;
    Object.entries(BackgroundImage.cache).forEach(([key, value]) => {
      if (value[this.LAST_USED_ON] < oldest) {
        oldest = value[this.LAST_USED_ON];
        source = key;
      }
    });

    if (oldest > 0) {
      delete BackgroundImage.cache[source];
    }
  }

  updateImageToCache(url: string, image: HTMLImageElement, type: string) {
    if (!BackgroundImage.cache[url]) {
      BackgroundImage.cache[url] = {};
    }
    BackgroundImage.cache[url][type] = image;
  }

  getBlurRadiusFromCache(source: string): number | undefined {
    if (BackgroundImage.cache[source]) {
      return BackgroundImage.cache[source][this.BLUR_RADIUS];
    }
  }

  updateBlurRadiusToCache(source: string, blurRadius: number) {
    BackgroundImage.cache[source][this.BLUR_RADIUS] = blurRadius;
  }

  getImageFromCache(source: string, isBackgroundBlurEnabled: boolean): HTMLImageElement | undefined {
    const cacheKey = isBackgroundBlurEnabled ? this.BLUR : this.REGULAR;
    return BackgroundImage.cache[source]?.[cacheKey];
  }

  updateCacheLastUsed(source: string) {
    BackgroundImage.cache[source][this.LAST_USED_ON] = Date.now();
  }

  fetchImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => resolve(img);
      img.onerror = () => reject();
      img.src = url;
    });
  }

  releaseCanvas(canvas: HTMLCanvasElement | undefined): void {
    if (!canvas) {
      return;
    }
    canvas.width = 0;
    canvas.height = 0;
    const ctx = canvas.getContext("2d");
    ctx && ctx.clearRect(0, 0, 1, 1);
  }
}
