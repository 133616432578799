import $ from "jquery";
var form = null;
var input = null;

export function pickFile(accept = ".jpg,.jpeg,.png,image/jpeg,image/png") {
  return new Promise((resolve, reject) => {
    if(!form) {
      form = $("<form style='display: none;'/>");
      form.appendTo($("body"));
    }
    if(input) {
      input.remove();
    }
    input = $(`
      <input name="image[original]" type="file" accept=${accept}>
    `);
    input.appendTo(form);

    input.on("change", function() {
      if(this.files.length == 0) return;
      resolve(this.files[0]);
    });

    input[0].click();
  });
};
