import $ from 'jquery';
import Rails from '@rails/ujs';
var cache = {};

export default (type) => {
  return new Promise((resolve, reject) => {
    if(cache[type] != null) {
      resolve(cache[type]);
      return;
    }

    $.ajax({
      type: "GET",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      url: "/backgrounds",
      data: {
        type: type,
      }
    }).then((data) => {
      cache[type] = data.data;
      resolve(data.data)
    }).catch(() => {
      reject()
    });
  });
}
