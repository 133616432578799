<template>
  <div class="!mr-6">
    <p-dialog trigger-classes="focus:outline-none">
      <template v-slot:trigger>
        <p-button variant="secondary"><p-globe-icon class="mr-1"> </p-globe-icon> {{ currentLocale.name }} </p-button>
      </template>
      <template v-slot:content="{ closeDialog }">
        <div class="flex flex-col gap-y-6 w-full max-h-[680px]">
          <p-heading level="h4">{{ I18n.t("locales.choose_your_language") }}</p-heading>
          <div class="relative grid gap-3 sm:grid-cols-3 w-full pb-3">
            <p-selectable-card v-for="locale in locales" :key="locale.code" :selected="locale.code === currentLocale.code" :on-click="() => switchToLocale(locale, closeDialog)">
              {{ locale.name }}
            </p-selectable-card>
            <p-button class="sm:hidden mb-3" fullWidth autofocus variant="secondary" type="reset" :on-click="closeDialog">{{ I18n.t('editor.modal_close') }}</p-button>
          </div>
        </div>
      </template>
    </p-dialog>
  </div>
</template>

<script setup>
import "@/src/i18n";
import { inject } from "vue";

const I18n = inject("I18n");

const props = defineProps({
  currentLocale: Object,
  locales: Array,
});

const switchToLocale = (locale, closeDialog) => {
  closeDialog();
  window.location.href = locale.url;
};
</script>
