<template>
  <div @click="mainAlertMessage = ''">
    <div class="col-md-12">
      <div class="alert rounded-sm" :class="alertClass" v-if="mainAlertMessage">
        {{ mainAlertMessage }}
      </div>
      <div id="wrapper" class="d-flex">
        <div class="">
          <p class="mb-0">
            <span v-html="t.info"></span>
          </p>
        </div>
        <span class="ml-auto d-none d-md-block my-auto">
          <button type="button" class="btn btn-primary ml-3 text-nowrap" @click="addKeyClicked"><i class="fas fa-plus mr-1"></i> {{ t.new_api_key }}</button>
        </span>
      </div>
      <span class="d-md-none">
        <button type="button" class="btn btn-primary w-100 mt-4" @click="addKeyClicked"><i class="fas fa-plus mr-1"></i> {{ t.new_api_key }}</button>
      </span>
    </div>
    <div class="col-12 mt-4">
      <div class="list-group" v-if="api_keys && api_keys.length > 0">
        <a href="#" class="list-group-item list-group-item-action" @click.prevent="openMainModal(api_key)" v-for="api_key in api_keys" :key="api_key.id">
          <div class="d-md-flex w-100 justify-content-between d-none">
            <p class="mb-1" style="color: #0f70e6; font-weight: 600">{{ api_key["label"] }}</p>
            <small class="text-nowrap ml-2 mt-1" style="color: #6c757c">{{ api_key["last_used"] ? `${t.last_used} ${api_key["last_used"]}` : "" }}</small>
          </div>

          <div class="d-md-none">
            <p class="mb-1" style="color: #0f70e6">{{ api_key["label"] }}</p>
            <small class="text-nowrap" style="color: #6c757c">{{ api_key["last_used"] ? `${t.last_used} ${api_key["last_used"]}` : "" }}</small>
          </div>
        </a>
      </div>

      <div v-else v-html="no_api_keys_image"></div>
      <p v-html="t.random_tip" class="mt-4 mb-4 text-center" style="color: #6c757c"></p>

      <div ref="modal" v-if="showModal == 'main' || showModal == 'add'">
        <transition name="modal">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog modal-dialog-scrollable" role="document">
                <div class="modal-content">
                  <div class="modal-header align-items-center">
                    <h5 class="modal-title">{{ showModal == "main" ? t.api_key : t.new_api_key }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showModal = false">
                      <span aria-hidden="true" style="font-size: 1.5em">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="alert rounded-sm" :class="alertClass" v-if="alertMessage">
                      {{ alertMessage }}
                    </div>
                    <div class="alert alert-warning rounded-sm" v-if="apiKey.rotate_at">{{ t.rotate_at_message }} {{ new Date(apiKey.rotate_at).toLocaleString(t.current_locale) }}.</div>

                    <div class="form-group">
                      <label for="label">{{ t.key_label }}</label>
                      <input ref="labelInput" type="text" class="form-control" id="label" aria-describedby="labelHelp" v-model="apiKey.label" maxlength="255" />
                      <small id="labelHelp" class="form-text text-muted">{{ t.label_help }}</small>
                    </div>

                    <span class="api-key" v-if="apiKey.key">
                      <label for="label" class="mt-2">{{ t.api_key }}:</label>

                      <div class="mb-3 d-flex">
                        <code data-hj-suppress style="width: 70%">
                          <input ref="apiKeyInput" readonly="true" @focus="$event.target.select()" type="text" class="form-control" placeholder="" aria-describedby="labelHelp" v-model="apiKey.key" />
                        </code>
                        <button ref="copyButton" class="btn btn-primary ml-2" type="button" @click="copyToClipboard"><i class="fas fa-copy"></i> {{ t.copy }}</button>
                      </div>
                    </span>

                    <div ref="panelHeading" class="panel-heading mt-4" :class="apiKey.ip_passlist ? 'active' : ''" role="tab" id="headingAdvancedOptions" v-if="enterprise || showModal == 'main'">
                      <p class="panel-title mb-0">
                        <a class="ml-2 underline-on-hover" role="button" data-toggle="collapse" href="#collapseAdvancedOptions" aria-expanded="true" aria-controls="collapseAdvancedOptions" @click="changeArrow">{{ t.advanced_options }}</a>
                      </p>
                    </div>
                    <div id="collapseAdvancedOptions" class="panel-collapse collapse in" :class="this.apiKey.ip_passlist ? 'show' : ''" role="tabpanel" aria-labelledby="headingAdvancedOptions">
                      <div class="panel-body">
                        <div class="row" v-if="enterprise">
                          <div class="col">
                            <hr />
                            <div class="float-right">
                              <toggle-button v-if="!toggle_passlist" data-testid="otp_active_toggle_on" data-toggle="modal" v-model="toggle_passlist" :labels="switchLabels" :color="switchColors" :sync="true" :width="55" :height="25" />
                              <toggle-button v-if="toggle_passlist" data-testid="otp_active_toggle_off" data-toggle="modal" v-model="toggle_passlist" :labels="switchLabels" :color="switchColors" :sync="true" :width="55" :height="25" />
                            </div>
                            <p>
                              {{ t.ip_passlist_restrict_label }}
                            </p>
                          </div>
                        </div>
                        <div class="mb-3"></div>
                        <div v-if="toggle_passlist && enterprise">
                          <p>
                            <b>{{ t.ip_passlist_label }}</b>
                          </p>
                          <textarea id="ip_passlist" class="form-control text-monospace" v-model="ip_passlist_text"></textarea>
                          <small class="form-text text-muted">{{ t.ip_passlist_hint }}<code>127.0.0.1</code>, <code>127.0.0.0/24</code>, <code>2001:0db8:85a3:0000:0000:8a2e:0370:7334</code>.</small>
                        </div>
                        <div class="row" v-if="apiKey.key">
                          <div class="col-12">
                            <hr class="mt-0" />
                            <a href="#" class="mt-2" @click.prevent="showModal = 'reissue'" :disabled="buttonsDisabled" style="color: #ff7272"><i class="fas fa-sync mr-1" style="color: #ff7272"></i> {{ t.reissue_key }}</a>
                            <span class="hover-tooltip" style="margin-left: 5px; opacity: 0.8" :data-tippy-content="t.reissue_popup" tabindex="0">
                              <i class="fas fa-info-circle" style="font-size: 75%"></i>
                            </span>
                            <a href="#" class="mt-2 ml-5" @click.prevent="showModal = 'delete'" :disabled="buttonsDisabled" style="color: #ff7272"><i class="fas fa-trash mr-1"></i> {{ t.delete_key }}</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button data-testid="add_save_button" id="addSaveButton" type="button" class="btn btn-primary" @click="addOrSaveKey" :disabled="buttonsDisabled">{{ showModal == "add" ? t.create : t.save_changes }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div v-if="showModal == 'delete'">
        <transition name="modal">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog modal-dialog-scrollable" role="document">
                <div class="modal-content">
                  <div class="modal-header align-items-center">
                    <h5 class="modal-title">{{ t.delete_title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showModal = 'main'">
                      <span aria-hidden="true" style="font-size: 1.5em">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="alert rounded-sm" :class="alertClass" v-if="alertMessage">
                      {{ alertMessage }}
                    </div>
                    <p>{{ t.delete_explainer_0 }}</p>
                    <p>{{ t.delete_explainer_1 }}</p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary-outline" @click="showModal = 'main'" :disabled="buttonsDisabled">{{ t.cancel }}</button>
                    <button type="button" class="btn btn-danger" @click="deleteKey" :disabled="buttonsDisabled">{{ t.delete_confirm }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div v-if="showModal == 'reissue'">
        <transition name="modal">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog modal-dialog-scrollable" role="document">
                <div class="modal-content">
                  <div class="modal-header align-items-center">
                    <h5 class="modal-title">{{ t.reissue_title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showModal = ''">
                      <span aria-hidden="true" style="font-size: 1.5em">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p>{{ t.reissue_explainer_0 }}</p>
                    <p>{{ t.reissue_explainer_1 }}</p>
                    <div class="form-group row">
                      <label for="select_reissue" class="col-sm-6 col-form-label">{{ t.reissue_deactivate_label }}</label>
                      <div class="col-sm-6">
                        <select v-model="reissue_key_timeout" class="form-control" id="select_reissue">
                          <option value="0">
                            {{ t.reissue_deactivate_immediately }}
                          </option>
                          <option value="10">
                            {{ t.reissue_deactivate_10_minutes }}
                          </option>
                          <option value="60">
                            {{ t.reissue_deactivate_1_hour }}
                          </option>
                          <option value="720">
                            {{ t.reissue_deactivate_12_hours }}
                          </option>
                          <option value="1440">
                            {{ t.reissue_deactivate_24_hours }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary-outline" @click="showModal = 'main'" :disabled="buttonsDisabled">{{ t.cancel }}</button>
                    <button type="button" class="btn btn-danger" @click="reissueKey" :disabled="buttonsDisabled">{{ t.reissue_key }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <div v-if="showModal == 'too_many_api_keys'">
        <transition name="modal">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal-dialog modal-dialog-scrollable" role="document">
                <div class="modal-content">
                  <div class="modal-header align-items-center">
                    <h5 class="modal-title">{{ t.too_many_api_keys_title }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showModal = false">
                      <span aria-hidden="true" style="font-size: 1.5em">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p>
                      {{ t.too_many_api_keys_explainer_0 }} {{ this.max_api_keys }} {{ t.api_keys }}.
                      <span v-if="enterprise">{{ t.too_many_api_keys_explainer_enterprise }}</span>
                      <span v-else>{{ t.too_many_api_keys_explainer_non_enterprise }}</span>
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="contactUsClicked">{{ t.too_many_api_keys_contact_us }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Rails from "@rails/ujs";
import { ToggleButton } from "vue-js-toggle-button";
import tippy from "tippy.js";

export default {
  components: { ToggleButton },
  props: {
    t: Object,
    api_keys_props: Array,
    authenticity_token: String,
    max_api_keys: Number,
    no_api_keys_image: String,
    enterprise: Boolean,
  },
  data: function () {
    return {
      showModal: "",
      apiKey: undefined,
      buttonsDisabled: false,
      toggle_passlist: false,
      alertClass: "",
      alertMessage: "",
      mainAlertMessage: "",
      api_keys: [],
      reissue_key_timeout: "0",
      ip_passlist_text: "",
      switchLabels: {
        checked: "On",
        unchecked: "Off",
      },
      switchColors: {
        checked: "#8CE4BF",
        disabled: "#8CE4BF",
        unchecked: "#454545",
      },
    };
  },
  watch: {
    showModal: function (val, oldVal) {
      if (oldVal != "reissue") {
        this.alertMessage = "";
      }
      if (val == "main" || val == "add") {
        this.toggle_passlist = this.apiKey.ip_passlist ? true : false;
        this.ip_passlist_text = this.apiKey.ip_passlist?.join("\n");
        this.updateTooltips();
      }
      if (val == "reissue") {
        this.reissue_key_timeout = "0";
      }
      if (val == "add") {
        let that = this;
        setTimeout(function () {
          that.$refs.labelInput.select();
        }, 0);
      }
      document.body.style.overflow = val ? "hidden" : "";
    },
  },
  mounted: function () {
    //debugger;
    this.api_keys = this.api_keys_props;
    this.alertClass = "";
    this.alertMessage = "";
  },
  computed: {
    datetime: function () {
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = new Date(Date.now() - tzoffset);
      return localISOTime.toISOString().split("T")[0] + " " + localISOTime.toISOString().split("T")[1].slice(0, -5);
    },
  },
  methods: {
    changeArrow() {
      this.$refs.panelHeading.classList.toggle("active");
    },
    copyToClipboard() {
      this.$refs.apiKeyInput.focus();
      document.execCommand("copy");
      this.$refs.copyButton.innerHTML = '&nbsp;&nbsp;<i class="fas fa-check"></i>&nbsp;&nbsp;';
    },
    hideModal() {
      this.showModal = "";
    },
    contactUsClicked(e) {
      window.location.href = "/support/contact";
    },
    addKeyClicked() {
      if (this.api_keys.length >= this.max_api_keys) {
        this.showModal = "too_many_api_keys";
        return;
      }
      this.apiKey = { label: this.t.untitled + " (" + this.datetime + ")" };
      this.showModal = "add";
    },
    openMainModal(apiKey) {
      this.apiKey = { ...apiKey };
      this.showModal = "main";
    },
    updateTooltips() {
      let that = this;
      this.$nextTick(() => {
        var tooltips = $(that.$refs.modal).find(".hover-tooltip");
        if (tooltips.length > 0) {
          tippy(tooltips.get(), { allowHTML: true, interactive: true });
        }
      });
    },
    deleteKey() {
      this.alertClass = "";
      this.alertMessage = "";
      this.buttonsDisabled = true;
      $.ajax({
        type: "DELETE",
        headers: {
          "X-CSRF-TOKEN": Rails.csrfToken(),
        },
        url: `/api_key/${this.apiKey.id}`,
        data: {
          authenticity_token: this.authenticity_token,
          current_locale: this.t.current_locale,
        },
        success: (data) => {
          this.buttonsDisabled = false;
          if (data.status == "ok") {
            this.api_keys = data.api_keys;
            this.showModal = "";
          }
          this.showResponse(data, true);
        },
        error: (e) => {
          this.buttonsDisabled = false;
          this.showResponse(e);
        },
      });
    },
    addOrSaveKey() {
      let that = this;
      this.alertClass = "";
      this.alertMessage = "";
      this.buttonsDisabled = true;

      var url = "/api_key/";
      if (this.showModal == "add") {
        url += `add`;
      }
      if (this.showModal == "main") {
        url += `save/${this.apiKey.id}`;
      }

      var ip_passlist;
      if (this.ip_passlist_text) {
        ip_passlist = this.ip_passlist_text
          .split("\n")
          .map((e) => e.trim())
          .filter((e) => e != null && e !== "");
      }
      $.ajax({
        type: "POST",
        url: url,
        headers: {
          "X-CSRF-TOKEN": Rails.csrfToken(),
        },
        data: {
          label: this.apiKey.label,
          ip_passlist: ip_passlist,
          allow_any: !this.toggle_passlist,
          allow_list: this.toggle_passlist,
          authenticity_token: this.authenticity_token,
          current_locale: this.t.current_locale,
        },
        success: (data) => {
          this.buttonsDisabled = false;
          if (data.status == "ok") {
            this.apiKey = data.api_key;
            this.api_keys = data.api_keys;
            if (this.showModal == "add") {
              this.showModal = "main";
              setTimeout(function () {
                that.showResponse(data);
              }, 0); // otherwise message will be overriden by showModal watcher
            } else if (this.showModal == "main") {
              this.showModal = "";
              this.showResponse(data, true);
            }
          }
        },
        error: (e) => {
          this.buttonsDisabled = false;
          this.showResponse(e);
        },
      });
    },
    showResponse(data, main = false) {
      alertMessage = "Error: " + data.status + ". " + this.t.error_contact;
      if (data.status == 200 || data.status == "ok") {
        this.alertClass = "alert-success";
      } else if (data.status == "warning") {
        this.alertClass = "alert-warning";
      } else {
        this.alertClass = "alert-danger";
      }
      var alertMessage;
      if (data.status != 200 && data.responseJSON && data.responseJSON.message) {
        alertMessage = data.responseJSON.message;
      } else if (data.message) {
        alertMessage = data.message;
      } else {
        alertMessage = "Error: " + data.status + ". " + this.t.error_contact;
      }

      if (alertMessage) {
        if (main) this.mainAlertMessage = alertMessage;
        else this.alertMessage = alertMessage;
      }
    },
    reissueKey(e) {
      e.preventDefault();
      this.alertClass = "";
      this.alertMessage = "";

      this.buttonsDisabled = true;
      $.ajax({
        type: "POST",
        headers: {
          "X-CSRF-TOKEN": Rails.csrfToken(),
        },
        url: `/api_key/rotate/${this.apiKey.id}`,
        data: {
          reissue_key_timeout: this.reissue_key_timeout,
          current_locale: this.t.current_locale,
        },
        success: (data) => {
          this.buttonsDisabled = false;
          if (data.status == "ok" || data.status == "warning") {
            this.api_keys = data.api_keys;
            this.apiKey = data.api_key;
            this.showModal = "main";
          }
          this.showResponse(data);
        },
        error: (e) => {
          this.buttonsDisabled = false;
          this.showResponse(e);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.panel-heading {
  padding: 0;
  border: 0;
}
.panel-title > a,
.panel-title > a:active {
  color: #0f70e6;
  text-decoration: none;
}
.panel-heading a:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f0da";
  float: left;
  transition: all 0.5s;
  color: #454545;
  font-weight: 700;
}
.panel-heading.active a:before {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
}

.alert-danger {
  color: #721c24;
}
.alert-warning {
  color: #856404;
}
.alert-success {
  color: #155724;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.btn-secondary-outline {
  border: 1px solid #0f70e6;
  color: #0f70e6;
}

.btn-danger {
  background: #ff7272;
  border-color: #ff7272;
  color: white;
}
</style>
