<template>
  <div class="checkbox-captcha" ref="checkbox-captcha">
    
  </div>
</template>

<script>
import { hcaptcha_checkbox } from '../src/hcaptcha';

export default {
  props: {
    type: String,
    config: Object,
  },
  mounted() {
    if(this.type == "hcaptcha_checkbox") {
      hcaptcha_checkbox({ element: this.$refs['checkbox-captcha'], ...this.config }).then((value) => {
        this.$emit("completed", value)
      });
    }
    else {
      alert("Error: Unknown captcha type")
    }
  },
}
</script>

<style scoped>
</style>
