<template>
  <Drawer :on-close="closePanel" :close-icon-position="closeIconPosition" :close-title="I18n.t('editor.modal_close')">
    <template #content>
      <div ref="addBackgroundPanel" class="w-full flex gap-3 md:flex-col !pb-3 md:!pb-0" :class="contentWrapperClasses">
        <TabGroup>
          <TabList class="flex space-x-2 !pl-3 md:!pl-0 md:!pt-4 md:!pb-2" :class="tabListClasses">
            <Tab v-slot="{ selected }" as="template">
              <TabButton class="min-w-[5rem]" :selected="selected">{{ I18n.t("editor.photo") }}</TabButton>
            </Tab>
            <Tab v-slot="{ selected }" as="template">
              <TabButton class="min-w-[5rem]" :selected="selected">{{ I18n.t("editor.color") }}</TabButton>
            </Tab>
            <template v-if="!Flipper.isEnabled('effects_panel')">
              <Tab v-slot="{ selected }" as="template">
                <TabButton class="min-w-[5rem]" :selected="selected">{{ I18n.t("editor.blur") }}</TabButton>
              </Tab>
            </template>
          </TabList>

          <div v-if="isNewLayoutEnabled" class="!m-0 divider"><hr class="!m-0"></div>

          <TabPanels>
            <!-- Start Photo -->
            <TabPanel>
              <div class="sm:h-64 md:!pb-4" :class="photosTabPanelClasses">
                <PhotoTiles
                  :image-button-size="imageButtonSize"
                  :selected-image-state="props.selectedImageState"
                  :available-backgrounds="availableBackgrounds"
                  :is-loading="isLoading"
                />
              </div>
              <div class="hidden md:block shadow-fold w-full h-2"></div>
            </TabPanel>
            <!-- End Photo -->

            <!-- Start Color -->
            <TabPanel>
              <div class="sm:h-64 md:!pb-4" :class="colorsTabPanelClasses">
                <ColorTiles :selected-image-state="props.selectedImageState" :colors="availableColors" />
              </div>
              <div class="hidden md:block shadow-fold w-full h-2"></div>
            </TabPanel>
            <!-- End Color -->

            <!-- Start Blur -->
            <TabPanel>
              <div
                class="flex flex-col gap-6 overflow-scroll hide-scrollbars h-32 md:h-64 !px-4 !pt-2 md:!px-0 md:!pb-4"
              >
                <div class="flex items-center gap-2">
                  <Toggle
                    :name="I18n.t('editor.blur_background')"
                    :model-value="props.selectedImageState.isBackgroundBlurEnabled"
                    @update:modelValue="updateBlurState"
                  />
                  <span class="text-sm text-typo">{{ I18n.t("editor.blur_background") }}</span>
                </div>
                <RangeSlider
                  :value="props.selectedImageState?.blurRadius || 10"
                  @slider-value-changed="changeBlurRadius"
                  :label="I18n.t('editor.blur_amount')"
                  :disabled="!props.selectedImageState.isBackgroundBlurEnabled"
                  :selected-image-state="props.selectedImageState"
                  :min="0"
                  :max="25"
                  :step="1"
                  :on-mousedown="cacheState"
                  :on-touchstart="cacheState"
                  :on-mouseup="applyBlur"
                  :on-touchend="applyBlur"
                />
              </div>
              <!-- Some height to compensate the missing shadow-fold -->
              <div class="w-full h-2"></div>
            </TabPanel>
            <!-- End Blur -->
          </TabPanels>
        </TabGroup>
      </div>
    </template>

    <template v-if="!isNewLayoutEnabled" #mobile-actions>
      <TextButton variant="secondary" @click="reset">{{ I18n.t("editor.reset") }}</TextButton>
      <TextButton @click="closePanel">{{ I18n.t("editor.done") }}</TextButton>
    </template>

    <template #actions>
      <Button :full-width="true" variant="secondary" @click="reset">{{ I18n.t("editor.reset") }}</Button>
      <Button v-if="!isNewLayoutEnabled" :full-width="true" @click="closePanel">{{ I18n.t("editor.done") }}</Button>
    </template>
  </Drawer>
</template>

<script setup lang="ts">
import "@/src/i18n";

import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";

import { Button, TabButton, Toggle, Drawer, TextButton } from "prism";

import ColorTiles from "../prism/color_tiles.vue";
import PhotoTiles from "../prism/photo_tiles.vue";
import RangeSlider from "../prism/range_slider.vue";
import loadSuggestions from "../../src/image_suggestions";
import { onMounted, ref, inject, computed } from "vue";
import Client from "@/modules/internal_api/client";
import { Background } from "@/modules/internal_api/image";
import { useEditorStore } from "@/stores/editor_store";
import {
  rbgEditorApplyBackgroundImageV101,
  rbgEditorApplyBlurV100,
  rbgEditorUploadBackgroundImageV100,
} from "kaleido-event-tracker";
import { PersistentStore } from "@/stores/persistent_store";
import Flipper from "@/modules/flipper";

const I18n = inject("I18n");

interface AddBackgroundProps {
  foregroundType: string;
  selectedImageState: PersistentStore;
}

const props = defineProps<AddBackgroundProps>();
const store = useEditorStore();
const availableColors = ref<string[]>([
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#9e9e9e",
  "#607d8b",
  "#000000",
]);
const availableBackgrounds = ref<Background[]>([]);
const isLoading = ref<boolean>(false);
const cachedState = ref<any>(null);
const nextBlurRadius = ref<number>(0);
const addBackgroundPanel = ref();

onMounted(async () => {
  loadRelevantBackgrounds();
  nextBlurRadius.value = props.selectedImageState.blurRadius;
  updateIsNarrowPanel();
});

const loadRelevantBackgrounds = async () => {
  isLoading.value = true;
  const relevantBackgrounds = await loadSuggestions(props.foregroundType);
  availableBackgrounds.value = relevantBackgrounds;

  isLoading.value = false;
};

const numberOfBackgrounds = computed(() => availableBackgrounds.value.length);
const numberOfBackgroundsPerRow = computed(() => {
  // Loading state shows 32 empty tiles
  if (isLoading.value) {
    return 16 + 3;
  }
  // Half of the available backgrounds + 3 (because they are rendered in groups of 3)
  return Math.ceil(numberOfBackgrounds.value / 2) + 3;
});

const updateBlurState = (value: boolean): void => {
  props.selectedImageState.withSnapshot(() => {
    props.selectedImageState.setBackgroundBlurEnabled(value);
  });

  const eventBlurRadius = value === true ? props.selectedImageState.blurRadius : 0;
  rbgEditorApplyBlurV100({ image_id: store.selectedImage.meta.id, blur_value: eventBlurRadius });
};

const cacheState = () => {
  const clonedState = JSON.parse(JSON.stringify(props.selectedImageState.current));
  cachedState.value = clonedState;
};

const applyBlur = () => {
  props.selectedImageState.setBlurRadius(nextBlurRadius.value);
  props.selectedImageState.snapshotWithState(cachedState.value);
  props.selectedImageState.persist();
  rbgEditorApplyBlurV100({ image_id: store.selectedImage.meta.id, blur_value: props.selectedImageState.blurRadius });
};

const isNewLayoutEnabled = Flipper.isEnabled('background_image_search');
const closeIconPosition = Flipper.isEnabled('background_image_search') ? "floating" : "contained";

const contentWrapperClasses = computed(() => ({
  "flex-col !p-4 !pb-2 md:!p-0 content-wrapper": isNewLayoutEnabled,
  "flex-col-reverse !pb-3 md:!pb-0": !isNewLayoutEnabled,
}));

const photosTabPanelClasses = computed(() => ({
  "gap-1 tile-grid-photos-new-layout": isNewLayoutEnabled,
  "gap-2 tile-grid-photos": !isNewLayoutEnabled,
  "narrow-panel": isNarrowPanel.value,
  "regular-panel": !isNarrowPanel.value,
}));

const colorsTabPanelClasses = computed(() => ({
  "gap-1 tile-grid-photos-new-layout": isNewLayoutEnabled,
  "gap-2 tile-grid-colors": !isNewLayoutEnabled,
  "narrow-panel": isNarrowPanel.value,
  "regular-panel": !isNarrowPanel.value,
}));

const tabListClasses = computed(() => ({
  "justify-start": isNewLayoutEnabled,
  "justify-center md:justify-start": !isNewLayoutEnabled,
}));

const imageButtonSize = computed(() => {
  return isNewLayoutEnabled ? "lg" : "default";
});

const isNarrowPanel = ref(true);
const updateIsNarrowPanel = () => {
  isNarrowPanel.value = addBackgroundPanel.value?.clientWidth < 308;
};

window.addEventListener("resize", () => {
  updateIsNarrowPanel();
});

const emit = defineEmits<{
  (e: "closePanel"): void;
  (e: "reset"): void;
}>();

const closePanel = () => {
  emit("closePanel");
};

const reset = () => {
  emit("reset");
};

const changeBlurRadius = (blurRadius) => {
  const parsedBlurRadius = parseInt(blurRadius, 10);
  nextBlurRadius.value = parsedBlurRadius;
};

const uploadBackgroundFile = async (file: File) => {
  store.isUploadingCustomBackground = true;

  const result = await Client.uploadBackgroundToBucket(store.selectedImage, file);

  if (result.success) {
    props.selectedImageState.withSnapshot(() => {
      props.selectedImageState.setSelectedBackgroundColor(undefined);
      props.selectedImageState.setUploadedBackgroundPhotoUrl(result.url);
      props.selectedImageState.setSelectedBackgroundPhotoUrl(result.url);
      props.selectedImageState.setIsBackgroundAddedByBlur(false);
    });

    rbgEditorUploadBackgroundImageV100({ image_id: store.selectedImage.meta.id });
    rbgEditorApplyBackgroundImageV101({ image_id: store.selectedImage.meta.id, background_image_id: result.url });
  }

  store.isUploadingCustomBackground = false;
};
window.uploadBackgroundFile = uploadBackgroundFile;
</script>

<style scoped>
.narrow-panel {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.regular-panel {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.tile-grid-photos-new-layout {
  display: grid;
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: scroll;

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 12.5rem;
  }
}

.tile-grid-photos {
  display: grid;
  grid-template-columns: repeat(v-bind(numberOfBackgroundsPerRow), minmax(64px, 1fr));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  overflow-x: scroll;
  overflow-y: hidden;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding-left: 0;
    padding-right: 12px;
    margin-right: -12px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.tile-grid-colors {
  display: grid;
  grid-template-columns: repeat(12, minmax(64px, 1fr));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  overflow-x: scroll;
  overflow-y: hidden;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding-left: 0;
    padding-right: 12px;
    margin-right: -12px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.content-wrapper {
  max-height: 20rem;
}

.divider {
  margin: 0 -16px !important;

  @media (min-width: 640px) {
    margin: 0 -24px !important;
  }
}
</style>
