<template>
  <textarea v-model="internalValue" @keyup="onKeyUp"></textarea>
</template>
<script>
export default {
  props: {
    value: { type: String, default: "" },
    cols: { type: Number, default: 50 },
    rows: { type: Number, default: 2 },
    setValid: { type: Object },
  },
  mounted: function() {
      this.$emit('setValid', true);
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    onKeyUp(evt) {
      var arr = this.value.split("\n");

      if (arr.length > this.rows) {
        this.$emit('setValid', false);
        return;
      } else {
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].length > this.cols) {
            this.$emit('setValid', false);
            return;
          }
        }
      }
      this.$emit('setValid', true);
    },
  },
};
</script>

