import mitt, { Emitter } from "mitt";

export type InternalEvents = {
  purchase: void;
  toggleCanvaCta: void;
  editorMounted: void;
  newEditorPageMounted: void;
  imagesChanged: number;
  lecBannerVisibilityChanged: boolean;
  maintenanceModeBannerVisibilityChanged: boolean;
};

const emitter: Emitter<InternalEvents> = mitt<InternalEvents>();
export default emitter;
