<template>
  <div class="save-template-overlay">
    <div class="backdrop" v-on:click="close"></div>
    <div class="content">
      <button class="close-btn" v-on:click="close" :title="t.modal_close">
        <i class="fas fa-times"></i>
      </button>
      <p style="margin-bottom: 0;">
        <h2 class="text-center mb-2" style="font-size: 150%"><b>{{ t.save_as_design_preset }}</b></h2>

        <p class="text-center pl-3 pr-3" style="line-height: 1.5;">{{ t.save_to_my_templates}} <a :href="windows_mac_linux_url">{{ t.more_info }}</a></p>

        <div class="container mt-3" v-if="signed_in">
          <div class="row top-buffer">
            <div class="col-sm text-center">
              <img class="" :src="thumbnail_image" style="max-width: 50%">
            </div>
            <div class="col-sm top-buffer" style="min-height: 150px;">
              <form v-on:submit.prevent="saveTemplate">
                <span>{{ name }}</span>
                <input type="text" v-model="templateName" :maxlength="72" class="form-control mt-2">
                <button v-if="saveState == 'SAVE_STATE_IDLE'" class="btn btn-primary mb-1 mt-3" style="position: absolute; bottom: 0;" v-on:click="saveTemplate">
                  {{ t.save }}
                </button>
                <button v-if="saveState == 'SAVE_STATE_SAVING'" disabled class="btn btn-info mb-1 mt-3" style="position: absolute; bottom: 0;">
                  {{ t.saving }}
                </button>
                <div class="row mx-0" v-if="errorMessage">
                  <div class="my-1" style="width: 100%;">
                    <div class="template-alert alert-danger">
                      <small>{{ errorMessage }}</small>
                    </div>
                  </div>
                </div>
                <button v-if="saveState == 'SAVE_STATE_SAVED'" disabled class="btn btn-success mb-1 mt-3" style="position: absolute; bottom: 0;">
                  {{ t.saved }}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div v-else class="text-muted text-center">
          <hr/>
          <h3 style="font-size: 150%">{{ t.sign_up_for_free }}</h3>
          <button class="btn btn-primary mb-1" v-on:click="openSignUp">
            {{ t.sign_up }}
          </button>
          <hr/>

          <small>{{ t.already_have_an_account }} <a :href="login_url" >{{ t.log_in }}</a></small>
        </div>
      </p>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Rails from "@rails/ujs";

export default {
  props: {
    base64: String,
    t: Object,
    thumbnail_image: String,
    changedInputs: Object,
    signed_in: Boolean,
    login_url: String,
    signup_url: String,
    name: String,
    windows_mac_linux_url: String,
  },
  data() {
    return {
      templateName: this.t.my_settings + ` @ ${new Date().toLocaleString()}`,
      saveState: 'SAVE_STATE_IDLE',
      errorMessage: undefined
    };
  },
  computed: {
    href() {
      return encodeURI(window.location.href);
    }
  },
  methods: {
    saveTemplate() {
      this.errorMessage = undefined;

      if (!this.validateData()) return;

      this.saveState = 'SAVE_STATE_SAVING';

      $.ajax({
        method: "POST",
        url: this.t.save_template_url,
        headers: {
          "X-CSRF-TOKEN": Rails.csrfToken(),
        },
        data: {
          template_config: {
            name: this.templateName,
            input_values: JSON.stringify(this.changedInputs)
          }
        }
      }).then((response) => {
        this.saveState = 'SAVE_STATE_SAVED';
        location.href = response.location;
      }).catch((error) => {
        this.saveState = 'SAVE_STATE_IDLE';
        this.errorMessage = error.responseJSON.errors[0].detail;
      })
    },
    validateData() {
      if (this.templateName == "") {
        this.errorMessage = this.t.error_template_name_empty;
        return false;
      }
      return true;
    },
    openSignUp() {
      location.href = this.signup_url;
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style scoped>
.save-template-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19000;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}
.save-template-overlay .backdrop {
  background: rgba(0,0,0,0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 19020;
}
.save-template-overlay .content {
  background: #fff;
  position: relative;
  z-index: 19050;
  padding: 20px;
  margin: 25px;
  max-width: 750px;
  border-radius: 3px;
}
.close-btn {
  position: absolute;
  right: -20px;
  top: -20px;
  background: transparent;
  border: 1px solid #595959;
  color: #595959;
  font-size: 16px;
  width: 34px;
  height: 34px;
  background: #fff;
  padding: 8px;
  margin: 0;
  border-radius: 19px;
  line-height: 1;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.close-btn:hover, .close-btn:focus {
  background: #eee;
}

.top-buffer { margin-top:10px; }

</style>
