<template>
  <div>
    <div class="container">
      <div class="row align-items-center">
        <div class="pull-left ml-3 mr-2">
          <strong class="mr-1" style="font-size: 14px">{{ node.title_i18n }}</strong>
        </div>
        <div class="col text-right" style="padding-left: 0px">
          <span v-if="node.tempJson.settings.multiLine">
            <LimitedTextarea v-model="node.string_i18n" @input="setInput" @rows="node.tempJson.settings.maxLines" @cols="node.tempJson.settings.maxChars" @setValid="setValid" v-if="node.tempJson.settings.maxLines >= 0 && node.tempJson.settings.maxChars >= 0" style="width: 100%" class="form-control"></LimitedTextarea>
            <textarea v-model="node.string_i18n" v-else style="width: 100%" class="form-control"></textarea>

            <div class="pt-1" v-if="!valid" style="color: #ff7272">{{ t.too_much_text }}</div>
          </span>
          <input ref="inputElement" v-else @input="inputChanged" v-model="node.string_i18n" :maxlength="36" class="form-control" />
        </div>
      </div>
      <div class="row m-3 align-items-center" v-if="node.fontSelectionEnabled">
        <div class="col-12 no-x-padding">
          <strong class="mr-1" style="font-size: 14px">{{ node.t.font }}</strong>
        </div>
      </div>
      <div class="row m-3 align-items-center" v-if="node.fontList.length > 0 && node.fontSelectionEnabled">
        <div class="col-12 no-x-padding">
          <v-select label="font" :options="node.fontList" v-model="node.font"></v-select>
        </div>
      </div>
    </div>
    <div class="row m-3 align-items-center" v-if="node.fontList.length > 0 && node.fontSelectionEnabled">
      <div class="col-12 no-x-padding">
        <v-select label="font" :options="node.fontList" v-model="node.font"></v-select>
      </div>
    </div>
    <div class="row my-1 mx-3 text-center" v-if="progress">
      <div class="progress">
        <div class="progress-bar with-pulse" role="progressbar" v-bind:aria-valuenow="progress * 100" aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: `${progress * 100}%` }"></div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import LimitedTextarea from "./limited_textarea.vue";

export default {
  props: {
    node: Object,
    t: Object,
    value: String,
  },
  data: () => ({
    progress: null,
    valid: true,
  }),
  watch: {
    "node.font": function (val) {
      this.node.fontChange(null, val, true);
    },
  },
  methods: {
    inputChanged() {
      this.setInput(this.$refs.inputElement.value);
    },
    setInput(input) {
      this.node.stringChange(null, input, true);
    },
    setValid(valid) {
      this.valid = valid;
    },
    changeProgress(value) {
      this.progress = value;
    },
  },
  components: {
    vSelect,
    LimitedTextarea,
  },
};
</script>

<style scoped></style>
<style>
/* hide the x button on the font dropdown field because we always need a font set*/
.vs__clear {
  display: none;
}
</style>
