import { useEditorStore } from "@/stores/editor_store";
import { useCheckIOS } from "./check_if_ios";
import { Image } from "@/modules/internal_api/image";

// On iOS there is a limit for donwloading images which seems to be either resolution higher than 16MP
export const useCheckIfIOSImageTooLarge = (): boolean => {
  const store = useEditorStore();
  const IOS_MAX_DOWNLOAD_PIXELS = 16_777_216;
  const isIOS = useCheckIOS();
  const image: Image = store.selectedImage;
  const totalPixels = image.meta.hdHeight * image.meta.hdWidth;
  const isImageTooLarge = totalPixels > IOS_MAX_DOWNLOAD_PIXELS;

  return isIOS && isImageTooLarge;
};
