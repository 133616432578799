import User from "@/modules/user";

export function useCanvaLinkBuilder(flavor) {
  const base = flavor === "production" ? "https://canva.com/content-partner" : "https://canva-dev.com/content-partner";

  const canvaVariants = {
    A: "DEFAULT",
    B: "WITH_DOCTYPE",
    C: "WITH_DOCTYPE_AND_BRANDING",
    IA: "INLINE_EDITOR_DEFAULT",
    IAM: "INLINE_EDITOR_ANDROID_APP_DEFAULT",
  };

  const utmPrefix = "REMOVE_BG_TO_CANVA";

  const buildUrl = (image: string, variant: string | undefined, doctype: string | undefined = undefined) => {
    const url = new URL(base);

    url.searchParams.append("image-url", image);
    if (doctype) url.searchParams.append("type", doctype);
    if (variant) {
      const variantParam = getVariantParam(variant);
      url.searchParams.append("variant", variantParam);
      url.searchParams.append("utm_campaign", `${utmPrefix}_${variantParam}`);
      url.searchParams.append("utm_medium", "acquisitions");
      url.searchParams.append("utm_source", "removebg");
    }

    return url.toString();
  };

  const getVariantParam = (variant: string | undefined) => {
    if (!variant) {
      return canvaVariants.IA;
    }

    if (User.mobileApp() && variant.toUpperCase() === "IA") {
      return canvaVariants.IAM;
    }

    if (variant) {
      return canvaVariants[variant.toUpperCase()];
    }

    return canvaVariants.IA;
  };

  return {
    buildUrl,
  };
}
