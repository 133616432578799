<template>
  <BasicInput
    id="email"
    :model-value="email"
    @update:modelValue="updateEmail"
    class="grow"
    type="email"
    :label="inputLabel"
    :error="error"
    :rounded="true"
    input-classes="md:!pr-36"
    :onEnter="subscribe"
  ></BasicInput>
  <Button class="sm:absolute sm:right-1.5 sm:top-1.5" @click="subscribe" :loading="loading">{{
    I18n.t("newsletter.subscribe")
  }}</Button>
</template>

<script setup lang="ts">
import Rails from "@rails/ujs";
import "@/src/i18n";

import { ref, inject, onMounted } from "vue";
import { BasicInput, Button } from "prism";
import { hcaptcha_invisible } from "../../src/hcaptcha";

const I18n = inject("I18n");
const inputLabel = ref(I18n.t("newsletter.enter_your_email"));
const loading = ref(false);
const error = ref(undefined);
const csrfToken = ref("");
const email = ref("");

const props = defineProps({
  siteKey: String,
  subscribeUrl: String,
});

onMounted(() => {
  csrfToken.value = Rails.csrfToken();
});

const updateEmail = (value) => {
  email.value = value;
  inputLabel.value = I18n.t("newsletter.enter_your_email");
  error.value = undefined;
};

const subscribe = async (event) => {
  if (!isValidateEmail(email.value)) return;

  event.preventDefault();
  loading.value = true;

  try {
    const captcha = await hcaptcha_invisible({ sitekey: props.siteKey });
    await submitWithCaptcha(captcha);
  } catch (error) {
    loading.value = false;
    error.value = `Captcha failed: Please try again or contact team@remove.bg if the problem persists.`;
  }
};

const isValidateEmail = (email: string): boolean => {
  if (email === "") return false;

  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const valid = regex.test(email);
  if (!valid) {
    error.value = I18n.t("support.invalid_email");
  }

  return valid;
};

const submitWithCaptcha = async (captcha) => {
  const response = await fetch(props.subscribeUrl, {
    method: "POST",
    headers: {
      "X-CSRF-Token": csrfToken.value,
      "content-type": "application/json",
    },
    body: JSON.stringify({
      email: email.value,
      hcaptcha_response: captcha,
    }),
  });

  if (!response.ok) {
    await handleFailedRequest(response);
  } else {
    await handleSucessfulRequest(response);
  }

  loading.value = false;
};

const handleFailedRequest = async (response) => {
  const data = await response.json();
  const message = data["message"] || `Error: Please try again or contact team@remove.bg if the problem persists.`;

  error.value = message;
};

const handleSucessfulRequest = async (response) => {
  const data = await response.json();

  if (data.status === "ok") {
    error.value = undefined;
    email.value = "";
    alert(data.message);
  } else {
    // Don't display the error underneath the input,
    // reuse the label to show the error.
    error.value = "";
    inputLabel.value = data.message;
  }
};
</script>
