import "magnific-popup";
import "bootstrap-4/dist/js/bootstrap.bundle.js";

import Rails from "@rails/ujs";
if (!window._rails_loaded) {
  Rails.start();
}

import "@/src/i18n";
import "@/src/error_tracking";
import "@/src/auth";
import "@/src/vue_mount";
import "@/src/snowplow";
import "@/src/track";
import "@/src/split_metrics";
import "@/src/upload";
import "@/src/images";
import "@/src/pricing";
import "@/src/docs";
import "@/src/tooltips";
import "@/src/api";
import "@/src/mobile_app";
import "@/src/cancellation";
import "@/src/hcaptcha";
import "@/src/usage_chart";
import "@/src/dashboard";
import "@/src/blog";
import "@/src/leads";

import Cookie from "@/src/cookie.js";
window.Cookie = Cookie;

import $ from "jquery";
window.$ = window.jQuery = $;

import initVue from "@/src/vue_mount_prism.js";
initVue();

import { Application } from "stimulus";
import { registerControllers } from "stimulus-vite-helpers";

const application = Application.start();
const controllers = import.meta.globEager("@/controllers/*_controller.js");
registerControllers(application, controllers);

// globally enable tippy
import tippy from "tippy.js";
var tooltips = $(document).find(".hover-tooltip");
if (tooltips.length > 0) {
  tippy(tooltips.get(), { allowHTML: true, interactive: true });
}
