import $ from "jquery";

if (location.hash === "#api-key") {
  $("html,body").scrollTop(0);
}

$("body").on("click", "#api-docs-nav .nav-link", function(event) {
  event.preventDefault()
  const anchor = $(event.target).attr("href")

  history.replaceState({}, "", anchor)
  $("#docs").animate({
      scrollTop: $(anchor)[0].offsetTop
  }, 1000)
})

// window.addEventListener("load", (event) => {
// const observer = new IntersectionObserver(
//     (entries, observer) => {
//       // console.log(entries);
//       // console.log(observer);
//       entries.forEach((entry) => {
//         if (entry.isIntersecting) {
//           console.log("Hey, I'm intersecting", entry.target)
//         } else {
//           console.log("Hey, I'm NOT intersecting", entry.target)
//         }
//       });
//     },
//     {
//       root: document.getElementById("#docs"),
//       rootMargin: '0px',
//       threshold: 1.0
//     }
//   );

//   document.querySelectorAll("h2").forEach((h2) => {
//     observer.observe(h2);
//   });
// });


window.addEventListener("load", (event) => {
  $("body").on("activate.bs.scrollspy", "#docs", function (e) {
    const anchor =  $("#api-docs-nav .nav-link.active").attr("href")
    history.replaceState({}, "", anchor)
  });
});

// window.addEventListener("load", (event) => {
//   console.log("page is fully loaded");
// });
