import $ from "jquery";

$(() => {
  $("#download-usage-button").on('click', () => {
    let group = $('#group-usage-by').val();
    let daterange = $('input[data-target="chart.dateRange"]').val()
    let api_key = $('#api_key').val();
    let api_key_label = $('#api_key').find("option:selected").text();
    let from = daterange.split(' – ')[0]
    let to = daterange.split(' – ')[1]
    let button = $("#download-usage-button")
    let button_url = button.data('url')+ "?group="+ group + "&from=" + from + "&to=" + to;
    if(api_key !== "all") {
      button_url += "&api_key_id=" + api_key
      button_url += "&filename=" + api_key_label
    } else {
      button_url += "&filename=all"
    }
    button.attr('href', button_url);
  });
});
