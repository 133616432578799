import $ from "jquery";

const container = $("#dashboard");
if(container) {
  const url = document.location.toString();
  if (url.match('#')) {
    $('.nav-card-tabs a').removeClass("active");
    $('.nav-card-tabs a[href="#'+url.split('#')[1]+'"]').tab('show').addClass("active");
  }

  $('body').on('shown.bs.tab', '.nav-card-tabs a', function (e) {
    if(history.pushState) {
      history.pushState(null, null, e.target.hash);
    } else {
      window.location.hash = e.target.hash;
    }
  })
}