export type Point = { x: number; y: number };
export type Line = {
  action?: DrawAction,
  brushSize?: number,
  points: Point[];
}

export enum DrawAction {
  Erase = "erase",
  Restore = "restore",
}
