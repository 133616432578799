<template>
  <div class="prism text-typo-secondary" data-target="feedback.shareBar">
    <div class="flex flex-row gap-2">
      <span class="flex align-items-center text-typo-secondary pr-2"
        >{{ I18n.t("image.share_the_love", { love_icon_html: "" }) }} <LikeOffIcon class="pl-1" style="color: #ff3f30"
      /></span>
      <a
        class="text-typo-secondary p-3"
        :title="I18n.t('image.share')"
        :href="shareTo(SOCIAL_MEDIA.FACEBOOK)"
        target="_blank"
        ondragstart="return false;"
      >
        <FacebookIcon />
      </a>

      <a
        class="text-typo-secondary p-3"
        :title="I18n.t('image.tweet')"
        :href="shareTo(SOCIAL_MEDIA.TWITTER)"
        target="_blank"
        ondragstart="return false;"
      >
        <TwitterIcon />
      </a>
      <a
        class="text-typo-secondary p-3"
        :title="I18n.t('image.share')"
        :href="shareTo(SOCIAL_MEDIA.LINKEDIN)"
        target="_blank"
        ondragstart="return false;"
      >
        <LinkedinIcon />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { FacebookIcon, TwitterIcon, LikeOffIcon, LinkedinIcon } from "prism";

const I18n = inject("I18n");

const SOCIAL_MEDIA = {
  TWITTER: "twitter",
  LINKEDIN: "linkedin",
  INSTAGRAM: "instagram",
  FACEBOOK: "facebook",
};

const shareTo = (sociaMedia) => {
  switch (sociaMedia) {
    case SOCIAL_MEDIA.FACEBOOK: {
      const url = "https://remove.bg/?utm_source=facebook.com&utm_medium=referral&utm_campaign=share_on_social";
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    }
    case SOCIAL_MEDIA.TWITTER: {
      return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        I18n.t("image.tweet_message", { link: `${window.location.hostname}/start` }) + " "
      )}&via=remove_bg `;
    }
    case SOCIAL_MEDIA.LINKEDIN: {
      return `https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fremove.bg%2F%3Futm_source%3Dlinkedin.com%26utm_medium%3Dreferral%26utm_campaign%3Dshare_on_social&mini=true`;
    }
  }
};
</script>

<style scoped>
.p-3 {
  padding: 0.75rem !important;
}
</style>
