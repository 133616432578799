<template>
  <div :id="category" v-if="tools.length > 0" class="category-tools mb-4 px-3 px-md-0">
    <div class="d-flex justify-content-between align-items-center mb-4 mx-n3 mx-md-2">
      <h3 class="h4 mb-0 w-75" :class="{ h5: headerSize == 'small' }">{{ header }}</h3>
      <a v-if="paginate" :href="categoryUrl">See All <i class="ml-1 fas fa-chevron-right"></i></a>
    </div>

    <div ref="tools" class="row mx-n4 mx-md-n3">
      <tool v-for="tool in tools" :key="tool.id" :tool="tool" url="https://google.com" />
    </div>

    <nav v-if="totalPages > 1" class="d-flex justify-content-center align-items-center">
      <button type="button" class="d-inline-block btn btn-sm btn-outline-secondary" @click="jumpToFirstPage">
        <i class="fas fa-angle-double-left"></i>
      </button>
      <button type="button" class="d-inline-block btn btn-sm btn-outline-secondary mx-1" :disabled="disablePrevButton" @click="prevPage">
        <i class="fas fa-angle-left"></i>
      </button>
      <span class="mx-2 font-weight-bold">{{ page }} of {{ totalPages }}</span>
      <button type="button" class="d-inline-block btn btn-sm btn-outline-secondary mx-1" :disabled="disableNextButton" @click="nextPage">
        <i class="fas fa-angle-right"></i>
      </button>
      <button type="button" class="d-inline-block btn btn-sm btn-outline-secondary" @click="jumpToLastPage">
        <i class="fas fa-angle-double-right"></i>
      </button>
    </nav>
  </div>
</template>

<script>
import $ from "jquery";
import Rails from "@rails/ujs";
import Tool from "./tool.vue";

export default {
  props: {
    header: String,
    headerSize: String,
    category: String,
    categoryUrl: String,
    contentUrl: String,
    excludeTool: String,
    paginate: Boolean,
  },

  components: {
    Tool,
  },

  data() {
    return {
      state: "loading",
      page: 1,
      totalPages: 1,
      tools: [],
    };
  },

  mounted() {
    this.loadTools();
  },

  computed: {
    disableNextButton() {
      return this.page == this.totalPages;
    },

    disablePrevButton() {
      return this.page == 1;
    },
  },

  methods: {
    loadTools() {
      this.state = "loading";
      $.ajax({
        type: "GET",
        headers: {
          "X-CSRF-TOKEN": Rails.csrfToken(),
        },
        url: this.contentUrl,
        data: { page: this.page, exclude: this.excludeTool, paginate: this.paginate },
        success: (data) => {
          this.totalPages = data.meta.total_pages;
          this.tools = data.tools;
          this.state = "done";
          this.$nextTick(() => {
            this.setBoxHeight();
          });
        },
      });
    },

    nextPage() {
      if (this.page == this.totalPages) return;

      this.page += 1;
      this.loadTools();
      this.scrollToHeader();
    },

    prevPage() {
      if (this.page == 1) return;

      this.page -= 1;
      this.loadTools();
      this.scrollToHeader();
    },

    jumpToFirstPage() {
      this.page = 1;
      this.loadTools();
      this.scrollToHeader();
    },

    jumpToLastPage() {
      this.page = this.totalPages;
      this.loadTools();
      this.scrollToHeader();
    },

    scrollToHeader() {
      document.getElementById(this.category).scrollIntoView({ behavior: "smooth" });
    },

    setBoxHeight() {
      const tools = $(this.$refs.tools).children(".tool");
      const maxHeight = Math.max(...$.map(tools, (tool) => $(tool).height()));

      tools.height(maxHeight);
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  min-width: 31px;
}

.loading {
  min-height: 200px;
}

.category-tools {
  scroll-margin: 65px;
}
</style>
