import Configs from "@/modules/configs";

const maxSize = (size_in_mb = 12) => 1024 * 1024 * size_in_mb;

export enum UploadValidationError {
  INVALID_TYPE = "invalid_type",
  TOO_LARGE = "too_large",
  CORRUPTED = "corrupted",
};

export type UploadValidationResult = {
  valid: boolean;
  error?: UploadValidationError
}

export function useUploadValidation() {
  const validateFile = (file: File): UploadValidationResult => {
    if (file.type != "image/jpeg" && file.type != "image/png") {
      return {
        valid: false,
        error: UploadValidationError.INVALID_TYPE,
      };
    }

    if (file.size === 0) {
      return {
        valid: false,
        error: UploadValidationError.CORRUPTED,
      };
    }

    const max_file_size_limit_mb = Number(Configs.get("max_file_size_limit_mb"));
    if (file.size > maxSize(max_file_size_limit_mb)) {
      return {
        valid: false,
        error: UploadValidationError.TOO_LARGE,
      }
    }

    return {
      valid: true,
    };
  };

  return {
    validateFile,
  }
}
