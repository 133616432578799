<template>
  <Spacer class="overflow-x-clip">
    <Container>
      <Spacer size="sm"
        ><Heading align-center level="h2">{{ heading }}</Heading>
      </Spacer>
    </Container>

    <TabGroup>
      <TabList class="hide-scrollbars flex space-x-2 -my-2 py-2 justify-start md:justify-center overflow-x-scroll md:overflow-auto px-8 scroll-pl-8 md:scroll-p-0">
        <Tab v-for="element in elements" :key="element.title" v-slot="{ selected }" as="template">
          <TabButton :selected="selected">{{ element.title }}</TabButton>
        </Tab>
      </TabList>

      <Container>
        <TabPanels>
          <TabPanel v-for="(element, index) in elements" :key="element.title">
            <div class="relative group">
              <Spacer size="sm">
                <CurveSmall class="text-brand absolute hidden sm:block -right-16 -top-6 transition ease-in-out group-hover:rotate-[15deg] group-hover:-translate-x-2 transform-gpu" />
                <Dot class="absolute hidden sm:block -left-12 -bottom-4 transition ease-in-out group-hover:translate-x-8 group-hover:-translate-y-2 transform-gpu group-hover:scale-110" />
                <PlaceholderImageDragger :front-src="element.after" :back-src="element.before" :title="element.title" :lazy="index === 0"> </PlaceholderImageDragger>
              </Spacer>
            </div>
          </TabPanel>
        </TabPanels>
      </Container>
    </TabGroup>
    <div class="flex justify-center px-8 text-center">
      <Link :href="link.url" target="blank" class="undeline-none text-primary" size="lg" :draggable="false">{{ link.label }}</Link>
    </div>
  </Spacer>
  <Spacer size="sm"> </Spacer>
</template>

<script setup>
import { ref } from "vue";

import { Spacer, Container, Heading, TabButton, Dot, CurveSmall, Link } from "prism";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import PlaceholderImageDragger from "./placeholder_image_dragger.vue";

const props = defineProps({
  heading: String,
  elements: Object,
  link: Object,
});
</script>
