<template>
  <div>
    <div>
      <div class="row ml-3 mr-3 mt-3 mb-0 align-items-center">
        <div class="col-12 no-x-padding">
          <strong class="mr-1" style="font-size: 14px">{{ node.title_i18n }}</strong>
        </div>
      </div>
      <div class="container-fluid node-padding" style="flex: 1; py-3;">
        <div class="form-group text-center mb-0">
          <div class="d-none d-lg-block">
            <!-- desktop -->
            <vueper-slides ref="imageslides" slide-image-inside slide-multiple class="no-shadow" :visible-slides="4" :slide-ratio="1 / 4" :gap="1" :bullets="false" :touchable="false" :infinite="true" disable-arrows-on-edges>
              <template v-slot:arrow-left>
                <svg viewBox="0 0 9 18"><path stroke-linecap="round" d="m8 1 l-7 8 7 8"></path></svg>
              </template>
              <template v-slot:arrow-right>
                <svg viewBox="0 0 9 18"><path stroke-linecap="round" d="m1 1 l7 8 -7 8"></path></svg>
              </template>                
              <vueper-slide v-for="(slide, i) in design_slides" :key="i" :image="slide.thumbnail_url" :class="i == currentDesignId ? 'slide___active' : 'slide___inactive'" v-on:click.native="selectDesign(i)" v-on:click="selectDesign(i)"></vueper-slide>
            </vueper-slides>
          </div>
          <div class="d-lg-none">
            <!-- mobile -->
            <div class="template-image-list" data-ios-emulate-scroll="x">
              <button v-bind:key="i" class="template-item vueperslide__image" :class="{ slide___active: currentDesignId == i, slide___inactive: currentDesignId != i }" v-for="(slide, i) in design_slides" :style="{ 'background-image': `url('${slide.thumbnail_url}')` }" v-on:click="selectDesign(i)" style="background-size: contain; width: 76px; height: 76px; top: 2px"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-1 mx-3 text-center" v-if="progress">
        <div class="progress">
          <div class="progress-bar with-pulse" role="progressbar" v-bind:aria-valuenow="progress * 100" aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: `${progress * 100}%` }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  emits: ["click"], // vue.js 3 migration
  props: {
    node: Object,
    t: Object,
    value: String,
  },
  data: () => ({
    currentDesignId: -1,
    design_slides: [],
    progress: null,
  }),
  mounted() {
    // add data from graph to slides
    this.design_slides.push(...this.node.designList);
    this.currentDesignId = this.node.currentDesignId;
  },
  computed: {},
  methods: {
    changeProgress(value) {
      this.progress = value;
    },
    selectDesign(i = -1) {
      this.changeProgress(0.5);
      this.currentDesignId = i;
      this.node.useDesign(i, null);
      this.changeProgress(null);
    },
  },
  components: {
    VueperSlides,
    VueperSlide,
  },
};
</script>
<style scoped></style>
<style>
.vueperslide__image {
  background-color: transparent;
}

.slide___active {
  border: 2px solid #0f70e6;
  border-radius: 5px;
}

.slide___inactive {
  border: 2px solid #f9fafa;
  border-radius: 5px;
}

.slide___upload {
  background-color: rgb(99, 109, 118);
}

.template-image-list {
  padding: 0;
  margin: 0;
  margin-left: 0px;
  margin-right: 0px;
  display: -webkit-flex;
  display: flex;
  overflow: auto;
}
.template-image-list .template-item {
  margin: 0 8px 0 0;
  width: 80px;
  height: 80px;
  flex: 0 0 auto;
  position: relative;
  background-position: center;
  background-size: cover;
}
.template-image-list .template-item + .template-item {
  margin-left: 0;
}

@media (min-width: 992px) {
  .template-image-list {
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
  }
  .template-image-list .template-item {
    margin: 0 5px 10px 5px;
  }

  .template-image-list .template-item + .template-item {
    margin-left: 5px;
  }
}

.template-image-list {
  padding-bottom: 12px;
}

.vueperslides__arrow {
  color: white !important;
  padding-left: 0px;
  padding-right: 0px;
  opacity: 0.9 !important;
}
.vueperslides__arrow svg {
  stroke-width: 2 !important;
  width: 2em !important;
  padding: 0.5em !important;
}

.vueperslides__arrow--next {
  right: 0em !important;
}
.vueperslides__arrow--prev {
  left: 0em !important;
}

.vueperslide {
  cursor: pointer;
}

.background-hover {
  opacity: 0.8;
}

.background-hover:hover {
  opacity: 1;
}

.vueper-picker {
  background: #edeff0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(https://static.remove.bg/templates/colorwheel.svg);
  border-radius: 5px;
}

.vueperslide__title {
  font-size: 11px;
  position: relative;
  bottom: -5px;
}

.color-picker .backdrop {
  background: rgba(0, 0, 0, 1) !important;
}

@media (min-width: 992px) {
  .vueper-padding {
    padding: 8px;
  }
}

@media (max-width: 992px) {
  .mobile-justify-content-center {
    justify-content: center !important;
  }
  .vueper-padding {
    padding: 0px;
  }
}
</style>
