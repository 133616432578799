import { rbgLeadGeneratedV100, RbgLeadGeneratedEventV100 } from "kaleido-event-tracker";

const container = document.getElementById("ticket-created");

if (container) {
  const supportTicketId: number | null = (() => {
    const value = parseInt(container.getAttribute("data-support-ticket-id"));
    return isNaN(value) ? null : value;
  })();

  const leadId: string | null = container.getAttribute("data-lead-id");

  if (leadId || supportTicketId) {
    const data: RbgLeadGeneratedEventV100 = { lead_id: leadId, support_ticket_id: supportTicketId };
    rbgLeadGeneratedV100(data);
  }
}
