<template>
  <div id="swagger-docs"></div>
</template>

<script>
import SwaggerUI from "swagger-ui-dist";

export default {
  props: {
    url: String,
    apiKey: String,
  },

  data() {
    return {
      ui: null,
    };
  },

  mounted() {
    this.ui = SwaggerUI.SwaggerUIBundle({
      url: this.url,
      dom_id: "#swagger-docs",
      docExpansion: "full",
      deepLinking: false,
      defaultModelsExpandDepth: -1,
      onComplete: () => {
        if (this.apiKey) {
          this.ui.preauthorizeApiKey("APIKeyHeader", this.apiKey);
        }
      },
    });
  },
};
</script>

<style>
.renderedMarkdown ul {
  font-size: 14px;
}

.renderedMarkdown ul li {
  margin-bottom: 0;
}
</style>
