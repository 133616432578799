import $ from "jquery";
import { uploadBase64 } from './upload';

$(() => {
  if($("body.in-mobile-app").length > 0) {
    if(!window.mobile_app) window.mobile_app = {};
    window.mobile_app.upload = (filename, mimetype, base64) => {
      //console.log("invoked upload");
      uploadBase64(filename, mimetype, base64);
    };

    //  // implemented by client app
    //  shareUrl: (url) => {
    //    console.log("invoked shareUrl on stub");
    //  },
    //  shareBase64: (filename, base64) => {
    //    console.log("invoked shareBase64 on stub");
    //  },
    //  downloadUrl(filename, url) => {
    //    console.log("invoked downloadUrl on stub");
    //  }
    //  downloadBase64: (filename, base64) => {
    //    console.log("invoked downloadBase64 on stub");
    //  },
    //};
  }
});
