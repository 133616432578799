import {
  Image,
  Upload,
  UploadState,
  ImageMetaData,
  ResultData,
  ProcessingError,
  ProcessingState,
  UploadError,
} from "./image";
import Flipper from "@/modules/flipper";
import originalPlaceholder from "@assets/images/inline-editor/original_placeholder.jpg";

class ResponseParser {
  static parse(rawData: any): Image[] {
    const images: Image[] = rawData.data.map((entry: any) => {
      const image: Image = {
        meta: this.parseMetaData(entry),
        original: this.parseOriginalData(entry.original),
        previewResult: this.parseProcessingData(entry.preview_result),
        hdResult: this.parseProcessingData(entry.hd_result),
        magicBrushPreviewResult: this.parseProcessingData(entry.magic_brush_preview_result),
        magicBrushHdResult: this.parseProcessingData(entry.magic_brush_hd_result),
      };
      return image;
    });

    return images;
  }

  private static parseMetaData(entry: any): ImageMetaData {
    const meta = entry.meta;

    // Use a placeholder image for the original image if it is not yet uploaded
    let preview = originalPlaceholder;
    if (entry.original.state === UploadState.Finished) {
      preview = entry.original.url;
    }

    let hdWidth = meta.hd_width;
    let hdHeight = meta.hd_height;
    if (Flipper.isEnabled("supports_50mp") && meta["50_mp_width"] && meta["50_mp_height"]) {
      hdWidth = meta["50_mp_width"];
      hdHeight = meta["50_mp_height"];
    }

    const metaData: ImageMetaData = {
      id: meta.id,
      self: meta.self,
      preview: preview,
      livePreview: preview,
      foregroundType: meta.foreground_type,
      modelsVersion: meta.models_version,
      previewWidth: meta.preview_width,
      previewHeight: meta.preview_height,
      hdWidth: hdWidth,
      hdHeight: hdHeight,
      fullAvailable: meta.full_available,
      orientation: meta.orientation,
      ai_brush_processing_full_count: meta.ai_brush_processing_full_count,
      ai_brush_iterations: meta.ai_brush_iterations,
      ai_brush_last_iteration: meta.ai_brush_last_iteration,
      has_no_foreground: meta.has_no_foreground,
    };

    return metaData;
  }

  private static parseOriginalData(original: any): Upload | UploadError {
    if (original.state === UploadState.Error) {
      return {
        state: UploadState.Error,
        code: original.error_code,
        message: original.error_message,
      };
    }

    if (original.state === UploadState.Uploading) {
      return {
        state: UploadState.Uploading,
      };
    }

    const uploadData: Upload = {
      state: UploadState.Finished,
      url: original.url,
    };

    return uploadData;
  }

  private static parseProcessingData(processingData: any): ResultData | ProcessingError {
    if (processingData.state === ProcessingState.Error) {
      return this.parseErrorData(processingData);
    }

    const resultData: ResultData = {
      state: processingData.state,
      url: processingData.url,
      name: processingData.name,
      orderedAt: processingData.ordered_at,
      rated: processingData.rated,
      rating: processingData.rating,
      contributed: processingData.contributed,
      nextFetchIn: processingData.next_fetch_in,
    };

    return resultData;
  }

  private static parseErrorData(error: any): ProcessingError {
    const processingError: ProcessingError = {
      state: ProcessingState.Error,
      code: error.error_code,
      message: error.error_message,
    };

    return processingError;
  }
}

export default ResponseParser;
