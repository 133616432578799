<template>
  <div>
    <color-picker v-if="colorPickerActive" v-bind:value="value" v-on:close="colorPickerActive = false" v-on:change="setBackgroundColor($event.newValue)" style="z-index: 10000"></color-picker>
    <div v-if="backgroundImageNode && backgroundColorNode">
      <!-- Nav tabs -->
      <ul class="nav nav-tabs pt-0 pb-0 ml-2" id="myTab" role="tablist">
        <li class="nav-item" v-if="backgroundImageNode">
          <a class="nav-link active" id="background-image-tab" data-toggle="tab" href="#background-image" role="tab" aria-controls="home" aria-selected="true" style="font-size: 14px">{{ backgroundImageNode ? backgroundImageNode.title_i18n : "" }}</a>
        </li>
        <li class="nav-item" v-if="backgroundColorNode">
          <a class="nav-link" id="background-color-tab" data-toggle="tab" href="#background-color" role="tab" aria-controls="profile" aria-selected="false" style="font-size: 14px">{{ backgroundColorNode ? backgroundColorNode.title_i18n : "" }}</a>
        </li>
      </ul>
    </div>
    <div v-else>
      <div class="row m-3 align-items-center">
        <div class="col-12 no-x-padding">
          <strong class="mr-1" style="font-size: 14px">{{ backgroundImageNode ? backgroundImageNode.title_i18n : "" }}</strong>
        </div>
      </div>
    </div>
    <!-- Tab panes -->
    <!-- background images -->
    <div class="tab-content" v-if="backgroundImageNode">
      <div class="tab-pane active" id="background-image" role="tabpanel" aria-labelledby="background-image-tab">
        <div class="container-fluid node-padding" style="flex: 1; py-3;">
          <div class="form-group text-center mb-0">
            <div class="d-none d-lg-block">
              <!-- desktop -->
              <vueper-slides ref="imageslides" slide-image-inside slide-multiple class="no-shadow" :visible-slides="4" :slide-ratio="1 / 4" :gap="1" :bullets="false" :touchable="false" :infinite="true" disable-arrows-on-edges>
                <template v-slot:arrow-left>
                  <svg viewBox="0 0 9 18"><path stroke-linecap="round" d="m8 1 l-7 8 7 8"></path></svg>
                </template>
                <template v-slot:arrow-right>
                  <svg viewBox="0 0 9 18"><path stroke-linecap="round" d="m1 1 l7 8 -7 8"></path></svg>
                </template>                
                <vueper-slide v-if="backgroundImageNode.uploadEnabled" v-on:click.native="uploadBackground()" v-on:click="uploadBackground()">
                  <template v-slot:content>
                    <div class="vueperslide__content-wrapper">
                      <div class="background-preview" style="top: 2px; bottom: 2px; left: 1px; right: 1px; border-radius: 11px; width: auto; height: auto" :style="{ backgroundImage: 'url(' + backgroundBase64 + ')' }" v-if="backgroundBase64"></div>
                      <div class="background-preview background-hover" style="background-size: contain !important; overflow: hidden" :style="{ 'background-image': uploadButtonBackgroundImage }"></div>
                    </div>
                  </template>
                </vueper-slide>
                <vueper-slide v-for="(slide, i) in image_slides" :key="i" v-on:click.native="selectBackground(i)" v-on:click="selectBackground(i)" :image="slide.thumbnail_url" :class="i == activeBackground ? 'slide___active' : 'slide___inactive'"></vueper-slide>
              </vueper-slides>
            </div>
            <div class="d-lg-none">
              <!-- mobile -->
              <div class="template-image-list" data-ios-emulate-scroll="x">
                <button class="template-item vueperslide__image slide___inactive" v-if="backgroundImageNode.uploadEnabled" v-on:click.native="uploadBackground" v-on:click="uploadBackground">
                  <div class="background-preview" :style="{ backgroundImage: 'url(' + backgroundBase64 + ')' }" v-if="backgroundBase64" style="border-radius: 8px"></div>
                  <div class="background-preview" :style="{ 'background-image': uploadButtonBackgroundImage }"></div>
                </button>
                <button v-bind:key="slide.thumbnail_url" class="template-item vueperslide__image" :class="{ slide___active: activeBackground == i, slide___inactive: activeBackground != i }" v-for="(slide, i) in image_slides" :style="{ background: `url('${slide.thumbnail_url}')` }" v-on:click.native="selectBackground(i)" v-on:click="selectBackground(i)" style="width: 76px; height: 76px; top: 2px"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- background colors -->
      <div v-if="backgroundColorNode" class="tab-pane" id="background-color" role="tabpanel" aria-labelledby="background-color-tab">
        <div class="container-fluid node-padding" style="flex: 1; py-3;">
          <div class="form-group text-center mb-0">
            <div class="d-none d-lg-block mx-2">
              <vueper-slides ref="colorslides" slide-multiple class="no-shadow" :bullets="false" :visible-slides="4" :slide-ratio="1 / 4" :gap="1" :touchable="false" :infinite="true" disable-arrows-on-edges>
                <template v-slot:arrow-left>
                  <svg viewBox="0 0 9 18"><path stroke-linecap="round" d="m8 1 l-7 8 7 8"></path></svg>
                </template>
                <template v-slot:arrow-right>
                  <svg viewBox="0 0 9 18"><path stroke-linecap="round" d="m1 1 l7 8 -7 8"></path></svg>
                </template>                
                <vueper-slide v-on:click.native="colorPickerActive = true" v-on:click="colorPickerActive = true" :class="-1 == activeColor ? 'slide___active' : 'slide___inactive'">
                  <template v-slot:content>
                    <div class="vueperslide__content-wrapper vueper-picker"></div>
                  </template>
                </vueper-slide>
                <vueper-slide :class="i == activeColor ? 'slide___active' : 'slide___inactive'" v-for="(color, i) in color_slides" :key="i" v-on:click.native="selectColor(i)" v-on:click="selectColor(i)">
                  <template v-slot:content>
                    <div class="vueperslide__content-wrapper color_slide" :style="'background-color: ' + color + ';'">
                      <div class="vueperslide__title"></div>
                    </div>
                  </template>
                </vueper-slide>
              </vueper-slides>
            </div>
            <div class="d-lg-none">
              <div class="template-color-list" data-ios-emulate-scroll="x">
                <button class="template-item color_slide" :class="{ slide___active: activeColor == -1 }" v-on:click.native="colorPickerActive = true" v-on:click="colorPickerActive = true" style="background-color: #edeff0">
                  <div class="vueper-picker"></div>
                </button>
                <button :class="{ 'template-item color_slide': true, slide___active: activeColor == i }" :key="i" v-for="(color, i) in color_slides" :style="{ 'background-color': color }" v-on:click.native="selectColor(i)" v-on:click="selectColor(i)" data-hj-suppress></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-1 mx-3 text-center" v-if="progress">
        <div class="progress">
          <div class="progress-bar with-pulse" role="progressbar" v-bind:aria-valuenow="progress * 100" aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: `${progress * 100}%` }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ColorPicker from "./color_picker.vue";
import ColorList from "./color_list.vue";

import { pickFile } from "../src/pick_file";
import { ImageResizer } from "../src/remove-bg-tools";

import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  emits: ["click"], // vue.js 3 migration
  props: {
    node: Object,
    t: Object,
    value: String,
  },
  data: () => ({
    activeBackground: -1,
    activeColor: -1,
    backgroundBase64: null,
    image_slides: [], // the upload slide
    color_slides: [],
    colorPickerActive: false,
    backgroundColor: null,
    backgroundImageNode: null,
    backgroundColorNode: null,
    elseNode: null,
    progress: null,
  }),
  mounted() {
    // add data from graph to slides
    this.backgroundImageNode = this.node.graph.getNodeByName("BACKGROUND_IMAGE");
    this.elseNode = this.node.graph.getNodeByName("BACKGROUND_SELECTOR");
    if (this.backgroundImageNode) {
      this.activeBackground = this.backgroundImageNode.selectedImageId;
      this.backgroundBase64 = this.backgroundImageNode.url != this.backgroundImageNode.initialUrl ? this.backgroundImageNode.url : null;
      this.image_slides.push(...this.backgroundImageNode.imageList);
    }

    this.backgroundColorNode = this.node.graph.getNodeByName("BACKGROUND_COLOR");
    if (this.backgroundColorNode) {
      this.activeColor = this.backgroundColorNode.selectedColorId;
      this.backgroundColor = this.backgroundColorNode.hexColor;

      document.onreadystatechange = () => {
        // vueper-slides takes a while to load
        if (document.readyState == "complete") {
          if (this.activeColor > -1 || this.backgroundColor != "") {
            this.activateTab("background-color");
          }

          if (this.activeBackground > -1) {
            this.$refs.imageslides.goToSlide(this.activeBackground + 1, { animation: false });
          }
          if (this.activeColor > -1) {
            this.$refs.colorslides.goToSlide(this.activeColor + 1, { animation: false });
          }
        }
      };
      this.color_slides.push(...this.backgroundColorNode.colorList);
    }
  },
  computed: {
    uploadButtonBackgroundImage() {
      if (this.backgroundBase64) var url = "https://static.remove.bg/templates/change_file.png";
      else var url = "https://static.remove.bg/templates/select_file.png";
      var blah = 'url("' + url + '")';
      return 'url("' + url + '")';
    },
  },
  methods: {
    activateTab(tab) {
      $('.nav-tabs a[href="#' + tab + '"]').tab("show");
    },
    updateThumbnail(file, whichThumbnail) {
      let that = this;
      var imageResizer = new ImageResizer(file);
      imageResizer.reduce().then((returnObj) => {
        var fr = new FileReader();
        fr.onload = function () {
          that[whichThumbnail] = fr.result;
        };
        fr.readAsDataURL(returnObj.blob);
      });
    },
    changeProgress(value) {
      this.progress = value;
    },
    uploadBackground() {
      let that = this;
      pickFile().then((file) => {
        that.changeProgress("background", 0.15);
        that.activeBackground = -1;
        var reader = new FileReader();
        reader.addEventListener("progress", function (e) {
          if (e.lengthComputable && e.total > 0) {
            that.changeProgress((e.loaded / e.total) * 0.25 + 0.4);
          }
        });
        reader.addEventListener(
          "load",
          function () {
            // convert image file to base64 string
            that.reset();
            that.backgroundColorNode.reset();
            that.elseNode.aChange(1);
            that.backgroundImageNode.urlChange(null, reader.result);
            that.changeProgress(null);
          },
          false
        );

        // Read in the image file as a data URL.
        reader.readAsDataURL(file);

        this.updateThumbnail(file, "backgroundBase64");
      });
    },
    selectBackground(i = -1) {
      this.changeProgress(0.5);
      this.reset();
      this.activeBackground = i;
      this.backgroundColorNode.reset();
      this.elseNode.aChange(1);
      this.backgroundImageNode.selectedImageIdChange(null, i);
      this.changeProgress(null);
    },
    selectColor(i = -1) {
      this.changeProgress(0.5);
      this.reset();
      this.activeColor = i;
      this.backgroundImageNode.reset();
      this.elseNode.aChange(0);
      this.backgroundColorNode.selectedColorIdChange(null, i);
      this.changeProgress(null);
    },
    setBackgroundColor(color) {
      this.reset();
      this.backgroundColor = color;
      this.backgroundImageNode.reset();
      this.elseNode.aChange(0);
      this.backgroundColorNode.colorChange(null, color);
      this.changeProgress(null);
    },
    reset() {
      this.activeBackground = -1;
      this.activeColor = -1;
      this.backgroundBase64 = "";
      this.backgroundColor = "";
    },
  },
  components: {
    VueperSlides,
    VueperSlide,
    ColorPicker,
    ColorList,
  },
};
</script>
<style scoped>
/* very easy hover effect for buttons */
.background-hover {
  opacity: 0.8;
}

.background-hover:hover {
  opacity: 1;
}

.background-preview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
<style>
.vueperslide__image {
  background-color: transparent;
}

.color_slide {
  border-radius: 5px;
  border: 1px solid #dddfe1;
}

.slide___active {
  border: 2px solid #0f70e6;
  border-radius: 5px;
}

.slide___inactive {
  border: 2px solid #f9fafa;
  border-radius: 5px;
}

.slide___upload {
  background-color: rgb(99, 109, 118);
}

.template-image-list {
  padding: 0;
  margin: 0;
  margin-left: 0px;
  margin-right: 0px;
  display: -webkit-flex;
  display: flex;
  overflow: auto;
}
.template-image-list .template-item {
  margin: 0 8px;
  width: 80px;
  height: 80px;
  flex: 0 0 auto;
  position: relative;
  background-position: center;
  background-size: cover;
}
.template-image-list .template-item + .template-item {
  margin-left: 0;
}

@media (min-width: 992px) {
  .template-image-list {
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
  }
  .template-image-list .template-item {
    margin: 0 5px 10px 5px;
  }

  .template-image-list .template-item + .template-item {
    margin-left: 5px;
  }
}

.template-image-list {
  padding-bottom: 12px;
}

.template-color-list {
  padding: 0;
  margin: 0;
  margin-left: 0px;
  margin-right: 0px;
  display: -webkit-flex;
  display: flex;
  overflow: auto;
}
.template-color-list .template-item {
  margin: 0 8px;
  width: 80px;
  height: 80px;
  flex: 0 0 auto;
  outline: none;
  position: relative;
}
.template-color-list .template-item + .template-item {
  margin-left: 0;
}

@media (min-width: 992px) {
  .template-color-list {
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
  }
  .template-color-list .item {
    margin: 0 5px 10px 5px;
  }

  .template-color-list .template-item + .template-item {
    margin-left: 5px;
  }
}

.template-color-list {
  padding-bottom: 12px;
}

.vueperslides__arrow {
  color: white !important;
  padding-left: 0px;
  padding-right: 0px;
  opacity: 0.9 !important;
}
.vueperslides__arrow svg {
  stroke-width: 2 !important;
  width: 2em !important;
  padding: 0.5em !important;
}

.vueperslides__arrow--next {
  right: 0em !important;
}
.vueperslides__arrow--prev {
  left: 0em !important;
}

.vueperslide {
  cursor: pointer;
}

.background-hover {
  opacity: 0.8;
}

.background-hover:hover {
  opacity: 1;
}

.vueper-picker {
  background: #edeff0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(https://static.remove.bg/templates/colorwheel.svg);
  border-radius: 5px;
}

.vueperslide__title {
  font-size: 11px;
  position: relative;
  bottom: -5px;
}

.color-picker .backdrop {
  background: rgba(0, 0, 0, 1) !important;
}

@media (min-width: 992px) {
  .vueper-padding {
    padding: 8px;
  }
}

@media (max-width: 992px) {
  .mobile-justify-content-center {
    justify-content: center !important;
  }
  .vueper-padding {
    padding: 0px;
  }
}
</style>
