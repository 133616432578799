<template>
  <ImageDragger v-show="showImages" ref="dragger">
    <template #front>
      <Image :src="frontSrc" :on-load="imageLoaded" :lazy="false" alt="Example image" />
    </template>
    <template #back>
      <Image :src="backSrc" :on-load="imageLoaded" :lazy="false" alt="Example image" />
    </template>
  </ImageDragger>
  <div v-show="!showImages" class="w-full rounded-2xl md:rounded-4xl !bg-secondary animate-pulse aspect-video"></div>
</template>

<script setup>
import { ref } from "vue";
import { ImageDragger, Image } from "prism";

const imagesLoaded = ref(0);
const showImages = ref(false);
const dragger = ref();

const props = defineProps({
  title: String,
  frontSrc: String,
  backSrc: String,
  lazy: Boolean,
});

const imageLoaded = () => {
  imagesLoaded.value += 1;
  if (imagesLoaded.value < 2) return;

  if (!dragger.value) return;
  showImages.value = true;
};
</script>
