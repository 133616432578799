<template>
  <div class="container-fluid h-100">
    <div @change="checkoutStatusChange" id="paddle-checkout-event" class="d-none"></div>
    <div class="row" id="fullcheckout">
      <div class="col-12 col-md-8 p-4 p-md-2">
        <div class="row d-block d-md-none">
          <div class="col-6"><img :src="logo_url" class="img-fluid" /></div>
        </div>
        <div class="row text-center d-block d-md-none mt-4">
          <div class="col">
            <h6 v-if="checkout_for === 'product'">{{ t.credit_package }}</h6>
            <h6 v-if="checkout_for === 'plan'">{{ t.your_subscription }}</h6>
          </div>
        </div>
        <div class="row text-center d-block d-md-none">
          <div class="col">
            <div id="item-name">
              {{ item_name }}
              <a :href="changeProductLink()" class="text-muted underlined-link"
                ><small>{{ t.change }}</small></a
              >
            </div>
          </div>
        </div>
        <div class="row" style="background-color: white">
          <div class="col-lg-3 d-none d-lg-block"></div>
          <div class="col my-2 my-lg-5 mx-auto p-2 pb-5 p-md-5 p-lg-1">
            <div class="h3 border-light border-bottom py-2 d-none d-md-block">
              <span>{{ t.complete_your_purchase }}</span>
            </div>
            <div class="checkout-container" style="min-height: 500px"></div>
          </div>
          <div class="col-lg-3 d-none d-lg-block"></div>
        </div>
        <div class="row d-none d-md-block fixed-bottom" style="z-index: -1">
          <div class="col"></div>
          <div class="col-8 text-center">
            <p class="" style="color: lightgray; font-size: 115%">{{ t.good_company }}:</p>
            <p>
              <img v-for="company_logo in company_logos" :src="company_logo" class="img-fluid" style="max-width: 100px; display: inline" />
            </p>
          </div>
          <div class="col"></div>
        </div>
      </div>
      <div class="col-4 p-0 m-0 shadow-left d-none d-md-block" style="min-height: 100vh; background-color: #f7f9fa">
        <div class="checkout-overview p-5 mt-5">
          <div class="row mb-5">
            <div class="col-6 pl-0">
              <img :src="logo_url" class="img-fluid" />
            </div>
          </div>
          <div class="row mb-5">
            <div class="col p-0">
              <h5 v-if="checkout_for === 'product'">{{ t.credit_package }}</h5>
              <h5 v-if="checkout_for === 'plan'">{{ t.your_subscription }}</h5>
            </div>
            <div class="col-2 text-right p-0">
              <a :href="changeProductLink()" class="text-muted underlined-link"
                ><small>{{ t.change }}</small></a
              >
            </div>
          </div>
          <transition name="fade">
            <div v-if="updatingPrices" class="text-center">
              <i class="fa fa-spinner-third fa-2x fa-spin"></i>
            </div>
            <div v-else>
              <div class="row mb-2">
                <div class="col p-0">
                  <div>{{ item_name }}</div>
                </div>
                <div class="col text-right p-0">
                  <div class="currency d-inline"></div>
                  <div id="subtotal" class="d-inline">{{ this.priceNet }}</div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col p-0">{{ t.sales_tax }}</div>
                <div class="col text-right p-0">
                  <div class="currency d-inline"></div>
                  <div id="tax" class="d-inline">{{ this.priceTax }}</div>
                </div>
              </div>
              <div class="row text-bold py-2" style="border-bottom: 1px solid grey; border-top: 1px solid grey">
                <div class="col-8 m-0 p-0">
                  <div class="h5 m-0">{{ t.total }}</div>
                </div>
                <div class="col text-right p-0 m-0">
                  <div class="currency d-inline h5"></div>
                  <div class="h5 d-inline">{{ this.priceGross }}</div>
                </div>
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div v-if="showSyntheticPrices" class="row text-bold py-2">
              <div class="col-12 text-right p-0 m-0">
                <div class="d-inline">
                  {{ t.if_you_pay_via }}
                  <div class="h6 d-inline">{{ syntheticPaymentMethods }}</div>
                  {{ t.you_will_be_charged }}
                  <div class="h6 d-inline">{{ priceSyntheticGross }}</div>
                </div>
              </div>
            </div>
          </transition>
          <div v-if="checkout_for == 'plan'" class="row mt-5">
            <div class="hover-tooltip my-2 w-100 d-flex" :data-tippy-content="`<strong> ${t.full_refund_within_14_days} </strong> ${t.refund_terms}.`" tabindex="0">
              <i style="color: #93e3c0; font-size: 1.5rem" class="fa fa-check-circle mr-3"></i>
              <p class="mb-0">
                <strong class="mr-1">{{ t.risk_free }}:</strong><span class="dotted-ul">{{ t.money_back }}</span>
              </p>
            </div>
            <div class="hover-tooltip my-2 w-100 d-flex" :data-tippy-content="`<strong>${t.no_longterm_obligation}:</strong> ${t.all_cancelled_anytime}.`" tabindex="0">
              <i style="color: #93e3c0; font-size: 1.5rem" class="fa fa-check-circle mr-3"></i>
              <p class="mb-0">
                <strong class="mr-1">{{ t.flexible }}:</strong><span class="dotted-ul">{{ t.cancel_anytime }}</span>
              </p>
            </div>
            <div class="hover-tooltip my-2 w-100 d-flex" :data-tippy-content="`<strong>${t.roll_over_text}</strong> ${t.roll_over_terms}.`" tabindex="0">
              <i style="color: #93e3c0; font-size: 1.5rem" class="fa fa-check-circle mr-3"></i>
              <p class="mb-0">
                <strong class="mr-1">{{ t.fair }}:</strong><span class="dotted-ul">{{ t.roll_over }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav class="fixed-bottom mx-1">
      <transition name="slide">
        <div v-show="showMobileDetails" class="row p-4 mobile-detail-row" id="mobileCheckoutDetails" style="background-color: #f7f9fa; max-height: 200px">
          <div class="col">
            <div class="row mb-2 pb-2 border-light border-bottom">
              <div class="col-8 p-0">
                <div>{{ item_name }}</div>
              </div>
              <div class="col p-0 text-right">
                <div class="d-inline">{{ priceNet }}</div>
              </div>
            </div>
            <div class="row mb-2 pb-2 border-light border-bottom">
              <div class="col-8 p-0">{{ t.sales_tax }}</div>
              <div class="col p-0 text-right">
                <div class="d-inline">{{ priceTax }}</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div class="row d-md-none p-4 shadow-top mobile-detail-row" style="background-color: #f7f9fa">
        <div class="col-12">
          <div class="row">
            <div class="col-6 p-0 my-auto">
              <div class="h5 m-0">{{ t.total }}</div>
            </div>
            <div class="col-6 text-right p-0">
              <div id="total" class="h5 d-inline mr-3">{{ priceGross }}</div>
              <i @click="toggleMobileDetails" style="font-size: 1.5rem" class="mobile-checkout-toggle fa fa-angle-up"></i>
              <i @click="toggleMobileDetails" style="font-size: 1.5rem" class="mobile-checkout-toggle fa fa-angle-down d-none"></i>
            </div>
          </div>
          <transition name="fade">
            <div v-if="showSyntheticPrices" class="row py-2">
              <div class="col-12 text-right p-0 m-0 small">
                <div class="d-inline">
                  {{ t.if_you_pay_via }}
                  <div class="h6 d-inline small">{{ syntheticPaymentMethods }}</div>
                  <br />
                  {{ t.you_will_be_charged }}
                  <div class="h6 d-inline small">{{ priceSyntheticGross }}</div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <form style="display: none" action="/checkouts/complete" method="POST" id="purchase-completed-form">
        <input type="hidden" id="p_id" name="p_id" :value="p_id" />
        <input type="hidden" id="paddle_t_id" name="paddle_t_id" value="" />
      </form>
    </nav>
  </div>
</template>
<script>
import $ from "jquery";
import EventBus from "../src/event_bus";

export default {
  props: {
    logo_url: String,
    company_logos: Array,
    checkout_for: String,
    coupon: String,
    product: Object,
    plan: Object,
    item_name: String,
    p_id: String,
    user_id: String,
    user_email: String,
    locale: String,
    t: Object,
    checkout_override_url: String,
    experiment_name: String,
  },

  data() {
    return {
      countriesUnlocked: false,
      selectedCountry: "",
      selectedZip: "",
      showMobileDetails: false,
      priceGross: "",
      priceNet: "",
      priceTax: "",
      priceSyntheticGross: "",
      priceCurrency: "USD",
      paddleCheckoutEvent: "",
      paddleCheckoutPrices: Object,
      paddlePaymentMethods: Array,
      syntheticPaymentMethods: "",
      updatingPrices: false,
    };
  },
  mounted() {
    this.updatingPrices = true;
    this.initPaddle();
    this.openCheckout();
  },

  computed: {
    showSyntheticPrices: function () {
      return this.syntheticPaymentMethods !== "";
    },
    checkoutLoaded: function () {
      return this.paddleCheckoutEvent === "Checkout.Loaded";
    },
  },
  methods: {
    checkoutStatusChange() {
      this.paddleCheckoutEvent = document.getElementById("paddle-checkout-event").innerHTML;
      this.paddleCheckoutPrices = window.paddleCheckoutPrices;
      this.paddlePaymentMethods = window.paddleCheckoutPrices.available_payment_methods;
      this.updatePrices();
      this.updateSyntheticPaymentMethods();
      this.updatingPrices = false;
    },
    updatePrices() {
      var currencyLabels = document.querySelectorAll(".currency");
      var currencyName = window.paddleEventData.checkout.prices.customer.currency;
      var subtotal = window.paddleEventData.checkout.prices.customer.total - window.paddleEventData.checkout.prices.customer.total_tax;
      var tax = window.paddleEventData.checkout.prices.customer.total_tax;
      var total = window.paddleEventData.checkout.prices.customer.total;

      this.priceNet = this.localizedPrice(subtotal.toFixed(2), currencyName);
      this.priceTax = this.localizedPrice(tax, currencyName);
      this.priceGross = this.localizedPrice(total, currencyName);
    },
    showApplePay() {
      return !!window.ApplePaySession;
    },
    updateSyntheticPaymentMethods() {
      this.syntheticPaymentMethods = "";
      var methods = "";
      let _this = this;
      $.each(this.paddlePaymentMethods, function (key, value) {
        if (value.prices_type === "vendor") {
          var provider = _this.paymentProviderName(value.type);
          if (provider === "Apple Pay") {
            if (_this.showApplePay()) {
              methods += provider + ", ";
            }
          } else {
            methods += provider + ", ";
          }
        }
      });
      this.syntheticPaymentMethods = methods.slice(0, -2);
      this.priceSyntheticGross = this.localizedPrice(this.paddleCheckoutPrices.vendor.total, this.paddleCheckoutPrices.vendor.currency);
    },
    paymentProviderName(provider) {
      switch (provider) {
        case "apple-pay":
          return "Apple Pay";
        case "paypal":
          return "PayPal";
        default:
          return provider.charAt(0).toUpperCase() + provider.slice(1);
      }
    },

    initPaddle() {
      var paddleConfig = $("#paddle-config");
      var config = paddleConfig.data("config");

      if (config.env == "sandbox") {
        Paddle.Environment.set("sandbox");
        Paddle.Options({ debug: true });
      }

      Paddle.Setup({
        vendor: config.vendor,
        eventCallback: function (eventData) {
          window.paddleCallback(eventData);
        },
      });
    },
    openCheckout() {
      Paddle.Checkout.open({
        email: this.user_email,
        locale: this.locale,
        method: "inline",
        product: this.p_id,
        coupon: this.coupon,
        allowQuantity: false,
        passthrough: {
          user_id: this.user_id,
          experiment: this.experiment_name,
        },
        disableLogout: true,
        frameTarget: "checkout-container",
        frameInitialHeight: 450,
        frameStyle: "width:100%; min-width:312px; min-height: 500px; background-color: transparent; border: none;", // Please ensure the minimum width is kept at or above 286px with checkout padding disabled, or 312px with checkout padding enabled. See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
        override: this.checkout_override_url,
      });
    },

    changeProductLink() {
      return "/pricing/?p_id=" + this.p_id;
    },

    localizedPrice(price, currency = this.priceCurrency) {
      // This is done because if we are just using 'en' as the locale it will defaullt to
      // en-US which is not what we want because it will never use the long format 'US$' leading
      // to confusion for customers from other countries that also use the $ symbol
      // Intl.NumberFormat('en-GB', { style: 'currency', currency: 'USD'}).format(100) => 'US$100.00' (correct)
      // Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(100) => '$100.00' (wrong)
      const locale = this.locale === "en" ? "en-GB" : this.locale;

      var formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
      });
      return formatter.format(price);
    },
    toggleMobileDetails() {
      this.showMobileDetails = !this.showMobileDetails;
      document.querySelectorAll(".mobile-checkout-toggle").forEach((e) => {
        e.classList.toggle("d-none");
      });
      document.querySelectorAll(".mobile-detail-row").forEach((e) => {
        e.classList.toggle("shadow-top");
      });
    },
  },
};
</script>
<style scoped>
.shadow-left:before {
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  width: 15px;
  box-shadow: -10px 0 15px -15px inset;
  left: -15px;
}
.shadow-top {
  box-shadow: 0px 10px 7px 8px rgba(50, 50, 50, 0.75);
}
.underlined-link {
  text-decoration: underline;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  display: none;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.slide-enter-to,
.slide-leave {
  max-height: 0;
}
.slide-enter,
.slide-leave-to {
  max-height: inherit;
}

.dotted-ul {
  border-bottom: 1px dotted #000;
}
</style>
