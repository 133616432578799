<template>
  <div id="lecBanner" class="fixed w-full top-0 z-50">
    <Alert :dismissable="true" class="lec-alert rounded-none" :onClose="close">
      <span v-html="msg"></span>
    </Alert>
  </div>
</template>

<script setup lang="ts">
import "@/src/i18n";
import { inject, onMounted } from "vue";
import { Alert } from "prism";
import emitter from "@/modules/event_bus";
import Routes from "@/modules/routes";
import Rails from "@rails/ujs";

const I18n = inject("I18n");
const msg = I18n.t("lec_html", { link: Routes.get("tos_url") });

onMounted(() => {
  emitter.emit("lecBannerVisibilityChanged", true);
});

const close = async () => {
  emitter.emit("lecBannerVisibilityChanged", false);

  await fetch(Routes.get("session_preferences_url"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken(),
    },
    body: JSON.stringify({
      key: "lec_banner_dismissed",
      value: true,
    }),
  });
};
</script>

<style>
.lec-alert {
  background-color: black !important;
  color: white !important;
}

.lec-alert.rounded-none {
  border-radius: 0 !important;
}

.lec-alert a {
  color: white !important;
  font-weight: bold !important;
  text-decoration: none !important;
}

.lec-alert button {
  color: white !important;
}

.lec-alert .flex-none.w-6.h-6:first-of-type {
  display: none;
}
</style>
