class Flipper {
  static isEnabled(flag: string): boolean {
    const flags = document.querySelector('meta[name="feature-flags"]')

    try {
      const parsedFlags = JSON.parse(flags?.getAttribute("content") || "{}")
      return parsedFlags.hasOwnProperty(flag) ? parsedFlags[flag] : false
    } catch (_error) {
      return false
    }
  }
}

export default Flipper
