import { StoreDefinition, defineStore } from "pinia";
import type { Image } from "@/modules/internal_api/image";

import { DrawAction } from "@/stores/line";
import type { Line, Point } from "@/stores/line";
import {
  rbgEditorBackgroundsPanelOpenedV100,
  rbgEditorEffectsPanelOpenedV100,
  rbgEditorEraseRestorePanelOpenedV100,
} from "kaleido-event-tracker";

export enum DrawMode {
  Magic = "magic",
  Classic = "classic",
}

export enum BrushColor {
  Erase = "#DB1436",
  Restore = "#C1E7C2",
}

export enum ViewMode {
  Before = "before",
  After = "after",
}

export type MagicBrushCache = {
  formData: FormData;
};

export type DrawSettings = {
  isEnabled: boolean;
  action: DrawAction;
  mode: DrawMode;
  color: BrushColor;
  brushSize: number;
  magicBrushSize: number;
};

export type Panel = "fx" | "addBackground" | "eraseRestore" | null;

type UseNullStore = ReturnType<typeof defineStore>;
type NullStore = ReturnType<UseNullStore>;
export type EditorStore = ReturnType<typeof useEditorStore>;
export type EditorStoreSGA = Omit<EditorStore, keyof NullStore>;

interface State {
  images: Image[];
  selectedImageId: string | null;
  lines: Line[];
  drawSettings: DrawSettings;
  viewMode: ViewMode;
  isMagicBrushEnabled: boolean;
  magicBrushCache: MagicBrushCache | null;
  manualBrushCursorPosition: Point;
  magicBrushCursorPosition: Point;
  isSwitchingImages: boolean;
  isHoveringEditor: boolean;
  isAdjustingBrushSize: boolean;
  isUploadingCustomBackground: boolean;
  isApplyingBackgroundChanges: boolean;
  openedPanel: Panel;
}

export const useEditorStore = defineStore("editor", {
  state: (): State => ({
    images: [],
    selectedImageId: null,
    lines: [],
    drawSettings: {
      isEnabled: false,
      action: DrawAction.Erase,
      mode: DrawMode.Magic,
      color: BrushColor.Erase,
      brushSize: 10,
      magicBrushSize: 50,
    },
    isMagicBrushEnabled: true,
    magicBrushCache: null,
    viewMode: ViewMode.After,
    manualBrushCursorPosition: { x: 0, y: 0 },
    magicBrushCursorPosition: { x: 0, y: 0 },
    isSwitchingImages: false,
    isHoveringEditor: false,
    isAdjustingBrushSize: false,
    isUploadingCustomBackground: false,
    isApplyingBackgroundChanges: false,
    openedPanel: null,
  }),

  getters: {
    selectedImage(state: State): Image | null {
      if (state.selectedImageId) {
        return this.images.find((image: Image) => image.meta.id === state.selectedImageId);
      } else {
        return null;
      }
    },
    lastLine(state: State): Line | null {
      if (state.lines.length > 0) {
        return state.lines[state.lines.length - 1];
      } else {
        return null;
      }
    },
  },

  actions: {
    // Replace the entire list of images
    // This is used when first mounting the editor
    // Also selects the first image in the list
    setImages(images: Image[]): void {
      this.images = images;
      if (images.length > 0) {
        this.selectImage(images[0]);
      }
    },

    // Updates the selected image
    selectImage(image: Image): void {
      const index = this.images.findIndex((i: Image) => i.meta.id === image.meta.id);
      if (index < 0) return;

      this.selectedImageId = image.meta.id;
    },

    // Add an image to the beginning of the list
    // This is used when the user uploads a new image
    addImage(image: Image): void {
      this.images.unshift(image);
    },

    // Update an image in place, usually after polling for processing results
    updateImage(image: Image): void {
      const index = this.images.findIndex((i: Image) => i.meta.id === image.meta.id);

      this.images.splice(index, 1, image);
    },

    // Replace an image with another one
    // This is used when replacing a temporary/local image with a created record from the server
    replaceImage(oldImage: Image, newImage: Image): void {
      const index = this.images.findIndex((i: Image) => i.meta.id === oldImage.meta.id);

      // At this stage the original image is uploaded but has not yet been processed
      // so we do not now the dimensions of the uploade image, for preview reasons we did however
      // already determine the most likely preview resolution, so we can copy those over here
      const { previewWidth, previewHeight, preview, livePreview, width, height, sizeMb } = oldImage.meta;
      newImage.meta = { ...newImage.meta, previewWidth, previewHeight, preview, livePreview, width, height, sizeMb };

      this.images.splice(index, 1, newImage);
    },

    // Remove an image from the local store
    // Does not delete the image from the server (use Client.deleteImage for that)
    deleteImage(image: Image): void {
      const index = this.images.findIndex((i: Image) => i.meta.id === image.meta.id);
      const images = this.images.filter((i: Image) => i.meta.id !== image.meta.id);
      this.images = images;

      if (images.length === 0) {
        this.selectedImageId = null;
        return;
      }

      let newIndex = 0;
      if (index > 0) {
        newIndex = index - 1;
      }

      // If the deleted image was selected, select the first image in the list
      if (this.selectedImageId === image.meta.id) {
        this.selectImage(this.images[newIndex]);
      }
    },

    enterEraseMode(): void {
      this.clearLines();
      this.drawSettings.action = DrawAction.Erase;
      this.drawSettings.color = BrushColor.Erase;
    },

    enterRestoreMode(): void {
      this.clearLines();
      this.drawSettings.action = DrawAction.Restore;
      this.drawSettings.color = BrushColor.Restore;
    },

    startLine(point: Point): void {
      // A line consists of at least two points so we need to move the
      // starting point by one pixel to create a valid line
      const endPoint = { x: point.x + 1, y: point.y + 1 };
      this.lines.push({ points: [point, endPoint] });
    },

    // Add a point to the last/current line
    addLinePoint(point: Point): void {
      const lastLine = this.lastLine;
      if (lastLine) {
        lastLine.points.push(point);
      }
      this.lines.splice(this.lines.length - 1, 1, lastLine);
    },

    togglePanel(id: Panel) {
      this.openedPanel = this.openedPanel === id ? null : id;

      switch (this.openedPanel) {
        case "addBackground":
          rbgEditorBackgroundsPanelOpenedV100({ image_id: this.selectedImageId });
          return;
        case "eraseRestore":
          rbgEditorEraseRestorePanelOpenedV100({ image_id: this.selectedImageId });
          return;
        case "fx":
          rbgEditorEffectsPanelOpenedV100({ image_id: this.selectedImageId });
          return;
        default:
          return;
      }
    },

    clearLines(): void {
      this.lines = [];
    },

    closePanel(): void {
      this.openedPanel = null;
    },
  },
});
