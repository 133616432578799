import $ from "jquery";

export function getMetaJSON(name) {
  return JSON.parse($(`meta[name='${name}']`).attr("content"));
}

var uploaderTranslations = null;
export function getUploaderTranslations() {
  if(uploaderTranslations == null) uploaderTranslations = getMetaJSON('upload-page:t');
  return uploaderTranslations;
}
