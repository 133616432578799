<template>
  <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-16" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-200" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-16">
    <div v-show="isVisible" class="w-full sticky flex justify-center" style="bottom: 1rem">
      <Button :href="url" size="lg" :as="as"><slot></slot></Button>
    </div>
  </transition>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { Button } from "prism";

const props = defineProps({
  url: String,
  as: {
    type: String,
    default: "link"
  },
  showWhenLeaves: String,
  hideWhenEnters: String,
});

const leftViewport = ref(false);
const enteredViewport = ref(false);
const isVisible = computed(() => {
  return !leftViewport.value && enteredViewport.value;
});

const showObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      leftViewport.value = entry.isIntersecting;
    });
  },
  {
    rootMargin: "-72px",
    once: true,
  }
);

const hideObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      enteredViewport.value = !entry.isIntersecting;
    });
  },
  {
    once: true,
  }
);

onMounted(() => {
  showObserver.observe(document.getElementById(props.showWhenLeaves));
  hideObserver.observe(document.getElementById(props.hideWhenEnters));
});

onBeforeUnmount(() => {
  showObserver.disconnect();
  hideObserver.disconnect();
});
</script>
