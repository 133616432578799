<template>
  <a :href="tool.content.show_url" class="tool d-block col-6 col-md-3 mb-4 px-2 px-md-3">
    <div class="h-100 text-center position-relative">
      <span v-if="showBadge" class="badge badge-secondary badge-secondary-light text-uppercase position-absolute mx-auto">
        {{ tool.content.badge_text }}
      </span>
      <div class="h-100 p-3 pd-lg-4 border rounded-sm">
        <div :style="toolLogoStyle(tool.content.icon.filename)" class="mx-auto mb-3 mt-1"></div>
        <h4 class="mb-0 h5 text-dark font-family-sans">{{ tool.name }}</h4>
        <small v-for="(group, type) in groupedSources" :key="type" class="text-muted">
          {{ type }}
          {{ group.map((source) => source.name).join(" | ") }}
        </small>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    tool: Object,
  },

  computed: {
    showBadge() {
      return this.tool.content.badge_text.length > 0;
    },

    groupedSources() {
      return this.groupBy(this.tool.content.sources, "source_type_short");
    },
  },

  methods: {
    toolLogoStyle(url) {
      const cached_url = url.replace("//a.storyblok.com/f/67418/", "//sb.kaleidousercontent.com/67418/");

      return {
        backgroundImage: `url("${cached_url}")`,
        backgroundSize: "contain",
        borderRadius: "0.25rem",
        width: "75px",
        height: "75px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };
    },

    groupBy(array, key) {
      return array.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
  },
};
</script>

<style lang="scss" scoped>
.tool {
  // min-height: 230px;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.badge {
  left: 50%;
  transform: translateX(-50%);
  top: -9px;
}
</style>
