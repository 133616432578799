import semver from "semver";

class User {
  static signedIn(): Boolean {
    return this.getValue("signed_in", false);
  }

  static newEditorUrl(): String {
    return this.getValue("new_editor_url", "/");
  }

  static homeUrl(): String {
    return this.getValue("home_url", "/");
  }

  static preferencesUrl(): string {
    const values = this.parseValues();
    if (!values.hasOwnProperty("preferences_url")) return "/";

    return values["preferences_url"];
  }

  static mobileApp(): boolean {
    return this.getValue("mobile_app", false);
  }

  static mobileAppSupportsInlineEditor(): boolean {
    return this.getValue("mobile_app_supports_inline_editor", false);
  }

  private static getValue(key: string, defaultValue: any): any {
    const values = this.parseValues();
    if (!values.hasOwnProperty(key)) {
      return defaultValue;
    }
    return values[key];
  }

  static androidAppVersion(): string | null {
    const values = this.parseValues();
    if (!values.hasOwnProperty("android_app_version")) return null;

    return values["android_app_version"];
  }

  static isUsingIOs(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  static iOsVersion(): string | null {
    const regex = /OS (\d+)(_\d+)?/i;
    const match = navigator.userAgent.match(regex);
    if (match && match.length > 1) {
      return semver.coerce(match[1]);
    }

    return null;
  }

  static iOsVersionIsAtLeast(version: string): boolean {
    const iOsVersion = this.iOsVersion();
    if (!iOsVersion) return false;

    return semver.gte(iOsVersion, semver.coerce(version));
  }

  static isUsingTouchDevice(): boolean {
    return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  }

  private static parseValues(): Object {
    const values = document.querySelector('meta[name="user"]');
    try {
      const parsedValues = JSON.parse(values?.getAttribute("content") || "{}");
      return parsedValues;
    } catch (_error) {
      return {};
    }
  }
}

export default User;
