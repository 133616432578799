<template>
  <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.5 24C4.5 35.0457 13.4543 44 24.5 44C35.5457 44 44.5 35.0457 44.5 24C44.5 12.9543 35.5457 4 24.5 4C13.4543 4 4.5 12.9543 4.5 24ZM41.5 24C41.5 33.3888 33.8888 41 24.5 41C15.1112 41 7.5 33.3888 7.5 24C7.5 14.6112 15.1112 7 24.5 7C33.8888 7 41.5 14.6112 41.5 24ZM23.5342 31.0864C23.5431 31.078 23.5519 31.0694 23.5607 31.0607L33.5607 21.0607C34.1464 20.4749 34.1464 19.5251 33.5607 18.9393C32.9749 18.3536 32.0251 18.3536 31.4393 18.9393L22.5 27.8787L18.5607 23.9393C17.1464 22.5251 15.0251 24.6464 16.4393 26.0606L21.4315 31.0528C21.4341 31.0554 21.4367 31.058 21.4393 31.0607C21.4455 31.0668 21.4517 31.073 21.458 31.079C22.1503 31.7532 23.0061 31.5919 23.5342 31.0864Z"
      fill="currentColor"
    />
  </svg>
</template>
<script setup lang="ts"></script>
