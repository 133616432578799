import { computed, ComputedRef, inject, Ref, watch } from "vue";
import type { Point } from "@/stores/line";
import { useEditorStore } from "@/stores/editor_store";

import Konva from "konva";
import { PersistentStore } from "@/stores/persistent_store";
import { EditorStage } from "@/modules/editor/stage";

interface UseZoomableArgs {
  stage: Ref<Konva.Stage>;
  stageOriginalBg: Ref<Konva.Stage>;
  initialScale: Ref<number>;
  isProcessing: Ref<boolean> | ComputedRef<boolean>;
}

const DEFAULT_ZOOM_LEVEL = 100;
const MAX_ZOOM_LEVEL = 400;

/**
 * useZoomable is a composable that provides the zooming functionality to the canvas
 */
export function useZoomable(args: UseZoomableArgs): UseZoomableReturnType {
  let { stage, stageOriginalBg, initialScale, isProcessing } = args;
  const store = useEditorStore();
  const currentPersistentStore = inject("currentPersistentStore") as Ref<PersistentStore>;

  let editorStage = new EditorStage(stage.value, stageOriginalBg.value);
  watch([stage, stageOriginalBg], () => {
    editorStage = new EditorStage(stage.value, stageOriginalBg.value);
  });

  const canZoomIn = computed(() => {
    return currentPersistentStore.value?.zoomLevel < MAX_ZOOM_LEVEL && !isProcessing.value;
  });

  const canZoomOut = computed(() => {
    return currentPersistentStore.value?.zoomLevel > DEFAULT_ZOOM_LEVEL && !isProcessing.value;
  });

  // Note: This function could be a utility function instead of belonging to this composable. Move it, if needed.
  const clampPos = (pos: Point, stage: Konva.Stage): Point => {
    const theStage = stage.getStage();
    const canvasWidth = theStage.width();
    const canvasHeight = theStage.height();
    const scale = theStage.scaleX();
    const contentWidth = store.selectedImage.meta.previewWidth;
    const contentHeight = store.selectedImage.meta.previewHeight;

    const scaledWidth = contentWidth * scale;
    const scaledHeight = contentHeight * scale;

    const minX = Math.min(0, canvasWidth - scaledWidth);
    const maxX = scaledWidth > canvasWidth ? 0 : canvasWidth - scaledWidth;
    const minY = Math.min(0, canvasHeight - scaledHeight);
    const maxY = scaledHeight > canvasHeight ? 0 : canvasHeight - scaledHeight;

    pos.x = Math.max(Math.min(pos.x, maxX), minX);
    pos.y = Math.max(Math.min(pos.y, maxY), minY);

    return pos;
  };

  const repositionAfterCenterZoom = () => {
    const theStage = stage.value.getStage();
    const scale = theStage.scaleX();
    const stageWidth = theStage.width();
    const stageHeight = theStage.height();

    const imageWidth = store.selectedImage.meta.previewWidth;
    const imageHeight = store.selectedImage.meta.previewHeight;

    const newPos = {
      x: Math.floor((-imageWidth * scale) / 2 + stageWidth / 2),
      y: Math.floor((-imageHeight * scale) / 2 + stageHeight / 2),
    };

    if (currentPersistentStore.value?.zoomLevel === DEFAULT_ZOOM_LEVEL) {
      newPos.x = 0;
      newPos.y = 0;
    }

    editorStage.updateStages({ position: newPos });
  };

  const repositionAfterFocusPointZoom = (focusPoint: Point, pointerPosition: Point, opts?: ZoomOpts) => {
    if (!focusPoint || !pointerPosition) return;

    const theStage = stage.value.getStage();
    const scale = theStage.scaleX();

    const pos = {
      x: -(focusPoint.x - pointerPosition.x / scale) * scale,
      y: -(focusPoint.y - pointerPosition.y / scale) * scale,
    };

    if (currentPersistentStore.value?.zoomLevel === DEFAULT_ZOOM_LEVEL) {
      pos.x = 0;
      pos.y = 0;
    }

    const newPos = clampPos(pos, stage.value);

    if (opts?.animate) {
      new Konva.Tween({
        node: theStage,
        duration: 0.5, // duration in seconds
        x: newPos.x,
        y: newPos.y,
        easing: Konva.Easings.EaseInOut,
        onFinish: () => {
          editorStage.updateStages({ position: newPos });
        },
      }).play();
    } else {
      editorStage.updateStages({ position: newPos });
    }
  };

  const applyZoom = (callback?: () => void) => {
    const baseScale = initialScale.value;
    let zoomLevel = currentPersistentStore.value?.zoomLevel;
    let newScale = -1;

    if (currentPersistentStore.value?.defaultZoomLevel < 0) {
      // This means it's the initial zoom ie is triggered for the first time
      // In this case we need to set the zoom level that matches the base level.
      // From the next time onwards it'll hold the zoom value changed by user.
      zoomLevel = baseScale * 100;
      currentPersistentStore.value?.setDefaultZoom(100);
    }

    const increment = zoomLevel - 100; // (110 - 100 = 10);     (130 - 100 = 30)
    newScale = baseScale + increment / 100; // eg: 0.5 + 10/100 = 0.6  or 0.9 + (-10/100) = 0.8
    newScale = Math.round((newScale + Number.EPSILON) * 1000) / 1000;
    editorStage.updateStages({ scale: newScale });

    callback?.();
  };

  /**
   * zoom is a function that zooms the editor.
   * If the selectedImage has the last_pointer_position and last_focus_point, this function will zoom based on this focal point
   * If they are missing, it'll just zoom from the center
   */
  const zoom = (opts?: ZoomOpts) => {
    // Zoom from the center if we do have last pointer position and focus point
    if (!store.selectedImage?.meta?.last_pointer_position || !store.selectedImage?.meta?.last_focus_point) {
      repositionAfterCenterZoom();
      return;
    }

    const focusPoint = store.selectedImage?.meta?.last_focus_point;
    const pointerPosition = store.selectedImage?.meta?.last_pointer_position;

    repositionAfterFocusPointZoom(focusPoint, pointerPosition, opts);
  };

  /**
   * zoomOut zooms out the given stage. If a zoom factor is provided, it zooms out with the provided factor.
   *   If not, the default zoom factor is 10.
   *   The callback is invoked after the zoom is applied.
   * @param args
   * @example
   *    zoomOut({ zoomFactor: 15, callback: () => {} })
   */
  const zoomOut = (args?: ZoomArgs) => {
    const { zoomFactor = 10, callback = () => {} } = args || {};
    if (canZoomOut.value) {
      currentPersistentStore.value?.zoomOut(zoomFactor);
      applyZoom(callback);
      zoom();
    }
  };

  /**
   * zoomIn zooms in the given stage. If a zoom factor is provided, it zooms out with the provided factor.
   *   If not, the default zoom factor is 10.
   *   The callback is invoked after the zoom is applied.
   * @param args
   * @example
   *    zoomIn({ zoomFactor: 15, callback: () => {} })
   */
  const zoomIn = (args?: ZoomArgs) => {
    const { zoomFactor = 10, callback = () => {} } = args || {};
    if (canZoomIn.value) {
      currentPersistentStore.value?.zoomIn(zoomFactor);
      applyZoom(callback);
      zoom();
    }
  };

  /**
   * resetZoom resets the zoom to the default level and repositions the image from the center
   */
  const resetZoom = () => {
    setZoom(DEFAULT_ZOOM_LEVEL);
    applyZoom();
    repositionAfterCenterZoom();
  };

  const setZoom = (value: number): void => currentPersistentStore.value?.setZoom(value);

  const setDefaultZoomLevel = () => setZoom(DEFAULT_ZOOM_LEVEL);

  return {
    zoom,
    zoomIn,
    zoomOut,
    applyZoom,
    resetZoom,
    canZoomOut,
    canZoomIn,
    repositionAfterFocusPointZoom,
    repositionAfterCenterZoom,
    setDefaultZoomLevel,
    setZoom,
    clampPos,
  };
}

interface ZoomArgs {
  zoomFactor?: number;
  callback?: () => void;
}

interface ZoomOpts {
  animate?: boolean;
}

export interface UseZoomableReturnType {
  zoom: (opts?: ZoomOpts) => void;
  zoomIn: (args?: ZoomArgs) => void;
  zoomOut: (args?: ZoomArgs) => void;
  applyZoom: (callback?: () => void) => void;
  resetZoom: () => void;
  canZoomOut: ComputedRef<boolean>;
  canZoomIn: ComputedRef<boolean>;
  repositionAfterFocusPointZoom: (focusPoint: Point, pointerPosition: Point, opts?: ZoomOpts) => void;
  repositionAfterCenterZoom: () => void;
  setDefaultZoomLevel: () => void;
  setZoom: (value: number) => void;
  clampPos: (pos: Point, stage: Konva.Stage) => Point;
}
