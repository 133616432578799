import { computed } from "vue";

import { useEditorStore } from "@/stores/editor_store";
import Flipper from "@/modules/flipper";

const THRESHOLD_SCALE = 1.0;
const MAXIMUM_SCALE = 2;
let DEFAULT_CANVAS_SIZE = {
  width: 614,
  height: 406,
};
const SCREEN_LG = 992;

/**
 * useResizableCanvas is a composable that is responsible to provide methods that are related to resizing of the canvas
 *   based on the available space on the screen.
 */
export function useResizableCanvas() {
  const store = useEditorStore();
  const image = computed(() => store.selectedImage);

  const isScaledCanvasEnabled = Flipper.isEnabled("enable_scaled_canvas");

  // { [image_id]: <scale_value> }
  const scaleMap: Record<string, number> = {};

  const isDesktopScreen = () => {
    const navEl = document.getElementById("mobile-navbar");
    return navEl?.clientWidth > SCREEN_LG;
  };

  /**
   * isCanvasExtendable calculates the available space on the page
   *   and check if the canvas can be extended to a bigger size.
   */
  const isCanvasExtendable = (): boolean => {
    const scale = getScale();

    return scale > THRESHOLD_SCALE && scale <= MAXIMUM_SCALE;
  };

  /**
   * isCanvasShrinkable calculates the available space on the page
   *   and check if the canvas should be shrinked to a smaller size.
   *   For eg: if the height/width of the preview image > screen height/width; Esp. in mobile screens
   */
  const isCanvasShrinkable = (): boolean => getScale() < 1;

  /**
   * getScale calculates the scale that the image can be scaled to
   *   as per the available space on the screen.
   * @param force Defaults to false. When true, will force re-calculate the value and does not the value from cache
   */
  const getScale = (force = false): number => {
    if (!force && scaleMap[image.value?.meta?.id]) return scaleMap[image.value?.meta?.id];

    const headerEl = document.getElementById("navbar");

    if (!isDesktopScreen()) return scaleForSmallScreen();

    const navHeight = headerEl.clientHeight;

    // The footer element and side tools element cannot be found sometimes leading to errors in calculation of the scale.
    // So, I'm adding hard coded estimated values that more/less represents these element dimension.
    // Not sure, why these elements could not be found, maybe there are not mounted yet.
    const footerHeight = document.getElementById("footer")?.clientHeight || 100;
    const sideMenuWidth = document.getElementById("side-panel")?.clientWidth || 328;

    // Calculated as: we will need to have two cols/rows of width/height of 96px each.
    //  Considering some breathing space in the UI.
    const horizontalSpacing = 96 * 2;
    const verticalSpacing = 96 * 2;

    const totalPageHeight = document.getElementById("page")?.clientHeight || 0;
    const previewImageHeight = image.value?.meta?.previewHeight || DEFAULT_CANVAS_SIZE.height;

    const heightScalingScale = (totalPageHeight - navHeight - footerHeight - verticalSpacing) / previewImageHeight;
    const screenWidth = headerEl.clientWidth;
    const previewImageWidth = image.value?.meta?.previewWidth || DEFAULT_CANVAS_SIZE.width;

    const widthScalingScale = (screenWidth - sideMenuWidth - horizontalSpacing) / previewImageWidth;

    let scale = Math.min(heightScalingScale, widthScalingScale);
    scale = Math.round((scale + Number.EPSILON) * 1000) / 1000;

    if (!isScaledCanvasEnabled && scale > 1) {
      scale = 1;
    } else if (isScaledCanvasEnabled && scale > MAXIMUM_SCALE) {
      scale = MAXIMUM_SCALE;
    }

    scaleMap[image.value?.meta?.id] = scale;

    return scale;
  };

  /**
   * scaleForSmallScreen calculates the scale that the image can be scaled to for small screen
   */
  const scaleForSmallScreen = () => {
    const navbarHeight = document.getElementById("navbar")?.clientHeight || 0;
    const mobileNavbarEl = document.getElementById("new-header");
    const mobileNavbarHeight = mobileNavbarEl?.clientHeight || 0;
    const footerHeight = document.getElementById("footer")?.clientHeight || 0;
    const sideMenuWidth = document.getElementById("side-panel")?.clientWidth || 0;
    const mobileDrawerSpaceHeight = document.getElementById("mobileDrawerSpace")?.clientHeight || 0;
    const horizontalGap = sideMenuWidth > 0 ? 20 : 0;

    const windowSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const bottomBar = document.getElementById("mobile-buttons-wrapper");
    const bottomBarSize = {
      width: bottomBar?.clientWidth || 0,
      height: bottomBar?.clientHeight || 0,
    };

    // Average size of the gap between the elements
    const gap = 20;

    const maxEditorHeight = Math.min(
      windowSize.height -
        Math.max(navbarHeight, mobileNavbarHeight) -
        footerHeight -
        bottomBarSize.height -
        9 * gap -
        (store.openedPanel !== null ? mobileDrawerSpaceHeight / 2 : 0),
      image.value?.meta?.previewHeight
    );

    const maxEditorWidth = Math.min(
      windowSize.width - 28 - sideMenuWidth - horizontalGap,
      image.value?.meta?.previewWidth
    );

    const heightScale = maxEditorHeight / image.value?.meta?.previewHeight;
    const widthScale = maxEditorWidth / image.value?.meta?.previewWidth;
    const scale = Math.round((Math.min(heightScale, widthScale) + Number.EPSILON) * 1000) / 1000;
    scaleMap[image.value?.meta?.id] = scale;

    return scale;
  };

  /**
   * This function calculates the new dimensions of the selected image after scaling the image.
   * The scale is calculated by `getScale()`
   */
  const resizedCanvasDimensions = () => {
    let resizedHeight = store.selectedImage?.meta?.previewHeight;
    let resizedWidth = store.selectedImage?.meta?.previewWidth;
    const scale = getScale();

    if (isCanvasExtendable() || isCanvasShrinkable()) {
      resizedHeight = resizedHeight * scale;
      resizedWidth = resizedWidth * scale;
    }

    return {
      height: resizedHeight,
      width: resizedWidth,
      scale: scale,
    };
  };

  return {
    isCanvasExtendable,
    isCanvasShrinkable,
    resizedCanvasDimensions,
    getScale,
  };
}
