import $ from "jquery";
import Rails from "@rails/ujs";

window.reportSplit = (experiment, goal = null, reset = true ) => {
  var data = {
    exp: experiment,
    reset: reset,
  };

  if(goal) data.goal = goal;

  $.ajax({
    type: "POST",
    url: "/split_metrics",
    headers: {
      "X-CSRF-TOKEN": Rails.csrfToken(),
    },
    data: data,
  })
}
