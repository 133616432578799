<template>
  <div class="mt-12 md:mt-32 bg-pattern-brand bg-repeat-x">
    <p-spacer>
      <p-container>
        <p-heading align-center level="h2">{{ heading }}</p-heading>
      </p-container>
      <p-spacer size="sm">
        <p-scrolling-container :wide="true" class="hide-scrollbars">
          <p-card v-for="testimonial in testimonials" class="flex-shrink-0 w-5/6 md:w-auto md:flex-shrink-1 !p-4 md:!p-8 flex flex-col justify-between">
            <div>
              <p-image :src="testimonial.company_image" :alt="testimonial.company" class="w-36 h-16 !mb-8 flex items-center" />
              <p class="lg:text-xl font-bold text-typo">“{{ testimonial.text }}”</p>
            </div>
            <div>
              <p-image :src="testimonial.image" :alt="testimonial.name" class="w-20 !mt-8" />
              <div class="flex flex-col !mt-1">
                <span class="font-bold text-typo-secondary">{{ testimonial.name }}</span>
                <span class="text-typo-secondary">{{ testimonial.role }}</span>
              </div>
            </div>
          </p-card>
        </p-scrolling-container>
      </p-spacer>
      <div class="flex flex-col items-center">
        <p-link :href="moreLink" :target="moreLinkTarget" class="undeline-none" size="lg">{{ I18n.t("enterprise.read_success_stories") }}</p-link>
      </div>
    </p-spacer>
  </div>
</template>

<script setup>
import "@/src/i18n";
import { ref, inject } from "vue";

const I18n = inject("I18n");

const props = defineProps({
  testimonials: Array,
  heading: String,
  moreLink: String,
  moreLinkTarget: {
    type: String,
    default: "_blank"
  },
});
</script>

<style scoped>
.hide-scrollbars {
  scrollbar-width: none;
}

.hide-scrollbars::-webkit-scrollbar {
  display: none;
}
</style>
