<template>
  <div class="flex align-items-center">
    <template v-if="isRated && rating < 0">
      {{ I18n.t("image.thank_you_for_rating") }}
    </template>
    <ShareSociaMedia v-else-if="isRated && rating === 1" />
    <template v-else>
      {{ I18n.t("image.rate_this_result") }}
      <IconButton
        class="ml-3"
        variant="secondary"
        :disabled="isRated"
        :title="I18n.t('image.rate_good')"
        @click="ratePositive"
        ><ThumbsUpIcon
      /></IconButton>

      <template v-if="needsDialog()">
        <QIPDialog :image="props.image">
          <template #trigger>
            <IconButton variant="secondary" :title="I18n.t('image.rate_bad')"><ThumbsDownIcon /></IconButton>
          </template>
        </QIPDialog>
      </template>
      <template v-else>
        <IconButton variant="secondary" :disabled="isRated" :title="I18n.t('image.rate_bad')" @click="rateNegative"
          ><ThumbsDownIcon
        /></IconButton>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import "@/src/i18n";
import { IconButton, ThumbsUpIcon, ThumbsDownIcon } from "prism";
import improvedecision from "@/src/improvedecision";
import { onMounted, ref, watch, inject, computed } from "vue";
import Routes from "@/modules/routes";
import { Image, ProcessingState } from "@/modules/internal_api/image";
import Client from "@/modules/internal_api/client";
import { rbgRateBadV100, rbgRateGoodV100 } from "kaleido-event-tracker";
import { useEditorStore } from "@/stores/editor_store";

import QIPDialog from "./qip_dialog.vue";
import ShareSociaMedia from "@/components/share_social_media.vue";

const I18n = inject("I18n");

interface RatingProps {
  image: Image;
}

const props = defineProps<RatingProps>();

const consented = ref<Boolean>(false);
const hasConsentError = ref<Boolean>(false);

const isRated = ref<Boolean>(false);
const rating = ref<Number>(0);
const store = useEditorStore();

onMounted(() => {
  updateConsentedValue();
  refresh();
});

watch(
  () => props.image,
  () => refresh()
);
watch(
  () => props.image.previewResult?.rated,
  () => refresh()
);

const refresh = () => {
  if (props.image.previewResult.state === ProcessingState.Finished) {
    isRated.value = props.image.previewResult.rated;
    rating.value = props.image.previewResult.rating;
  } else {
    isRated.value = false;
    rating.value = 0;
  }
};

const updateConsentedValue = () => {
  const decision = improvedecision.get();

  if (decision === undefined) {
    consented.value = false;
  } else if (decision === "accept") {
    consented.value = true;
  } else if (decision === "reject") {
    consented.value = false;
  }
};

const needsDialog = () => {
  const decision = improvedecision.get();
  return decision !== "accept";
};

const ratePositive = () => {
  window.track("Images", "rate_good", "Rate good");
  rbgRateGoodV100({ image_id: props.image.meta.id });
  rate(1);
};

const rateNegative = () => {
  window.track("Images", "rate_bad", "Rate bad");
  rbgRateBadV100({ image_id: props.image.meta.id });
  rate(-1);
};

const rate = (newRating: number) => {
  Client.rateImage(props.image, newRating, consented.value);
  isRated.value = true;
  rating.value = newRating;

  const image = props.image;

  if (image.previewResult.state == ProcessingState.Finished) {
    image.previewResult.rated = isRated.value as boolean;
    image.previewResult.rating = rating.value as number;
    store.updateImage(image);
  }
};

const ipc_url = computed(() => {
  return `<a href="${Routes.get("ipc_url")}" target="_blank">${I18n.t("improve.ipc")}</a>`;
});
</script>

<style>
.prism .dialog-wrapper {
  max-width: 480px !important;
  padding: 24px !important;
}
</style>
