import $ from "jquery";
import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "../stores/plugins/plugin-persisted-state";
import VueKonva from "vue-konva";
import UploadPage from "../components/upload/page.vue";

import { getUploaderTranslations } from "./get_meta";

var uploader = null;

function initUploader(el) {
  const app = createApp(UploadPage);
  const pinia = createPinia();
  pinia.use(piniaPluginPersistedstate);
  app.use(pinia);
  app.use(VueKonva);
  app.provide("I18n", window.I18n);
  uploader = app.mount(el);
}

window.addEventListener("popstate", (event) => {
  location.reload();
});

export function showUploadPage() {
  if (!uploader) {
    var t = getUploaderTranslations();
    window.history.pushState({}, t.title, t.new_editor_url);
    $("#page-content").html("<div class='prism'><div class='upload-page-wrapper'></div></div>");
    initUploader($(".upload-page-wrapper")[0]);
  }
}

export function uploadFile(file) {
  showUploadPage();
  uploader.filePicked(file);
}

export function uploadBase64(filename, mimetype, base64) {
  showUploadPage();
  uploader.filePickedBase64(filename, mimetype, base64);
}

export function uploadUrl(url) {
  showUploadPage();
  uploader.urlPicked(url);
}

export function uploadExample(example) {
  showUploadPage();
  uploader.examplePicked(example);
}

$(() => {
  var uploader = $(".upload-page-wrapper");
  if (uploader.length == 0) return;
  initUploader(uploader[0]);

  var url = new URL(window.location.href);
  window.history.replaceState({}, "", url.href);
});
