import $ from "jquery";

var paddleConfig = $("#paddle-config");

if (paddleConfig.length > 0) {
  if (typeof window.Paddle == "object") {
    var config = paddleConfig.data("config");

    if(config.env == "sandbox") {
      Paddle.Environment.set('sandbox');
      Paddle.Options({debug:true})
    }

    window.paddleCallback = function paddleCallback(data) {
      if (data.event === "Checkout.Complete") {
        var transIdField = document.getElementById("paddle_t_id")
        var completeForm = document.getElementById("purchase-completed-form");
        transIdField.value = data.eventData.checkout.id;
        completeForm.submit();
      }
      var eventField = document.getElementById("paddle-checkout-event");
      eventField.innerHTML = data.event;
      window.paddleEventData = data.eventData;
      window.paddleCheckoutPrices = data.eventData.checkout.prices;
      eventField.dispatchEvent(new Event("change"));
    };
  }
}

