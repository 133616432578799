<template>
  <Dialog
    v-show="hasNoForeground"
    ref="dialog"
    :dismissable="false"
    trigger-classes="w-full flex justify-center sm:justify-start focus:outline-none"
    dialog-classes="dialog-wrapper"
  >
    <template v-slot:content="{ closeDialog }">
      <div class="flex flex-col gap-y-6">
        <p class="mb-0" v-html="I18n.t('improve.try_magic_brush_or_submit_image')" />
        <div class="sm:flex sm:flex-row-reverse sm:gap-x-3 space-y-3 sm:space-y-0 grow">
          <Button
            class="mb-2 focus-visible:!outline-none active:!outline-none primary-btn w-full md:w-auto"
            type="button"
            variant="primary"
            :on-click="() => tryMagicBrush(closeDialog)"
            >{{ I18n.t("inline_editor.try_magic_brush") }}</Button
          >
          <Button
            class="!bg-transparent hover:!bg-transparent active:!bg-transparent text-primary hover:!text-primary-hover text-base w-full md:w-auto"
            variant="secondary"
            type="reset"
            :on-click="() => closeMissingForegroundDialog(closeDialog)"
            >{{ I18n.t("editor.dismiss") }}</Button
          >
        </div>
      </div>

      <QIPDialog
        ref="qipDialog"
        id="no-fg-qip-dialog"
        trigger-class="hidden"
        :image="store.selectedImage"
        @on-close="
          () => {
            onQIPDialogCancel(closeDialog);
          }
        "
        @on-submit="
          () => {
            onQIPDialogSubmit(closeDialog);
          }
        "
      >
        <template #content>
          <p class="mb-0" v-html="I18n.t('improve.qip_agree_to_x')" />
        </template>
      </QIPDialog>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, inject, onMounted, computed, onUpdated, watch } from "vue";
import improvedecision from "@/src/improvedecision";
import QIPDialog from "../prism/qip_dialog.vue";
import type { I18n } from "i18n-js";

import { Dialog, Button } from "prism";
import { useEditorStore } from "@/stores/editor_store";

const I18n: I18n = inject("I18n");

const store = useEditorStore();
const dialog = ref(null);

interface MissingForegroundDialogProps {
  currentPersistentStore: any;
}

const props = defineProps<MissingForegroundDialogProps>();

const qipDialog = ref(null);

onMounted(() => {
  openDialog();
  addEventListeners();
});

const hasNoForeground = computed(() => store.selectedImage?.meta?.has_no_foreground === true);

onUpdated(() => {
  openDialog();
});

const addEventListeners = () => {
  const el = document.getElementById("no-fg-qip");
  if (el) {
    el.addEventListener("click", function (e) {
      handleSubmitImage(e);
    });
  }
};

const handleSubmitImage = async (e) => {
  e.preventDefault();
  qipDialog.value?.showDialog();

  if (improvedecision.get() === "accept") {
    await qipDialog.value?.showThankyouDialog();

    closeMissingForegroundDialog(dialog.value?.closeDialog);
  }
};

const openDialog = () => {
  if (shouldOpenDialog()) {
    dialog.value?.showDialog();
  }
};

const shouldOpenDialog = (): boolean => {
  return store.selectedImage.meta.has_no_foreground && !props.currentPersistentStore?.noForegroundCtaActionTaken;
};

watch(
  () => store.selectedImage?.meta?.id,
  () => {
    if (!store.selectedImage) return;
    if (shouldOpenDialog()) return;

    dialog.value?.closeDialog();
  }
);

const closeMissingForegroundDialog = (closeDialog: Function) => {
  props.currentPersistentStore.setNoForegroundCtaActionTaken(true);

  closeDialog();
};

const tryMagicBrush = (closeDialog: Function) => {
  store.openedPanel = "eraseRestore";
  store.drawSettings.isEnabled = true;
  closeMissingForegroundDialog(closeDialog);
};

const onQIPDialogCancel = (closeDialog: Function) => {
  closeMissingForegroundDialog(closeDialog);
};

const onQIPDialogSubmit = (closeDialog: Function) => {
  closeMissingForegroundDialog(closeDialog);
};
</script>

<style>
.prism .dialog-wrapper {
  max-width: 480px !important;
  padding: 24px !important;
}
</style>
