<template>
  <div class="prism">
    <Spacer>
      <Container>
        <div class="flex flex-col items-center gap-8 sm:gap-16">
          <div class="text-center">
            <Heading align-center level="h2">{{ I18n.t("upload_widget.heading") }}</Heading>
            <div class="!mt-2">{{ I18n.t("upload_widget.text_mobile") }}</div>
          </div>
          <div class="relative group flex flex-col gap-8 max-w-md">
            <div class="w-full flex flex-col sm:justify-center sm:items-center sm:gap-8 sm:pt-36 sm:pb-16 rounded-4xl bg-white shadow-2xl">
              <Button size="lg" @click="uploadFile">{{ I18n.t("upload_widget.upload_image") }}</Button>
              <div class="hidden sm:flex flex-col gap-1.5">
                <p class="m-0 font-bold text-xl text-typo-secondary">{{ I18n.t("upload_widget.or_drop_a_file") }},</p>
                <span class="text-xs text-typo-secondary text-center">
                  {{ I18n.t("upload_widget.paste_text_start").toLowerCase() }}
                  <a href="#" class="text-typo-secondary select-photo-url-btn underline" @click.prevent="uploadUrl(event)">{{ I18n.t("upload_widget.paste_text_url") }}</a>
                  {{ I18n.t("upload_widget.paste_text_end") }}
                </span>
              </div>
            </div>

            <div class="max-w-md">
              <div class="flex flex-col gap-2 sm:flex-row justify-between items-center">
                <div class="flex flex-row sm:!flex-col sm:justify-center">
                  <span class="font-bold text-typo-secondary !mr-1 sm:!mr-0">{{ I18n.t("upload_widget.no_image") }}</span>
                  <span class="font-bold text-typo-secondary">{{ I18n.t("upload_widget.try_one_of_these") }}</span>
                </div>
                <div class="flex gap-2">
                  <ImageButton v-for="image in exampleImages" @click.prevent="exampleSelected(image)" :key="image.thumbnail_url" ondragstart="return false;">
                    <Image :src="image.thumbnail_url" alt="Example image"></Image>
                  </ImageButton>
                </div>
              </div>
              <p v-html="uploadTerms" class="text-2xs text-typo-secondary text-center sm:!text-left !mt-4"></p>
            </div>

            <Curve class="text-brand absolute hidden sm:block top-1/3 -left-2/3 transition ease-in-out rotate-45 group-hover:rotate-[42deg] group-hover:translate-x-2 transform-gpu" />
            <Star class="text-brand absolute hidden sm:block -right-1/4 top-0 transition ease-in-out group-hover:rotate-45 group-hover:-translate-x-4 transform-gpu" />
          </div>
        </div>
      </Container>
    </Spacer>
  </div>
</template>

<script setup>
import "@/src/i18n";
import { ref, inject, onMounted } from "vue";
import { Spacer, Container, Heading, Button, Curve, Star, ImageButton, Image } from "prism";
import { useUploaderSamples } from "../../composables/uploader_samples";

const I18n = inject("I18n");
const exampleImages = ref([]);
const uploadTerms = ref("");

const base = "https://static.remove.bg/uploader-samples/";
const { availableImages } = useUploaderSamples();

onMounted(() => {
  availableImages.forEach((element) => {
    const n = Math.floor(Math.random() * element.length);
    exampleImages.value.push(element[n]);
  });

  // TODO Improve this
  const metaElement = document.querySelector("meta[name='upload-page:t']");
  const metaContent = JSON.parse(metaElement.getAttribute("content"));
  uploadTerms.value = metaContent.upload_terms_prism;
});

const exampleSelected = (image) => {
  window.onExampleSelected(image);
  scrollToTop();
};

const uploadFile = () => {
  window.onUploadFile();
  scrollToTop();
};

const uploadUrl = (url) => {
  window.onUploadUrl(url);
  scrollToTop();
};

const scrollToTop = () => {
  window.scrollTo({ top: 0 });
};
</script>
