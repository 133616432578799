<template>
  <label :class="classes" for="RangeSlider">
    <span v-if="props.label" class="text-sm text-typo shrink-0">{{ props.label }}</span>
    <span class="relative flex w-full">
      <input
        v-model="sliderValue"
        class="form-range appearance-none w-full h-1 rounded-full p-0 !bg-secondary focus:outline-none focus:ring-0 focus:shadow-none"
        name="RangeSlider"
        type="range"
        :step="props.step"
        :min="props.min"
        :max="props.max"
        :disabled="props.disabled"
        @mousedown="props.onMousedown"
        @mouseup="props.onMouseup"
        @touchstart="props.onTouchstart"
        @touchend="props.onTouchend"
      />
      <!-- Progress bar -->
      <div
        class="absolute left-0 pointer-events-none rounded-full h-1"
        :class="barClasses"
        :style="{
          width: sliderValue * (100 / props.max) + '%',
        }"
      ></div>
    </span>
  </label>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";

export interface RangeSliderProps {
  value?: number;
  label?: string;
  inlineLabel?: boolean;
  disabled?: boolean;
  step?: number;
  min?: number;
  max?: number;
  onMousedown?: (e: MouseEvent) => void;
  onMouseup?: (e: MouseEvent) => void;
  onTouchstart?: (e: MouseEvent) => void;
  onTouchend?: (e: MouseEvent) => void;
}

const props = withDefaults(defineProps<RangeSliderProps>(), {
  value: 0,
  label: undefined,
  inlineLabel: false,
  disabled: false,
  min: 0,
  max: 100,
  step: 1,
  onMousedown: () => {
    // noop
  },
  onMouseup: () => {
    // noop
  },
  onTouchstart: () => {
    // noop
  },
  onTouchend: () => {
    // noop
  },
});

const sliderValue = ref(props.value);
const emit = defineEmits<{
  (e: "sliderValueChanged", sliderValue: number): void;
}>();

watch(sliderValue, (sliderValue) => {
  emit("sliderValueChanged", sliderValue);
});

watch(
  () => props.value,
  (value) => {
    if (sliderValue.value !== props.value) sliderValue.value = value;
  }
);

const classes = computed(() => ({
  "prism-rangeslider": !props.disabled,
  "prism-rangeslider-disabled": props.disabled,
  "w-full flex gap-4": true,
  "flex-col": !props.inlineLabel,
  "flex-row items-center justify-center align-middle": props.inlineLabel,
}));

const barClasses = computed(() => ({
  "!bg-brand-typo ": !props.disabled,
  "!bg-secondary-hover": props.disabled,
}));
</script>

<style>
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-radius: theme("borderRadius.full") !important;
  background: theme("colors.brand.typo.DEFAULT") !important;
  border: 0px solid theme("colors.brand.typo.DEFAULT") !important;
  cursor: grab !important;
}
</style>
