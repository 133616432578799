import JSZip from "jszip";
import type { Image } from "@/modules/internal_api/image";
import { rbgImageComposedV200, rbgImageUnpackedV200 } from "kaleido-event-tracker";

const userAgent = navigator.userAgent.toLowerCase();
const isUCBrowser = /ucbrowser/.test(userAgent);

export type ExportOptions = {
  mimeType?: string;
  quality?: number;
};

export type ExportResult = {
  blob?: Blob;
  colorBytes?: Uint8Array;
  alphaBlob?: Blob;
  alphaBytes?: Uint8Array;
};

export class ZipCanvas {
  canvas: HTMLCanvasElement | null = null;
  colorBytes: Uint8Array | null = null;
  alphaCanvas: HTMLCanvasElement | null = null;
  alphaBytes: Uint8Array | null = null;
  disposed: boolean = false;

  constructor(
    canvas: HTMLCanvasElement,
    colorBytes: Uint8Array,
    alphaCanvas: HTMLCanvasElement,
    alphaBytes: Uint8Array
  ) {
    this.canvas = canvas;
    this.colorBytes = colorBytes;
    this.alphaCanvas = alphaCanvas;
    this.alphaBytes = alphaBytes;
  }

  export = async (options: ExportOptions = {}): Promise<ExportResult> => {
    if (this.disposed) {
      throw new Error("Canvases already disposed");
    }

    if (!this.canvas || !this.alphaCanvas) {
      return { blob: null, colorBytes: null, alphaBlob: null, alphaBytes: null };
    }

    const mimeType = options.mimeType || "image/png";
    const quality = options.quality || 1.0;
    const parsedOptions: ExportOptions = { mimeType, quality };

    if (this.canvas.hasOwnProperty("msToBlob")) {
      return this.msBlobExport(parsedOptions);
    }

    if (isUCBrowser) {
      return this.ucExport(parsedOptions);
    }

    return this.modernExport(parsedOptions);
  };

  private modernExport = async ({ mimeType, quality }: ExportOptions): Promise<ExportResult> => {
    const blob = await new Promise<Blob>((resolve) => {
      this.canvas.toBlob(
        (blob) => {
          resolve(blob!);
        },
        mimeType,
        quality
      );
    });

    const alphaBlob = await new Promise<Blob>((resolve) => {
      this.alphaCanvas.toBlob(
        (blob) => {
          resolve(blob!);
        },
        mimeType,
        quality
      );
    });

    return { blob, colorBytes: this.colorBytes, alphaBlob, alphaBytes: this.alphaBytes };
  };

  private msBlobExport = async (_options: ExportOptions): Promise<ExportResult> => {
    // msToblob does not support any options
    const blob: Blob = this.canvas.msToBlob();
    const alphaBlob: Blob = this.alphaCanvas.msToBlob();
    return Promise.resolve({ blob, colorBytes: this.colorBytes, alphaBlob, alphaBytes: this.alphaBytes });
  };

  private ucExport = async ({ mimeType, quality }: ExportOptions): Promise<ExportResult> => {
    const colorDataUrl = this.canvas.toDataURL(mimeType, quality);
    const alphaDataUrl = this.alphaCanvas.toDataURL(mimeType, quality);

    const colorResponse = await fetch(colorDataUrl);
    const alphaResponse = await fetch(alphaDataUrl);

    const blob = await colorResponse.blob();
    const alphaBlob = await alphaResponse.blob();

    return { blob, colorBytes: this.colorBytes, alphaBlob, alphaBytes: this.alphaBytes };
  };

  dispose = (): void => {
    ZipCanvas.releaseCanvas(this.canvas);
    ZipCanvas.releaseCanvas(this.alphaCanvas);
    this.colorBytes = null;
    this.alphaBytes = null;
    this.disposed = true;
  };

  static async fromBinary(image: Image, data: ArrayBuffer): Promise<ZipCanvas[]> {
    const zip: JSZip = await JSZip.loadAsync(data);

    const exportStart = performance.now();

    const colorBlob: Blob = await zip.file("color.jpg").async("blob");
    const colorBytes: Uint8Array = await zip.file("color.jpg").async("uint8array");

    const alphaBlob: Blob = await zip.file("alpha.png").async("blob");
    const alphaBytes: Uint8Array = await zip.file("alpha.png").async("uint8array");

    const shadowColorBlob: Blob = await zip.file("shadow_color.jpg")?.async("blob");
    const shadowAlphaBlob: Blob = await zip.file("shadow_alpha.png")?.async("blob");

    const semiTransparencyColorBlob: Blob = await zip.file("semi_transparency_color.jpg")?.async("blob");
    const semiTransparencyAlphaBlob: Blob = await zip.file("semi_transparency_alpha.png")?.async("blob");

    const exportEnd = performance.now();
    rbgImageUnpackedV200({ image_id: image.meta.id, procesing_time_ms: exportEnd - exportStart });

    const blobs = [
      [colorBlob, alphaBlob],
      [shadowColorBlob, shadowAlphaBlob],
      [semiTransparencyColorBlob, semiTransparencyAlphaBlob],
    ];

    const zipCanvases: ZipCanvas[] = [];

    const compositionStart = performance.now();
    for (const [colorBlob, alphaBlob] of blobs) {
      if (!colorBlob || !alphaBlob) {
        const zipCanvas = new ZipCanvas(null, null, null, null);
        zipCanvases.push(zipCanvas);
        continue;
      }

      const colorImage = await createImageBitmap(colorBlob);
      const alphaImage = await createImageBitmap(alphaBlob);

      const colorCanvas = ZipCanvas.createCanvas(colorImage);
      const alphaCanvas = ZipCanvas.createCanvas(alphaImage);

      const colorContext = colorCanvas.getContext("2d");
      const alphaContext = alphaCanvas.getContext("2d");

      const colorPixels = colorContext.getImageData(0, 0, colorCanvas.width, colorCanvas.height);
      const alphaPixels = alphaContext.getImageData(0, 0, alphaCanvas.width, alphaCanvas.height);

      for (let i = 0; i < colorPixels.data.length; i += 4) {
        colorPixels.data[i + 3] = alphaPixels.data[i];
      }
      colorContext.putImageData(colorPixels, 0, 0);

      const zipCanvas = new ZipCanvas(colorCanvas, colorBytes, alphaCanvas, alphaBytes);
      zipCanvases.push(zipCanvas);
    }
    const compositionEnd = performance.now();
    rbgImageComposedV200({ image_id: image.meta.id, procesing_time_ms: compositionEnd - compositionStart });

    return zipCanvases;
  }

  static createCanvas(image: ImageBitmap): HTMLCanvasElement {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    canvas.getContext("2d").drawImage(image, 0, 0, canvas.width, canvas.height);
    return canvas;
  }

  static releaseCanvas(canvas: HTMLCanvasElement | null): void {
    if (!canvas) return;

    canvas.width = 0;
    canvas.height = 0;
    const ctx = canvas.getContext("2d");
    ctx && ctx.clearRect(0, 0, 1, 1);
  }
}
